import { lazy } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import SessionLayout from "./SessionLayout";
import Can from "./components/Can";
import ConfirmOrder from "./components/ConfirmOrder";
import CryptoEstimate from "./components/CryptoEstimate";
import CryptoPayment from "./components/CryptoPament";
import GetingStart from "./components/HelpCenterPages/GetingStart";
import Airdrop from "./components/LoyaltyComponent/AirDrop";
import LoyaltyProgram from "./components/LoyaltyComponent/loyaltyProgram";
import NotFound from "./components/NotFound";
import PaymentInfo from "./components/PaymentInfo";
import IndexProduct from "./components/ProductComponents/IndexProduct";
import NFTSToeknPage from "./components/ProductComponents/NFTSTokenPage";
import STBLAmount from "./components/ProductComponents/STBLAmountSecrtion";
import RepayPage from "./components/RepayPage";
import CustomSavinPlan from "./components/SavingPlans/CustomSavingPlan";
import SubscribePlan from "./components/SavingPlans/SubscribePlan";
import Spinner from "./components/Spinner";
import StakeInfo from "./components/StakeInfo";
import Staking from "./components/Staking";
import StakingNFTpage from "./components/StakingPages";
import SwapToken from "./components/SwapToken";
import AppLayout from "./layout/AppLayout";
import { Boarding, Login, Register } from "./pages";
import AffiliateBoarding from "./pages/AffiliateBoarding";
import BoardingDashboard from "./pages/BoardingDashboard";
import Dashboard from "./pages/Dashboard";
import HelpCenter from "./pages/HelpCenter";
import LoyaltyHeader from "./pages/Loyalty";
import MyToken from "./pages/MyToken";
import MyWallet from "./pages/MyWallet";
import Otp from "./pages/Otp";
import Otp2FA from "./pages/Otp2FA";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import {
  default as Product,
  default as ProductIndexHeader,
} from "./pages/Product";

import SellToken from "./components/SellToken";
import Home from "./pages/Home";
import MyOrder from "./pages/MyOrder";
import SavingPlanHeader from "./pages/Saving";
import Stak from "./pages/Stak";
import StakRepay from "./pages/StakRepay";
import StakingMenu from "./pages/Staking";
import TermsAndCond from "./pages/TermsAndCond";
import TokenBuySell from "./pages/TokenBuySell";
import TwoFAQrCode from "./pages/TwoFAQrCode";
import UserAgreement from "./pages/UserAgreement";
import Verify from "./pages/Verify";
import { useGetProfileQuery } from "./service/api";
import { useAppSelector } from "./store/hooks";
const AffiliateSystem = lazy(() => import("./pages/AffiliateSystem"));
const ProfileHeader = lazy(() => import("./pages/Profile"));

const AuthRoute = () => {
  const { authenticated, isLoading } = useAppSelector((state) => state.auth);

  if (isLoading) {
    return <Loader />;
  }

  if (!authenticated && !isLoading) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

const PublicRoutes = () => {
  const { authenticated, isLoading } = useAppSelector((state) => state.auth);
  if (isLoading) {
    return <Loader />;
  }
  if (authenticated && !isLoading) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

const Loader = () => {
  return (
    <div className="loadingContainer">
      <Spinner />
    </div>
  );
};

function App() {
  const { token, isLoading } = useAppSelector((state) => state.auth);

  const { isLoading: userLoading } = useGetProfileQuery(null, {
    skip: !Boolean(token),
    refetchOnMountOrArgChange: true,
  });

  const loading = userLoading || isLoading;

  if (loading) {
    return <Loader />;
  }

  return (
    <SessionLayout>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path="/affiliate-register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/register" element={<Register />} />
          <Route path="/join/:username" element={<Register />} />
          <Route path="/2fa-auth" element={<Otp2FA />} />
          <Route path="/terms-conditions" element={<TermsAndCond />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/user-agreements" element={<UserAgreement />} />
          <Route path="/affiliate-boarding" element={<AffiliateBoarding />} />
          <Route path="/2fa-qrcode" element={<TwoFAQrCode />} />
        </Route>
        <Route element={<AuthRoute />}>
          <Route element={<AppLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/token-buy-sell" element={<TokenBuySell />} />
            <Route
              path="/product"
              element={<Can.Access element={Product} />}
            />
            <Route path="/staking" element={<Stak />} />
            <Route path="/mytoken" element={<MyToken />} />
            <Route path="/boarding" element={<BoardingDashboard />} />
            <Route path="/CryptoEstimate" element={<CryptoEstimate />} />
            <Route path="/PaymentInfo" element={<PaymentInfo />} />
            <Route path="/ConfirmOrder" element={<ConfirmOrder />} />
            <Route path="/cryptoEstimate" element={<CryptoEstimate />} />
            <Route path="/paymentInfo" element={<PaymentInfo />} />
            <Route path="/confirmOrder" element={<ConfirmOrder />} />
            <Route path="/swap-token" element={<SwapToken />} />
            <Route path="/sell-token" element={<SellToken />} />
            <Route path="/cryptoPayment" element={<CryptoPayment />} />
            <Route path="/stakings" element={<Staking />} />
            <Route
              path="/staking-menu"
              element={<Can.Access element={StakingMenu} />}
            />
            <Route
              path="/stakeInfo"
              element={<Can.Access element={StakeInfo} />}
            />
            <Route
              path="/staking-menu/stakingNFTpage"
              element={<Can.Access element={StakingNFTpage} />}
            />
            <Route
              path="/auto-invest"
              element={<Can.Access element={SavingPlanHeader} />}
            />
            <Route
              path="/subscribePlan"
              element={<Can.Access element={SubscribePlan} />}
            />
            <Route
              path="/customsavingplan"
              element={<Can.Access element={CustomSavinPlan} />}
            />
            <Route
              path="/loyalty-program"
              element={<Can.Access element={LoyaltyHeader} />}
            />
            <Route
              path="/loyaltyProgram"
              element={<Can.Access element={LoyaltyProgram} />}
            />
            <Route
              path="/airdrop"
              element={<Can.Access element={Airdrop} />}
            />
            <Route
              path="/product"
              element={<Can.Access element={ProductIndexHeader} />}
            />
            <Route
              path="/product/nstbl"
              element={<Can.Access element={ProductIndexHeader} />}
            />
            <Route
              path="/product/nfts"
              element={<Can.Access element={ProductIndexHeader} />}
            />
            <Route
              path="/product/help"
              element={<Can.Access element={ProductIndexHeader} />}
            />
            <Route
              path="/indexProduct"
              element={<Can.Access element={IndexProduct} />}
            />
            <Route
              path="/stak_repay"
              element={<Can.Access element={StakRepay} />}
            />
            <Route
              path="/repaypage"
              element={<Can.Access element={RepayPage} />}
            />
            <Route
              path="/nftstoeknPage"
              element={<Can.Access element={NFTSToeknPage} />}
            />
            <Route
              path="/stblAmount"
              element={<Can.Access element={STBLAmount} />}
            />
            <Route
              path="/helpCenter"
              element={<Can.Access element={HelpCenter} />}
            />
            <Route
              path="/helpCenter/gettingStart"
              element={<GetingStart />}
            />
            <Route
              path="/profile"
              element={<Can.Access element={ProfileHeader} />}
            />
            <Route path="/mywallet" element={<MyWallet />} />
            <Route
              path="/affiliate"
              element={<Can.Access element={AffiliateSystem} />}
            />
            <Route path="/orders" element={<MyOrder />} />
          </Route>
          <Route path="/onboarding" element={<Boarding />} />
          <Route path="/boarding_details" element={<Verify />} />
          <Route path="/404" element={<NotFound />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </SessionLayout>
  );
}

export default App;
