import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ArrowRight } from "../../assets";
import AlertMessage from "../../components/AlertMessage";
import AppleLogin from "../../components/AppleLogin";
import CustomButton from "../../components/CustomButton";
import EditCancelPopup from "../../components/EditCacelPopup";
import FBLogin from "../../components/FBLogin";
import GoogleLogin from "../../components/GoogleLogin";
import Input from "../../components/Input";
import CustomModal from "../../components/common/CustomModal";
import FlexBox from "../../components/common/FlexBox";
import { useAlertMessage } from "../../context/AlertContext";
import { environment } from "../../environment";
import BasicLayout from "../../layout/BasicLayout";
import {
  Email,
  emailResponse,
  useCheckValidCodeMutation,
  useEmailSingupMutation,
  useRequestAccountRestoreMutation,
} from "../../service/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  checkUserRegister,
  setRegAffiliateCode,
  updateUser,
} from "../../store/reducers/authSlice";
import { loginSchema } from "../../utils/yupSchema";
import styles from "./index.module.scss";

type UserData = {
  user: User;
  token: string;
  refreshToken: string;
};
type AffiliateRegisterResp = {
  user: User;
};

export default function Login() {
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [isUSCitizen, setIsUSCitizen] = useState<boolean>(false);
  const [isCompany, setIsCompany] = useState<boolean | undefined>();
  const [emailSingup, { isLoading: loading }] = useEmailSingupMutation();
  const [codeCheck, { isLoading: loadingCode }] = useCheckValidCodeMutation();
  const [isAccountDelete, setIsAccountDelete] = useState<boolean>(false);
  const [isAccountClose, setIsAccountClose] = useState<boolean>(false);
  const [isEmail, setIsEmail] = useState<string>("");
  const [accountStatusMsg, setAccountStatusMsg] = useState<string>("");
  const { alertMsg, setAlertMsg, onCloseAlert } = useAlertMessage();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    authenticated,
    isLoading: userLoading,
    joinAffiliateCode,
  } = useAppSelector((state) => state.auth);
  const methods = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(loginSchema),
  });
  const {
    errors: { email },
    isValid,
  } = methods.formState;

  const path = window.location.pathname;
  const { username } = useParams();

  const [requestUserAccount, { isLoading: accountLoading }] =
    useRequestAccountRestoreMutation();

  useEffect(() => {
    if (username?.length) {
      handelCodeCheck();
    }
  }, [username]);

  useEffect(() => {
    if (authenticated && !userLoading) {
      navigate("/");
    }
  }, [userLoading, authenticated]);

  const handelCodeCheck = async () => {
    const body = {
      affiliateCode: username,
    };
    const { data, error } = (await codeCheck(body)) as apiResponse;
    if (data) {
      if (error) {
        setAlertMsg?.({
          type: "error",
          msg: error?.data?.message,
        });
        navigate("/login");
      } else {
        dispatch(setRegAffiliateCode(username ?? ""));
        navigate("/register");
        return;
      }
    }
  };

  const toggleAccountDelete = () => {
    setIsAccountDelete((isAccountDelete) => !isAccountDelete);
  };

  const toggleAccountClosed = () => {
    setIsAccountClose((isAccountClose) => !isAccountClose);
  };

  const emailSigupHandler = async (event: Email) => {
    if (loading) return;
    if (!isAgree) return;
    const { email } = event;

    if (path == "/affiliate-register") {
      const { data, error } = (await emailSingup({
        email,
        isCompany,
      })) as emailResponse;
      if (data) {
        const { user } = data?.data as AffiliateRegisterResp;
        dispatch(updateUser({ user }));
        navigate("/affiliate-boarding");
      }
      if (error) {
        setAlertMsg?.({
          type: "error",
          msg: error?.data?.message,
        });
        console.log(error, "error");
      }
      return;
    }
    if (joinAffiliateCode) {
      const { data, error } = (await emailSingup({
        email,
        isCompany,
        affiliateCode: joinAffiliateCode,
      })) as emailResponse;
      if (data) {
        setAlertMsg?.({
          type: "success",
          msg: data?.message,
        });
        dispatch(setRegAffiliateCode(""));
        navigate("/otp", {
          state: {
            email,
            type: "register",
          },
        });
      }
      if (error) {
        setAlertMsg?.({
          type: "error",
          msg: error?.data?.message,
        });
        console.log(error, "error");
      }
    } else {
      const { data, error } = (await emailSingup({
        email,
        isCompany,
      })) as emailResponse;
      if (data) {
        dispatch(
          checkUserRegister({ isRegistered: true, registeredOtp: false })
        );
        setAlertMsg?.({
          type: "success",
          msg: data.message,
        });
        navigate("/otp", {
          state: {
            email,
            type: "register",
          },
        });
      }
      if (error) {
        setIsEmail(email);
        setAccountStatusMsg(error?.data?.message);
        if (error?.data?.data?.errorType == "IS_CLOSED") {
          toggleAccountClosed();
        } else if (error?.data?.data?.errorType == "IS_DELETED") {
          toggleAccountDelete();
        } else {
          setAlertMsg?.({
            type: "error",
            msg: error?.data?.message,
          });
        }

        console.log(error, "error");
      }
    }
  };
  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setIsAgree(e?.target.checked);
  };

  const handleCitizenCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setIsUSCitizen(e?.target.checked);
  };

  const requestAccountHandler = async () => {
    if (isEmail.length === 0) {
      setAlertMsg?.({
        type: "error",
        msg: "Fill email id",
      });
      return;
    }
    const { data, error } = (await requestUserAccount({
      email: isEmail,
    })) as apiResponse;
    if (data) {
      toggleAccountClosed();
    }
    if (error) {
      setAlertMsg?.({
        type: "error",
        msg: error?.data?.message,
      });
    }
  };
  const socialBtnList = [
    {
      tag: "google",
      btnComponent: (
        <GoogleLogin
          navigateTo="/boarding_details"
          register
          classes={{ inputGroup: styles.input_group, button: styles.btn }}
          affiliateCode={joinAffiliateCode ?? ""}
        />
      ),
    },
    {
      tag: "facebook",
      btnComponent: (
        <FBLogin
          register
          navigateTo="/boarding_details"
          classes={{ inputGroup: styles.input_group, button: styles.btn }}
          affiliateCode={joinAffiliateCode ?? ""}
        />
      ),
    },
    {
      tag: "apple",
      btnComponent: (
        <div className={styles.input_group}>
          <AppleLogin
            register
            className={styles.btn}
            navigateTo="/boarding_details"
            affiliateCode={joinAffiliateCode ?? ""}
          />
        </div>
      ),
    },
  ];
  const notAllowSocialButton = environment.REMOVED_SOCIAL_BUTTON;

  const allowedSocialButton = socialBtnList.filter(
    (item) => !notAllowSocialButton.includes(item.tag)
  );
  return (
    <div className={styles.bg}>
      <div className={clsx(styles.signup_page)}>
        <BasicLayout>
          <div className={styles.sign_form}>
            <form onSubmit={methods.handleSubmit(emailSigupHandler)}>
              <h2>
                {path == "/affiliate-register"
                  ? "Create your Affiliate account!"
                  : "Create your account!"}
              </h2>
              <p className={styles.connectWith}>Connect with...</p>
              {alertMsg?.msg && (
                <AlertMessage type={alertMsg.type} onClose={onCloseAlert}>
                  {alertMsg.msg}
                </AlertMessage>
              )}
              <div className={styles.input_group}>
                <Controller
                  control={methods.control}
                  name="email"
                  render={({ field }) => (
                    <>
                      <Input
                        type="text"
                        placeholder="Enter your e-mail address here"
                        id="Email"
                        label="E-mail"
                        error={email as FieldError}
                        {...field}
                      />
                    </>
                  )}
                />
              </div>
              <div className={styles.or}>
                <span></span>
                Or
                <span></span>
              </div>
              {allowedSocialButton.map((socialButton) => (
                <>{socialButton.btnComponent}</>
              ))}
              {/* <GoogleLogin
                navigateTo="/boarding_details"
                register
                classes={{ inputGroup: styles.input_group, button: styles.btn }}
                affiliateCode={joinAffiliateCode ?? ""}
              />
              <FBLogin
                register
                navigateTo="/boarding_details"
                classes={{ inputGroup: styles.input_group, button: styles.btn }}
                affiliateCode={joinAffiliateCode ?? ""}
              />
              <div className={styles.input_group}>
                <AppleLogin
                  register
                  className={styles.btn}
                  navigateTo="/boarding_details"
                  affiliateCode={joinAffiliateCode ?? ""}
                />
              </div> */}

              <div className={clsx(styles.input_checkbox, styles.terms)}>
                <Input
                  value={isUSCitizen ? "true" : "false"}
                  name="termsUSCitizen"
                  noWidth
                  placeholder=""
                  type="checkbox"
                  id="citizenCheckbox"
                  onChange={handleCitizenCheckbox}
                  className={styles.checkboxsize}
                />
                <label
                  htmlFor="citizenCheckbox"
                  className={styles.rememberText}
                >
                  I am not a U.S. citizen or resident in the U.S.
                </label>
              </div>

              <div className={clsx(styles.input_checkbox, styles.terms)}>
                <Input
                  value={isAgree ? "true" : "false"}
                  name="terms"
                  noWidth
                  placeholder=""
                  type="checkbox"
                  id="checkbox"
                  onChange={handleCheckbox}
                  className={styles.checkboxsize}
                />
                <label htmlFor="checkbox" className={styles.rememberText}>
                  I agree with the{" "}
                  <span>
                    <Link
                      className={styles.term_link}
                      target="_blank"
                      to="/terms-conditions"
                    >
                      {" "}
                      Terms
                    </Link>
                  </span>
                  {", "}
                  <span>
                    <Link
                      className={styles.term_link}
                      target="_blank"
                      to="/user-agreements"
                    >
                      {" "}
                      the User Agrements
                    </Link>
                  </span>{" "}
                  and{" "}
                  <span>
                    <Link
                      className={styles.term_link}
                      target="_blank"
                      to="/privacy-policy"
                    >
                      {" "}
                      Privacy Policy
                    </Link>
                  </span>
                </label>
              </div>
              <div className={clsx(`${styles.input_group} ${styles.login}`)}>
                <CustomButton
                  type="submit"
                  loading={loading}
                  disabled={!isAgree || !isUSCitizen}
                >
                  Next
                  <ArrowRight />
                </CustomButton>
              </div>
              <div className={styles.registered}>
                <p>
                  Already registered?
                  <Link to="/login">
                    <span>Login</span>
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </BasicLayout>
      </div>

      <CustomModal isOpen={isAccountDelete} toggle={toggleAccountDelete}>
        <FlexBox className={styles.deleteModal}>
          <p> {accountStatusMsg} </p>
          <CustomButton
            className={styles.closeBtn}
            onClick={toggleAccountDelete}
            id="closeAccount"
          >
            Close
          </CustomButton>
        </FlexBox>
      </CustomModal>

      <CustomModal isOpen={isAccountClose} toggle={toggleAccountClosed}>
        <EditCancelPopup
          title={"Request admin to restore account!"}
          desc={accountStatusMsg}
          loading={accountLoading}
          updateBtn={"Confirm"}
          cancelBtn={"Cancel"}
          updateonClick={requestAccountHandler}
          cancelonClick={toggleAccountClosed}
        />
      </CustomModal>
    </div>
  );
}
