import { ReactNode } from "react";
import { Modal, type ModalProps as Props } from "react-responsive-modal";
import styles from "./index.module.scss";
// import { CloseIcon } from '../../../assets';
export type ModalProps = Partial<Props> & {
  isOpen: boolean;
  toggle: () => void;
  children?: ReactNode;
  showCloseIcon?: boolean;
  className?: string;
};

const customStyles = {
  borderRadius: "40px",
  // maxWidth: "600px",
};
const CustomModal = (props: ModalProps) => {
  const { isOpen, toggle, showCloseIcon = false, children, ...rest } = props;
  return (
    <>
      {isOpen && (
        <Modal
          open={isOpen}
          onClose={toggle}
          showCloseIcon={showCloseIcon}
          center
          {...rest}
        >
          <div className={styles.children}>
            {/* {isClose && <CloseIcon className={styles.close} onClick={toggle} />} */}
            {children}
          </div>
        </Modal>
      )}
    </>
    // <Dialog onClose={toggle} open={isOpen} PaperProps={{
    //     style: customStyles
    // }}>
    //     <div className={styles.children}>
    //         {isClose && <CloseIcon className={styles.close} onClick={toggle} />}
    //         {children}
    //     </div>
    // </Dialog>
  );
};

export default CustomModal;
