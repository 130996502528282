import clsx from "clsx";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackOrange } from "../../assets";
import CustomButton from "../../components/CustomButton";
import Tab from "../../components/Tab";
import { useAppSelector } from "../../store/hooks";
import styles from "./index.module.scss";

interface Props {
  item: {
    name: string;
    value: string;
  };
}
const tabs = ["Stake", "Unstake", "Claim"];
const data = [
  { name: "Stake-end-date", value: "01.02.2023  10:32" },
  { name: "Lock - up period", value: "1month" },
  { name: "You will receive (including fees)", value: "1238 SNEAL" },
  { name: "APR", value: "8.7%" },
  { name: "Exchange rate", value: "1 ETH = 1zETH" },
  { name: "Transaction cost", value: "$2.20" },
  { name: "Reward fee", value: "10%" },
];
const Stak = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const { isKYCed } = useAppSelector((state) => state.auth);
  const handleTab = (index: number) => setTabIndex(index);
  return (
    <>
      <div className={styles.back} onClick={() => navigate(-1)}>
        <BackOrange />
        <p>Back</p>
      </div>
      {/* <div className={clsx(styles.stakContainer, !isKYCed && "fade")}> */}
      <div className={clsx(styles.stakContainer)}>
        <div className={styles.stakMainDiv}>
          <div className={styles.cardWrapper}>
            <h2>$NFTS TOKEN</h2>
            <div className={styles.buttons}>
              {tabs.map((tab, i) => (
                <Tab
                  key={tab}
                  tab={tab}
                  index={i}
                  tabIndex={tabIndex}
                  handleTab={handleTab}
                />
              ))}
            </div>
            <h5 className={styles.claim}>Claim</h5>
            <div className={styles.title}>
              <p>Title</p>
              <span>Available amount: 1238 $NEAL</span>
            </div>
            <div className={styles.titleInput}>
              <input />
              <div className={styles.max}>
                <h4>Max</h4>
              </div>
            </div>
            <div className={styles.titleInputMobContainer}>
              <div className={styles.titleInputMob}>
                <div className={styles.titleInputMobTop}>
                  <p>Amount</p>
                  <span>Available: 1238 $NEAL</span>
                </div>
                <div className={styles.inputContainerMob}>
                  <input type={"number"} />
                  <div className={styles.max}>
                    <h4>Max</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.btnContainer}>
              <CustomButton variant="outlined">Cancel</CustomButton>
              <CustomButton onClick={() => navigate("/stak_repay")}>
                Submit
              </CustomButton>
            </div>

            <div className={styles.tokenDetail}>
              {data.map((item, i) => (
                <TokenDetail item={item} key={i} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const TokenDetail = ({ item }: Props) => {
  return (
    <div className={styles.item}>
      <span>{item.name}</span>
      <p className={clsx(item.name === "APR" && styles.colorChange)}>
        {item.value}
      </p>
    </div>
  );
};
export default Stak;
