import * as yup from "yup";

const phoneRegExp = /^(?:\+\d{1,3}\s?)?\d{10,15}$/;

export const personalInfoSchema = yup.object({
  userName: yup
    .string()
    .trim()
    .min(5, "Username should be at least 5 characters")
    .max(30, "Username should not be more than 30 characters")
    .matches(/^[a-z][a-z0-9_]{4,29}$/, "Invalid username format")
    .required("Username is required"),
  firstName: yup
    .string()
    .trim()
    .required("First name is required")
    .matches(/^[A-Za-z]+$/, "First name should not be special characters"),
  lastName: yup
    .string()
    .trim()
    .required("Last name is required")
    .matches(/^[A-Za-z]+$/, "Last name should not be special characters"),
  email: yup.string().optional(),
  dob: yup.string().required("DOB is required"),
  phone: yup
    .string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  nationality: yup.object().shape({
    label: yup.string().required("Nationality is required"),
    value: yup.string().required(),
  }),
  dualNationality: yup.object().shape({
    label: yup.string().optional(),
    value: yup.string().optional(),
  }),
  countryOfResidence: yup.object().shape({
    label: yup.string().required("Country of residence is required"),
    value: yup.string().required(),
  }),
  companyName: yup.string().optional(),
  companySize: yup.string().optional(),
  companyAddress: yup.string().optional(),
  companyAddedBy: yup.string().optional(),
  companyEmail: yup
    .string()
    .email("Company email must be a valid email")
    .optional(),
  companyIncorporationNumber: yup.string().optional(),
  countryIncorporation: yup.object().shape({
    label: yup.string().optional(),
    value: yup.string().optional(),
  }),
  countryOfBusiness: yup.object().shape({
    label: yup.string().optional(),
    value: yup.string().optional(),
  }),
  legalStructure: yup.object().shape({
    label: yup.string().optional(),
    value: yup.string().optional(),
  }),
  listOfBusiness: yup.string().optional(),
  taxJurisdictionCountry: yup.object().shape({
    label: yup.string().optional(),
    value: yup.string().optional(),
  }),
  taxId: yup.string().optional(),
});

export const personalBusinessInfoSchema = yup.object({
  userName: yup
    .string()
    .trim()
    .min(5, "Username should be at least 5 characters")
    .max(30, "Username should not be more than 30 characters")
    .matches(/^[a-z][a-z0-9_]{4,29}$/, "Username format is not correct")
    .required("Username is required"),
  firstName: yup.string().trim().required("First name is required"),
  lastName: yup.string().trim().required("Last name is required"),
  email: yup.string().optional(),
  dob: yup.string().required("DOB is required"),
  phone: yup
    .string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  nationality: yup.object().shape({
    label: yup.string().required("Nationality is required"),
    value: yup.string().required(),
  }),
  countryOfResidence: yup.object().shape({
    label: yup.string().required("Country of residence is required"),
    value: yup.string().required(),
  }),
  companyName: yup.string().required("Company name is required"),
  companySize: yup.string().required("Company size is required"),
  companyAddress: yup.string().required("Company address is required"),
  companyAddedBy: yup.string().required("Company addedby is required"),
});
export const loginSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("Email must be valid email")
    .typeError("Email must be valid email")
    .required("Email is required"),
});

export const contactManagerSchema = yup.object({
  accountManagerEmail: yup
    .string()
    .email("Email is not valid")
    .required("Email is required"),
  message: yup
    .string()
    .min(30, "Question must be at least 30 character")
    .required("Question is required"),
});

export const affiliateApplySchema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  userName: yup
    .string()
    .trim()
    .min(5, "Username should be at least 5 characters")
    .max(30, "Username should not be more than 30 characters")
    .matches(/^[a-z][a-z0-9_]{4,29}$/, "Username format is not correct")
    .required("Username is required"),
  dob: yup.string().required("DOB is required"),
  nationality: yup.object().shape({
    label: yup.string().required("Nationality is required"),
    value: yup.string().required(""),
  }),
  doYouHaveExp: yup
    .object()
    .shape({
      label: yup.string().optional(),
      value: yup.string().optional(),
    })
    .optional(),
  countryOfResidence: yup.object().shape({
    label: yup.string().required("Residence is required"),
    value: yup.string().required(),
  }),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  primarySocialAccount: yup
    .object()
    .shape({
      label: yup.string().optional(),
      value: yup.string().optional(),
    })
    .optional(),
  primarySocialAccountUserName: yup
    .string()
    // .trim()
    // .matches(/^[a-z][a-z0-9_]{4,29}$/, "Username format is not correct")
    .optional(),
});

export const campaignLinkSchema = yup.object({
  commissionRate: yup
    .number()
    .typeError("Commission rate should be number")
    .min(1)
    .max(100)
    .required("Commission rate is required")
    .test(
      "noEOrSign",
      "Commission rate should be between 1 to 99.", // error message
      (value) => value > 0 && value < 100
    ),
  affiliateCode: yup
    .string()
    .min(6, "Affiliate code must be at least 6 characters")
    .max(35, "Affiliate code must be at most 35 characters")
    .matches(/^[a-z0-9]+$/, {
      message:
        "Affiliate code should only be lowercase alpha numeric characters",
    }),
  note: yup.string().required("Note is required"),
});
