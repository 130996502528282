import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import styles from "./index.module.scss";
type Props = {
  className?: string
}
const Spinner = (props: Props) => {
  const { className } = props;
  return (
    <div className={clsx(styles.loader, className)}>
      <CircularProgress color="primary" />
    </div>
  );
};

export default Spinner;
