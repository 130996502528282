import React from "react";
import style from "./index.module.scss";
import { Link } from "react-router-dom";
import clsx from "clsx";
type Props = {
  src: any;
  blogTitle: string;
  date: any;
  byTime: string;
  alt: string;
  className?: string;
};
export default function NFTBlog(Props: Props) {
  const { src, blogTitle, date, byTime, alt, className } = Props;
  return (
    <div className={clsx(style.blog, className)}>
      <Link to="/">
        <div className={style.img}>
          <img src={src} alt={alt} />
        </div>
        <div className={style.blogText}>
          <h3>{blogTitle}</h3>
          <div className={style.blogItems}>
            <span>{byTime}</span>
            <p>{date}</p>
          </div>
        </div>
      </Link>
    </div>
  );
}
