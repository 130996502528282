import clsx from "clsx";
import { Tooltip, Zoom } from "material-ui-core";
import { ChangeEvent, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { useNavigate } from "react-router-dom";
import { GameBallIcon, GearIcon, InfoIcon, WarningIcon } from "../../assets";
import { useAlertMessage } from "../../context/AlertContext";
import {
  Options,
  Question,
  useGetKYCQuery,
  useGetQuestionsQuery,
  useSkipOnBoardingMutation,
  useSubmitQuestionMutation,
} from "../../service/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  changeSkipStatus,
  skipBoarding,
  updateOnBoardingStep,
  updateUser,
} from "../../store/reducers/authSlice";
import countryData from "../../utils/country.json";
import AlertMessage from "../AlertMessage";
import CustomButton from "../CustomButton";
import Input from "../Input";
import { OnBoardingProps } from "../PersonalInfo";
import RadioBtn from "../RadioBtn";
import RangeInput from "../RangeInput";
import ReactSelect, { Option } from "../Select";
import Spinner from "../Spinner";
import CustomModal from "../common/CustomModal";
import FlexBox from "../common/FlexBox";
import styles from "./index.module.scss";

export type Ques = {
  id: string;
  question?: string;
  type: string;
};

export type DATA = {
  value: string | undefined;
  label?: string | undefined;
};

export const PoliticallyExposedInfo = (props) => {
  return (
    <Tooltip title={props?.title} arrow TransitionComponent={Zoom}>
      <InfoIcon className={styles.infoIcon} />
    </Tooltip>
  );
};
type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const Questionnaire = ({ activeStep, setActiveStep }: OnBoardingProps) => {
  const [selectedOptions, setSelectedOptions] = useState<Options[]>([]);
  const [value, setValue] = useState<number[]>([0, 0]);
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [isPendingModalOpen, setIsPendingModalOpen] = useState<boolean>(false);
  const [showInputField, setShowInputField] = useState<boolean>(false);
  const [isApprovedModalOpen, setIsApprovedModalOpen] =
    useState<boolean>(false);
  const [isFailedModalOpen3, setIsFailedModalOpen3] = useState<boolean>(false);
  const [skip, setSkip] = useState(true);
  const [datevalue, onChange] = useState<Value>(new Date());

  const { alertMsg, setAlertMsg, onCloseAlert } = useAlertMessage();

  const { user, applicationId } = useAppSelector((state) => state.auth);
  useEffect(() => {
    !applicationId && setActiveStep(activeStep - 1);
  }, [applicationId]);
  const { data, isLoading } = useGetQuestionsQuery();
  const { data: kycData, isLoading: kycLoading } = useGetKYCQuery(
    applicationId,
    { skip }
  );
  const [result, setResult] = useState<any>();
  useEffect(() => {
    const result = data?.data?.data?.reduce(function (r, a) {
      r[a.heading] = r[a.heading] || [];
      r[a.heading].push(a);
      return r;
    }, Object.create(null));

    if (result) {
      const keys = Object.entries(result);
      setResult(keys);
    }
  }, [data?.data?.data]);
  useEffect(() => {
    if (!kycLoading && kycData?.success) {
      checkKYC();
    }
  }, [kycData, kycLoading]);
  const [submitQuestion, { isLoading: loading }] = useSubmitQuestionMutation();
  const [skipOnBoarding, { isLoading: skipLoading }] =
    useSkipOnBoardingMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const min = 0;
  const max = 50000;
  const onChangeSelect = (
    e: string | undefined,
    index: number | string,
    item: Question
  ) => {
    const { id, type, options } = item;
    const selected = options.find((option) => option.id === e);
    const optionObj = {
      question: id,
      answer: selected?.id,
      type,
    };
    validateSelected(id);
    setSelectedOptions((prev: any) => {
      let updatedOptions = [...prev];
      updatedOptions = [...updatedOptions, { ...optionObj, type }];
      return updatedOptions;
    });
  };
  const onChangeHandler = (
    option: Options,
    index: number,
    type: string,
    questionId: string
  ) => {
    const optionObj = {
      question: questionId,
      answer: option.id,
      type: option.type,
    };

    const selected = selectedOptions.find(
      (option, i) => option.question === questionId
    );
    if (selected) {
      const filteredPeople = selectedOptions.filter(
        (item) => item.question !== questionId
      );
      setSelectedOptions(filteredPeople);
    }

    if (option?.title == "Other") {
      setShowInputField(true);
    } else {
      setShowInputField(false);
    }

    setSelectedOptions((prev: any) => {
      let updatedOptions = [...prev];
      updatedOptions = [...updatedOptions, { ...optionObj, type }];
      return updatedOptions;
    });
  };
  if (isLoading) {
    return (
      <div className="flex a-center j-center">
        <Spinner />
      </div>
    );
  }
  const skipHandle = async () => {
    const payload = {
      id: user?.id,
      isOnboardingSkipped: true,
    };
    const { data, error } = (await skipOnBoarding(payload)) as SkipStep;
    if (data) {
      dispatch(changeSkipStatus(true));
      navigate("/", { replace: true });
    }
    if (error) {
      setAlertMsg?.({
        type: "error",
        msg: error?.message,
      });
    }
  };
  const submitHandler = async () => {
    const payload = {
      option: selectedOptions.filter((data) => data != null),
      userId: user?.id,
    };

    const { data, error }: any = await submitQuestion(payload);
    if (data) {
      dispatch(updateUser({ user: data.data.userDetails.user }));
      setSkip((prev) => !prev);
      dispatch(updateOnBoardingStep(4));
    }
    if (error) {
      setAlertMsg?.({
        type: "error",
        msg: error?.message,
      });
    }
  };

  const checkKYC = async () => {
    if (kycData?.data?.data?.reviewStatus === "pending") {
      setIsPendingModalOpen(true);
    } else if (kycData?.data?.data?.reviewResult?.reviewAnswer === "GREEN") {
      setIsApprovedModalOpen(true);
    } else {
      setIsFailedModalOpen3(true);
    }
  };
  const togglePending = () => {
    setIsPendingModalOpen((prev) => !prev);
  };
  const toggleApproved = () => {
    setIsApprovedModalOpen((prev) => !prev);
  };
  const toggleFailedModal = () => {
    setIsFailedModalOpen3((prev) => !prev);
  };
  const complete = () => {
    togglePending();
    dispatch(skipBoarding());
    navigate("/", { replace: true });
  };
  function valuetext(value: number) {
    const a = "$";
    return `${a} ${value}`;
  }

  const handleChange = (
    event: Event,
    newValue: number | number[],
    index: number | string,
    item: Ques
  ) => {
    const { id, type } = item;
    const optionObj = {
      question: id,
      answer: `${value[0]}-${value[1]}`,
      type,
    };
    validateSelected(id);
    setValue(newValue as number[]);
    setSelectedOptions((prev: any) => {
      let updatedOptions = [...prev];
      updatedOptions = [...updatedOptions, { ...optionObj, type }];
      return updatedOptions;
    });
  };
  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setIsAgree(e?.target.checked);
  };
  const handleCitizenCheckbox = (
    e: ChangeEvent<HTMLInputElement>,
    option1: Options,
    option2: Options,
    index: number,
    type: string,
    questionId: string
  ) => {
    let optionObj = {
      question: questionId,
      answer: e?.target?.checked ? option1?.id : option2?.id,
      type: type,
    };

    validateSelected(questionId);
    setSelectedOptions((prev: any) => {
      let updatedOptions = [...prev];
      updatedOptions = [...updatedOptions, { ...optionObj, type }];
      return updatedOptions;
    });
  };
  let count = 0;
  for (let i = 0; i < selectedOptions.length; i++) {
    if (selectedOptions[i]?.answer) {
      count += 1;
    }
  }

  const handleCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    option: Options,
    index: number,
    type: string,
    questionId: string
  ) => {
    const optionObj = {
      question: questionId,
      answer: option.id,
      type: option.type,
    };
    const isCheck = e?.target.checked;
    setSelectedOptions((prev: any) => {
      let updatedOptions = [...prev];
      if (isCheck) {
        const filteredData = updatedOptions.filter(
          (dat) => dat?.answer != option.id
        );
        updatedOptions = [...filteredData, { ...optionObj, type }];
      } else {
        const filteredData = updatedOptions.filter(
          (dat) => dat?.answer != option.id
        );
        updatedOptions = [...filteredData];
      }
      return updatedOptions;
    });
  };

  const onChangeInputField = (value: string) => {
    const otherData = selectedOptions.find((data) => data?.type == "radio");

    if (otherData) {
      setSelectedOptions((prev: any) => {
        let updatedOptions = [...prev];
        const filteredD = updatedOptions.filter(
          (val) => val.answer != otherData.answer
        );
        updatedOptions = [...filteredD, { ...otherData, comment: value }];
        return updatedOptions;
      });
    }
  };

  const handleInputChange = (answer: string, item: Ques) => {
    const { id, type } = item;
    const optionObj = {
      question: id,
      answer: `${answer}`,
      type,
    };
    validateSelected(id);
    setSelectedOptions((prev: any) => {
      let updatedOptions = [...prev];
      updatedOptions = [...updatedOptions, { ...optionObj, type }];
      return updatedOptions;
    });
  };

  const validateSelected = (questionId: string) => {
    const selected = selectedOptions.find(
      (option, i) => option.question === questionId
    );
    if (selected) {
      const filteredPeople = selectedOptions.filter(
        (item) => item.question !== questionId
      );
      setSelectedOptions(filteredPeople);
    }
  };

  const onCountryChangeSelect = (
    selectedValue: string | undefined,
    index: number | string,
    item: Question
  ) => {
    const { id, type } = item;

    const optionObj = {
      question: id,
      answer: selectedValue,
      type,
    };

    validateSelected(id);

    setSelectedOptions((prev: any) => {
      let updatedOptions = [...prev];
      updatedOptions = [...updatedOptions, { ...optionObj, type }];
      return updatedOptions;
    });
  };

  const show = (item: Question) => {
    if (!item?.dependentAnsId) {
      return true;
    }
    const selected = selectedOptions.find(
      (option) => option.answer === item.dependentAnsId
    );
    return Boolean(selected);
  };

  return (
    <div className={styles.questionnaire}>
      <div className={styles.skip}>
        <h2>Questionnaire</h2>
        <h5 onClick={skipHandle}>
          {skipLoading ? "Loading..." : "Skip for now"}
        </h5>
      </div>
      {alertMsg?.msg && (
        <AlertMessage type={alertMsg?.type} onClose={onCloseAlert}>
          {alertMsg?.msg}
        </AlertMessage>
      )}
      {!isLoading &&
        result?.map((queData) => {
          return (
            <>
              <h3>{queData[0]}</h3>
              {queData[1]?.map((item: Question, index: number) => {
                if (!show(item)) return null;
                let selectData: Option[] = [];
                if (item.type === "select") {
                  item.options.map((val) => {
                    selectData?.push({
                      value: val.id,
                      label: val.title,
                    });
                  });
                }
                return (
                  <div key={index} className={styles.parentClass}>
                    {item.type != "check" && <h5>{item.question ?? ""}</h5>}

                    {item.type === "radio" && (
                      <FlexBox className={clsx(styles.questionRadio, "wrap")}>
                        {item.options.map((option, i) => (
                          <div className={styles.halfWidth} key={i}>
                            <RadioBtn
                              label={option.title}
                              id={option.id}
                              name={item.question}
                              onChange={() =>
                                onChangeHandler(
                                  option,
                                  index,
                                  item.type,
                                  item.id
                                )
                              }
                              value={option.answer}
                            />
                          </div>
                        ))}
                        {showInputField && (
                          <Input
                            name="terms"
                            noWidth
                            placeholder="Other income source"
                            type="text"
                            id="text"
                            label="Mention about other income source"
                            onChange={(e) => onChangeInputField(e.target.value)}
                            className={styles.inputField}
                          />
                        )}
                      </FlexBox>
                    )}

                    {item.type === "checkbox" && (
                      <FlexBox className={clsx(styles.questionRadio, "wrap")}>
                        {item.options.map((option, i) => (
                          <div
                            className={clsx(styles.halfWidth, styles.checkbox)}
                            key={i}
                          >
                            <Input
                              name="terms"
                              noWidth
                              placeholder=""
                              type="checkbox"
                              id={`checkbox+${option?.id}`}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleCheckboxChange(
                                  e,
                                  option,
                                  index,
                                  item.type,
                                  item.id
                                );
                              }}
                              className={styles.checkboxsize}
                            />
                            <label
                              htmlFor={`checkbox+${option?.id}`}
                              className={styles.dnationality}
                            >
                              {option.title}
                            </label>
                          </div>
                        ))}
                      </FlexBox>
                    )}

                    {item.type === "range" && (
                      <FlexBox className={clsx(styles.questionRadio, "wrap")}>
                        <div className={styles.range}>
                          <RangeInput
                            value={value}
                            min={min}
                            max={max}
                            step={10}
                            handleChange={(event, newValue) =>
                              handleChange(event, newValue, index, item)
                            }
                            valuetext={valuetext}
                            aria-labelledby="non-linear-slider"
                          />
                          <div className="flex a-center j-between">
                            <p>Min ${min}</p>
                            <p>Max ${max}</p>
                          </div>
                        </div>
                        <div
                          className={clsx(styles.range, styles.rangInputField)}
                        >
                          <Input
                            label="Amount"
                            type=""
                            id="range"
                            name="range"
                            value={`$${value[0]} - $${value[1]}`}
                            placeholder=""
                          />
                        </div>
                      </FlexBox>
                    )}

                    {item.type === "select" && (
                      <FlexBox className={clsx(styles.questionRadio, "wrap")}>
                        {item.isTooltip ? (
                          <span className={styles.info}>
                            {<PoliticallyExposedInfo title={item.toolTips} />}
                          </span>
                        ) : (
                          ""
                        )}
                        <ReactSelect
                          options={selectData}
                          // value={selectData[0]}
                          onChange={(e, b) => {
                            const { value } = e as Option;
                            onChangeSelect(value, index, item);
                          }}
                          id={`select-${item.id}`}
                          placeholder="Select your status"
                          label=""
                        />
                      </FlexBox>
                    )}

                    {item.type === "select_country" && (
                      <FlexBox className={clsx(styles.questionRadio, "wrap")}>
                        <ReactSelect
                          options={countryData}
                          onChange={(e, b) => {
                            const { value } = e as Option;
                            onCountryChangeSelect(value, index, item);
                          }}
                          id={`select-${item.id}`}
                          placeholder="Select your status"
                          label=""
                        />
                      </FlexBox>
                    )}

                    {item.type === "check" && (
                      <FlexBox className={clsx(styles.flexBoxhorizontal)}>
                        <Input
                          name="terms"
                          noWidth
                          placeholder=""
                          type="checkbox"
                          id={`check-${item?.id}`}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleCitizenCheckbox(
                              e,
                              item.options[0],
                              item.options[1],
                              index,
                              item.type,
                              item.id
                            )
                          }
                          className={styles.checkboxsize}
                        />
                        <label
                          htmlFor={`check-${item?.id}`}
                          className={styles.dnationality}
                        >
                          {item.question}
                        </label>
                      </FlexBox>
                    )}

                    {item.type === "input" && (
                      <>
                        <FlexBox>
                          <Input
                            name={`input-${item.id}`}
                            placeholder=""
                            type="text"
                            id={`input-${item.id}`}
                            onChange={(e) => {
                              handleInputChange(e.target.value, item);
                            }}
                          />
                        </FlexBox>
                      </>
                    )}

                    {item.type === "date" && (
                      <>
                        <FlexBox>
                          <div className={styles.datePicker}>
                            <DatePicker onChange={onChange} value={datevalue} />
                          </div>
                        </FlexBox>
                      </>
                    )}
                  </div>
                );
              })}
            </>
          );
        })}
      <hr />
      <div className={styles.terms}>
        <Input
          value={isAgree ? "true" : "false"}
          name="terms"
          noWidth
          placeholder=""
          type="checkbox"
          id="term-condition"
          onChange={handleCheckbox}
        />
        <label htmlFor="term-condition">
          I agree to the processing of my personal data
        </label>
      </div>
      <div className={styles.btnContainer}>
        <CustomButton
          className={styles.back}
          variant="outlined"
          onClick={() => setActiveStep((prev) => prev - 1)}
          disabled={user?.onboardingStep !== 0}
        >
          Back
        </CustomButton>
        <CustomButton
          onClick={submitHandler}
          loading={loading}
          disabled={count >= 4 && isAgree ? false : true}
        >
          Submit
        </CustomButton>
      </div>
      <div className={styles.skipMobile}>
        <h5 className={styles.skipText} onClick={skipHandle}>
          {skipLoading ? "Loading..." : "Skip for now"}
        </h5>
      </div>
      <CustomModal isOpen={isPendingModalOpen} toggle={togglePending}>
        <div className={styles.modalContainer}>
          <GameBallIcon />
          <h1>You did great!</h1>
          <h4>Your verification status is: PENDING</h4>
          <p>
            You have completed the verification process! Please not that it can
            take up to 48 hours for the verification to be fully approved. You
            can start getting familiar with the Crypto platform in the meantime,
            but certain functions will be unavailable until the verification
            process is finalized on our end.
          </p>
          <p>Thank you, and welcome to Crypto!</p>
          <CustomButton
            className={styles.ok_btn}
            onClick={() => {
              setIsPendingModalOpen(false);
              complete();
            }}
          >
            Got It!
          </CustomButton>
        </div>
      </CustomModal>

      <CustomModal isOpen={isApprovedModalOpen} toggle={toggleApproved}>
        <div className={styles.modalContainer}>
          <GearIcon />
          <h1>Your verification is fully approved!</h1>
          <p>
            Good job, all the informations you have provided have been verified
            and approved!You can now take full advantage of all the functions
            available on the Crypto platform! Make sure to take the time to get
            familiar with everything, and refer to the help center, our publicly
            available documents and our communities if you need clarification!
          </p>
          <p>Thank you, and welcome to Crypto!</p>
          <CustomButton
            className={styles.ok_btn}
            onClick={() => {
              setIsApprovedModalOpen(false);
              complete();
            }}
          >
            Got It!
          </CustomButton>
        </div>
      </CustomModal>

      <CustomModal isOpen={isFailedModalOpen3} toggle={toggleFailedModal}>
        <div className={styles.modalContainer}>
          <WarningIcon />
          <h1>Unfortunately, your verification failed...</h1>
          <p>
            Please try again and make sure all the informations you’ve provided
            are correct and truthful and that the document and selfie you’ve
            uploaded are valid and meet all the requirements.
          </p>
          <CustomButton
            className={styles.ok_btn}
            onClick={() => {
              setIsFailedModalOpen3(false);
              complete();
            }}
          >
            Got It!
          </CustomButton>
          {/* <div className={styles.btnContainer}>
            <CustomButton
              className={styles.ok_btn}
              onClick={() => {
                toggleFailedModal()
                complete();
              }}
            >
              Got it!
            </CustomButton>
            {/* <CustomButton
              className={styles.ok_btn}
              onClick={() => setIsFailedModalOpen3(false)}
            >
              Try Again
            </CustomButton>
            <CustomButton
              variant="outlined"
              className={styles.ok_btn}
              onClick={() => {
                setIsFailedModalOpen3(false);
                complete();
              }}
            >
              Skip For Now
            </CustomButton>
          </div> */}
        </div>
      </CustomModal>
    </div>
  );
};

export default Questionnaire;
