import SumsubWebSdk from "@sumsub/websdk-react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  LevelName,
  useGetSumsubTokenQuery,
  useUpdateApplicantIdMutation,
} from "../../service/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { changeApplicationId } from "../../store/reducers/authSlice";
import Spinner from "../Spinner";
import styles from "./index.module.scss";

export interface OnSumsubProps {
  setIsSelfieSelected: Dispatch<SetStateAction<boolean>>;
  setSumsumAppId: Dispatch<SetStateAction<string>>;
  levelName?: LevelName;
  stepCompletedCB?: () => void;
}

const customCssStr =
  ":root {\n  --black: #190044;\n   --grey: #FFFFFF;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 0px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n} {\n }";

const SumsubSdk = ({
  setIsSelfieSelected,
  setSumsumAppId,
  levelName = "basic-kyc-level",
  stepCompletedCB,
}: OnSumsubProps) => {
  const { user, isCompany } = useAppSelector((state) => state.auth);
  levelName = isCompany == true ? "basic-kyb-level" : levelName;
  const { data, isLoading } = useGetSumsubTokenQuery(levelName, {
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  const [token, setToken] = useState<string>("");
  const applicantEmail = user?.email ? user?.email : "";
  const applicantPhone = user?.phone ? user?.phone : "";
  const dispatch = useAppDispatch();
  const [updateApplicant, { isLoading: loadingSumsub }] =
    useUpdateApplicantIdMutation();

  useEffect(() => {
    setToken(data?.data?.token || "");
  }, [data]);

  if (isLoading)
    return (
      <div className={styles.identity}>
        <Spinner />
        <br />
      </div>
    );

  if (!token) return <div className={styles.identity}>Token not found</div>;

  const handleUpdate = async (applicantId: string) => {
    const body: any = {
      userId: user?.id,
      applicantId: applicantId,
    };

    await updateApplicant(body);
  };

  return (
    <div className={styles.identity}>
      <SumsubWebSdk
        accessToken={token}
        expirationHandler={() => Promise.resolve(token)}
        config={{
          lang: "ru-RU",
          email: applicantEmail,
          phone: applicantPhone,
          i18n: {
            document: {
              subTitles: {
                IDENTITY: "Upload a document that proves your identity",
              },
            },
          },

          uiConf: {
            customCssStr,
          },
        }}
        options={{ addViewportTag: false, adaptIframeHeight: true }}
        onMessage={(type: any, payload: any) => {
          if (payload?.applicantId) {
            dispatch(changeApplicationId(payload?.applicantId));
            handleUpdate(payload?.applicantId);
          }
          console.log("onMessage", type, payload);
          console.log("payload?.applicantId", payload?.applicantId);
          setSumsumAppId(payload?.applicantId);
          if (type == "idCheck.applicantStatus") {
            setIsSelfieSelected(true);
          }
          if (
            type === "idCheck.stepCompleted" ||
            payload.reviewStatus === "pending"
          ) {
            stepCompletedCB?.();
          }
        }}
        onError={(data: any) => console.log("onError", data)}
      />
    </div>
  );
};

export default SumsubSdk;
