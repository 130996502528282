import React from "react";
import { Link } from "react-router-dom";
import CustomButton from "../CustomButton";
import CustomLink from "../CustomLink";
import Dashboardtitle from "../DashbordTitle";
import style from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Estimate } from "../../assets/icons/estimate-Icon.svg";
import { ReactComponent as SelectIcon } from "../../assets/icons/select-icon.svg";

export default function CryptoEstimate() {
  const navigate = useNavigate();
  return (
    <div className={style.estimate}>
      <Dashboardtitle
        className={style.estimeTitle}
        description={""}
        title={"Buy crypto"}
      />
      <div className={style.estimtCard}>
        <h6>Spend</h6>
        <div className={style.crytoValue}>
          <p>0.00</p>
          <div className={style.optons}>
            <select>
              <option selected>USD</option>
              <option selected>USD</option>
              <option selected>USD</option>
            </select>
            <SelectIcon />
          </div>
        </div>
      </div>
      <div className={style.estimtCard}>
        <h6>Receive</h6>
        <div className={style.crytoValue}>
          <p>0.00</p>
          <div className={style.optons}>
            <select>
              <option selected>ETH</option>
              <option selected>ETH</option>
              <option selected>ETH</option>
            </select>
            <SelectIcon />
          </div>
        </div>
      </div>
      <div className={style.estimateLink}>
        <Link to="/">Estimated price</Link>
        <Link to="/">1 ETH ≈ 24,251.30 USD</Link>
        <Estimate />
      </div>
      <div className={style.cryptoBtn}>
        <div className={style.btnChild}>
          <CustomButton variant={"outlined"}>Cancel</CustomButton>
        </div>
        <div className={style.btnChild}>
          <CustomButton
            variant={"secondary"}
            onClick={() => navigate("/cryptoPayment")}
          >
            Continue
          </CustomButton>
        </div>
      </div>
    </div>
  );
}
