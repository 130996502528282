import React from "react";
import style from "./index.module.scss";
import clsx from "clsx";
type Props = {
  link: React.ReactElement;
};
export default function CustomLink(
  Props: Props & Omit<React.HTMLProps<HTMLButtonElement>, "type">
) {
  const { link, className } = Props;
  return <div className={clsx(style.link, className)}> {link}</div>;
}
