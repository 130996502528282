import React from "react";
import { Navigate } from "react-router-dom";
import { environment } from "../environment";

type Props = {
  element: React.LazyExoticComponent<() => JSX.Element> | (() => JSX.Element);
};

const currentPath = window.location.pathname;
const pathNotAllow = environment.REMOVED_ACCESS_MODULE_PATH;

const Access = (props: Props) => {
  const { element: Component } = props;
  if (pathNotAllow?.includes(currentPath)) {
    return <Navigate to="/404" />;
  }
  return <Component />;
};

const Can = {
  Access,
};

export default Can;
