import { ReactComponent as SearchIcon } from "../../assets/icons/searchIcon.svg";
import style from "./index.module.scss";

export default function Searchinput() {
  return (
    <div className={style.search}>
      <SearchIcon />
      <input type="text" placeholder="Search" />
    </div>
  );
}
