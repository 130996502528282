import React from "react";
import style from "./index.module.scss";
type TokenItems = {
  title: string;
  tokenIcon: React.ReactElement;
  tokenText: string;
};
type Props = {
  tokenData: TokenItems[];
};
export default function SelectToken(props: Props) {
  const { tokenData } = props;
  return (
    <>
      {tokenData.map((tokenItems, index) => {
        return (
          <div className={style.selectToken} key={index}>
            <div className={style.selectIcon}>{tokenItems.tokenIcon}</div>
            <div className={style.selecttex}>
              <h4 className={style.tokenTitle}>{tokenItems.title}</h4>
              <p className={style.tokencontent}>{tokenItems.tokenText}</p>
            </div>
          </div>
        );
      })}
    </>
  );
}
