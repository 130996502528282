import { ChangeEvent, Dispatch, SetStateAction, useRef, useState } from "react";
import styles from "./index.module.scss";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import CustomButton from "../../CustomButton";
import { Camera, Camerab } from "../../../assets";

type Props = {
  file: FileList | undefined | null;
  setFile: Dispatch<SetStateAction<FileList | undefined | null>>;
  setIsDocNotApprove: Dispatch<SetStateAction<boolean>>;
  toggle: () => void;
  isDocNotApprove: boolean;
  title: string;
  desc: string;
};
type CROP_TYPE = {
  unit: string;
  aspect: string | undefined;
  x: number;
  y: number;
  width: number;
  height: number;
};
const UploadDoc = ({
  file,
  setFile,
  title,
  desc,
  isDocNotApprove,
  setIsDocNotApprove,
  toggle,
}: Props) => {
  // const [srcImg, setSrcImg] = useState<Crop | null>(null);
  // const [image, setImage] = useState(null);
  // const [crop, setCrop] = useState({ aspect: 16 / 9 });
  // useEffect(() => {
  // file && setSrcImg(URL.createObjectURL(file[0]))
  // }, [file])

  const [crop, setCrop] = useState<Crop>();

  const inputRef = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (inputRef !== null) {
      inputRef?.current?.click();
    }
    // console.log(inputRef, "inputRef")
  };
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files);
    }
  };
  const onComplete = (crop: PixelCrop) => {
    makeClientCrop(crop);
  };
  const makeClientCrop = async (crop: PixelCrop) => {
    if (crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(crop, "newFile.jpeg");
      //   this.setState({ croppedImageUrl });
    }
  };

  const imageRef = useRef<React.RefObject<HTMLInputElement>>(null);
  // console.log(imageRef, "imageRef")
  const getCroppedImg = (crop: PixelCrop, fileName: string) => {
    // console.log(crop, fileName, "zzzzzzzzzzzzz")
    // try {
    //     const canvas = document.createElement("canvas");
    //     const scaleX = image.naturalWidth / image.width;
    //     const scaleY = image.naturalHeight / image.height;
    //     canvas.width = crop.width;
    //     canvas.height = crop.height;
    //     const ctx = canvas.getContext("2d");
    //     ctx.drawImage(
    //         image,
    //         crop.x * scaleX,
    //         crop.y * scaleY,
    //         crop.width * scaleX,
    //         crop.height * scaleY,
    //         0,
    //         0,
    //         crop.width,
    //         crop.height
    //     );
    //     const base64Image = canvas.toDataURL("image/jpeg", 1);
    //     setResult(base64Image);
    //     console.log(result);
    // } catch (e) {
    //     console.log("crop the image");
    // }
  };

  return (
    <div className={styles.container}>
      <h2>{title}</h2>
      <p>{desc}</p>
      <div className={styles.imgContainer} onClick={handleClick}>
        {file ? (
          <>
            <ReactCrop
              crop={crop}
              onChange={(c) => setCrop(c)}
              onDragEnd={(e) => console.log(e, "dragg")}
              onComplete={onComplete}
              // onImageLoaded={e=>console.log(e)}
            >
              {file && (
                <img
                  // ref={imageRef}
                  src={URL?.createObjectURL(file[0])}
                />
              )}
            </ReactCrop>
            {/* <CustomButton onClick={getCroppedImg}>Crop</CustomButton> */}
          </>
        ) : (
          <>
            <input type="file" ref={inputRef} onChange={handleFileChange} />
            <span>Choose a image</span>
          </>
        )}
        {/* {file &&
                    // <ReactCrop
                    //     style={{ maxWidth: "50%" }}
                    //     src={srcImg}
                    //     onImageLoaded={setImage}
                    //     crop={crop}
                    // // onChange={setCrop}
                    // />
                   
                } */}
      </div>
      {file ? (
        <div className={styles.btnContainer}>
          <CustomButton
            startIcon={<Camerab />}
            variant="outlined"
            onClick={() => setFile(null)}
          >
            Retake Photo
          </CustomButton>
          <CustomButton
            onClick={() => {
              toggle();
              setIsDocNotApprove(true);
            }}
          >
            Submit
          </CustomButton>
        </div>
      ) : (
        <CustomButton startIcon={<Camera />}>Upload Photo</CustomButton>
      )}
    </div>
  );
};

export default UploadDoc;
