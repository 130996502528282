import { useNavigate } from "react-router-dom";
import { IResolveParams, LoginSocialApple } from "reactjs-social-login";
import { Apple } from "../assets";
import { useAlertMessage } from "../context/AlertContext";
import { environment } from "../environment";
import { useLoginByAppleMutation } from "../service/api";
import { useAppDispatch } from "../store/hooks";
import { loginUser } from "../store/reducers/authSlice";

type Props = {
  className?: string;
  navigateTo?: string;
  affiliateCode?: string;
  register?: boolean;
};
export default function AppleLogin(props: Props) {
  const { className, navigateTo, affiliateCode = "", register } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setAlertMsg } = useAlertMessage()
  const [loginByApple] = useLoginByAppleMutation();

  const handleAppleLogin = async (params: IResolveParams) => {
    try {
      const id_token = params.data?.authorization?.id_token || "";
      if (!id_token) {
        throw new Error("Id token not found.");
      }
      const { data } = await loginByApple({
        id_token,
        affiliateCode: affiliateCode,
      }).unwrap();

      const { user, token, refreshToken } = data;

      if (user && user?.is2FAEnable) {
        const route = user.is2FAVerified ? "/2fa-auth" : "/2fa-qrcode";
        return navigate(route, {
          state: {
            email: user?.email,
            user,
          },
        });
      }

      if (user && token && refreshToken) {
        dispatch(loginUser({ user, token, refreshToken }));
        if (navigateTo) {
          navigate(navigateTo);
        }
      }
    } catch (error: any) {
      setAlertMsg?.({
        type: 'error',
        msg: error?.message || error?.data?.message || "Something went wrong"
      })
    }
  };

  const handleAppleLoginError = async (
    params: { [key: string]: any } | string
  ) => {
    if (typeof params === "string") {
    } else if (
      !["popup_closed_by_user", "user_trigger_new_signin_flow"].includes(
        params.err.error
      )
    ) {
      setAlertMsg?.({
        type: 'error',
        msg: params.err.error
      })
    }
  };

  return (
    <LoginSocialApple
      client_id={environment.APPLE_BUNDLE_ID || ""}
      redirect_uri={window.location.href}
      onResolve={handleAppleLogin}
      onReject={handleAppleLoginError}
    >
      <button type="button" className={className}>
        <Apple />
        Continue with Apple ID
      </button>
    </LoginSocialApple>
  );
}
