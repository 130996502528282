import AccordionItem from ".";
import { AccordionData } from "../Accordion/type";
import Style from "../Accordion/index.module.scss";

function Accordion({ items }: { items: Array<AccordionData> }) {
  return (
    <ul className={Style.accordion}>
      {items.map((item, index) => (
        <AccordionItem key={index} data={item} />
      ))}
    </ul>
  );
}

export default Accordion;
