import clsx from "clsx";
import React from "react";
import { ReactComponent as Maestro } from "../../assets/icons/Maestro.svg";
import { ReactComponent as Mastercard } from "../../assets/icons/Mastercard.svg";
import { ReactComponent as Visa } from "../../assets/icons/visa-logo.svg";
import style from "./index.module.scss";
type Props = {
  className?: string;
};
export default function CardVisa(Props: Props) {
  const { className } = Props;
  return (
    <div className={clsx(style.visaCard, className)}>
      <button className={style.visaButton}>{<Visa />}</button>
      <button className={style.visaButton}>{<Mastercard />}</button>
      <button className={style.visaButton}>{<Maestro />}</button>
    </div>
  );
}
