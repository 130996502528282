import DataTable, {
  ConditionalStyles,
  SortOrder,
  TableColumn,
} from "react-data-table-component";
import { SortIcon } from "../../assets";
import Spinner from "../Spinner";

export type Data = {
  id: number | string | undefined;
  // stakAmount: string;
  // expDate: string;
  // rewardAmount: string;
  // claimable: string;
  // id?:string
};
export type Data2 = {
  id: number | string | undefined;
  item1: string;
  item2: string;
  item3: string;
  item4: string;
  item5: string;
  item6: string;
  item7: string;
  item8: string;
};
interface Props {
  columns: TableColumn<Data | Data2>[];
  data: Data[];
  loading?: boolean;
  handleSort?: (
    selectedColumn: TableColumn<Data>,
    sortDirection: SortOrder,
    sortedRows: Data[]
  ) => void;
  isSortServer?: boolean;
  conditionalRowStyles?: ConditionalStyles<Data>[];
  className?: string;
}

const customStyles = {
  rows: {
    style: {
      minHeight: "56px",
      backgroundColor: "#fff",
      margin: "5px",
      borderRadius: "10px",
      border: "none",
      boxShadow: "none",
      padding: "0px 10px",
      fontSize: "16px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      background: "#f4f4f4",
      borderBottom: 0,
      fontWeight: 500,
      fontSize: 14,
      color: "hsla(200, 53%, 23%, 0.5)",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      borderBottom: 0,
      color: "red",
    },
  },
  table: {
    style: {
      background: "#f6f6f6",
      borderBottom: 0,
    },
  },
};

const DataTables = (props: Props) => {
  const {
    columns,
    data,
    loading,
    handleSort,
    isSortServer,
    conditionalRowStyles,
    className,
  } = props;

  return (
    <div className={className}>
      <DataTable
        columns={columns}
        data={data}
        sortIcon={<SortIcon />}
        responsive
        customStyles={customStyles}
        fixedHeader
        fixedHeaderScrollHeight="380px"
        progressPending={loading}
        onSort={handleSort}
        progressComponent={<Spinner />}
        sortServer={isSortServer}
        conditionalRowStyles={conditionalRowStyles}
        highlightOnHover
      />
    </div>
  );
};

export default DataTables;
