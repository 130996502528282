import clsx from "clsx";
import React, { useState } from "react";
import useWindowResize from "../../../hooks/useWindowResize";
import CustomButton from "../../CustomButton";
import MonthButton from "../../MonthButton";
import FlexBox from "../../common/FlexBox";
import style from "./index.module.scss";
type Props = {
  cancelonClick?: () => void;
  saveonClick?: () => void;
  cancel?: string;
  saveChange?: string;
  title: string;
  inputAmounttext?: React.ReactElement;
  inputDuration?: React.ReactElement;
  investmentext: string;
};
export const durationBtn = [
  { mainVal: "1 month", mobileVal: "1 mo", value: 1 },
  { mainVal: "3 months", mobileVal: "3 mo", value: 3 },
  { mainVal: "6 months", mobileVal: "6 mo", value: 6 },
  { mainVal: "12 months", mobileVal: "12 mo", value: 12 },
  { mainVal: "Custom", mobileVal: "Custom", value: 0 },
];
export default function EditSavingPlan(Props: Props) {
  const [selectedDuration, setSelectedDuration] = useState<number>(1);
  const {
    cancelonClick,
    saveonClick,
    cancel,
    saveChange,
    title,
    inputAmounttext,
    inputDuration,
    investmentext,
  } = Props;

  const { width } = useWindowResize();
  const breakpoint = 650;

  const handleSelectDuration = (val: number) => {
    setSelectedDuration(val);
  };
  return (
    <div className={style.EditSavingPlan}>
      <h2 className={style.title}>{title}</h2>
      {inputAmounttext}
      <p className={style.timeline}>{investmentext}</p>
      <FlexBox className={style.flexButton}>
        {durationBtn.map((btn) => (
          <MonthButton
            key={btn.value}
            clasName={clsx(style.investButton)}
            selectedDuration={selectedDuration}
            value={btn.value}
            months={width > breakpoint ? btn.mainVal : btn.mobileVal}
            onClick={() => handleSelectDuration(btn.value)}
          />
        ))}
        {/* <MonthButton
          clasName={clsx(style.investButton)}
          months={width > breakpoint ? "1 month" : "1 mo"}
          onClick={() => handleSelectDuration(1)}
        />
        <MonthButton months={width > breakpoint ? "3 month" : "3 mo"} onClick={() => handleSelectDuration(3)} />
        <MonthButton months={width > breakpoint ? "6 month" : "6 mo"} onClick={() => handleSelectDuration(6)} />
        <MonthButton months={width > breakpoint ? "12 month" : "12 mo"} onClick={() => handleSelectDuration(12)} />
        <MonthButton months={"Custom"} /> */}
      </FlexBox>
      {inputDuration}
      <div className={style.buttonGroup}>
        <div className={style.cancel}>
          <CustomButton variant="outlined" onClick={cancelonClick}>
            {cancel}
          </CustomButton>
        </div>
        <div className={style.saveChange}>
          <CustomButton onClick={saveonClick}>{saveChange}</CustomButton>
        </div>
      </div>
    </div>
  );
}
