import { Step, StepLabel, Stepper } from "@mui/material";
import React, { FC, useState } from "react";
import AlertMessage from "../../components/AlertMessage";
import OnBoardHeader from "../../components/BoardingHeader";
import EmailVerification from "../../components/EmailVerification";
import IdentityVerification from "../../components/IdentityVerification";
import PersonalInfo from "../../components/PersonalInfo";
import Questionnaire from "../../components/Questionnaire";
import { useAlertMessage } from "../../context/AlertContext";
import { useAppSelector } from "../../store/hooks";
import { steps, stepsWithEmailVerification } from "../../utils/constant";
import styles from "./index.module.scss";

const Boarding: FC = () => {
  const [email, setEmail] = useState<string>("");
  const { alertMsg, onCloseAlert } = useAlertMessage();
  const { user }: any = useAppSelector((state) => state.auth);

  const findStep = (val: number) => {
    if (user?.provider === "facebook") {
      return val;
    } else if (user?.provider === "manual" || user?.provider === "google") {
      if (val === 0) return 0;
      return val - 1;
    } else {
      return val;
    }
  };

  const [activeStep, setActiveStep] = React.useState<number>(
    findStep(user?.onboardingStep)
  );

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <PersonalInfo activeStep={activeStep} setActiveStep={setActiveStep} />
        );
      case 1:
        return (
          <IdentityVerification
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        );
      case 2:
        return (
          <Questionnaire
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        );
    }
  };

  const getStepContentFb = (step: number) => {
    switch (step) {
      case 0:
        return (
          <PersonalInfo
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setEmail={setEmail}
          />
        );
      case 1:
        return (
          <EmailVerification
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            email={email}
          />
        );
      case 2:
        return (
          <IdentityVerification
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        );
      case 3:
        return (
          <Questionnaire
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        );
    }
  };

  const checkStep =
    user?.provider === "facebook" ? stepsWithEmailVerification : steps;

  return (
    <div className={styles.boarding}>
      <OnBoardHeader />
      <div className={styles.formWrapper}>
        <div className={styles.stepperContainer}>
          <Stepper activeStep={activeStep} className={styles.stepper}>
            {checkStep.map((step) => (
              <Step key={step}>
                <StepLabel
                  color="inherit"
                  style={{ whiteSpace: "nowrap" }}
                  StepIconProps={{
                    classes: {
                      active: styles.avtiveIcon,
                      text: styles.iconText,
                      completed: styles.completed,
                    },
                  }}
                >
                  {step}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {alertMsg?.msg && (
          <AlertMessage type={alertMsg?.type} onClose={onCloseAlert}>
            {alertMsg.msg}
          </AlertMessage>
        )}

        {user?.provider === "facebook"
          ? getStepContentFb(activeStep)
          : getStepContent(activeStep)}
      </div>
    </div>
  );
};

export default Boarding;
