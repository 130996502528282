import CustomButton from "../../CustomButton";
import FlexBox from "../../common/FlexBox";
import styles from "./index.module.scss";

interface Props {
  data: {
    amount: {
      min: string;
      max: string;
    };
    reward: {
      min: string;
      max: string;
    };
  }[];
}

type Item = {
  item: {
    amount: {
      min: string;
      max: string;
    };
    reward: {
      min: string;
      max: string;
    };
  };
};
const StakMobile = ({ data }: Props) => {
  return (
    <div>
      {/* <FlexBox flexWrap={true}> */}
      {data.map((item, i) => (
        <StakRow item={item} key={i} />
      ))}
      {/* </FlexBox> */}
    </div>
  );
};
const StakRow = ({ item }: Item) => {
  return (
    <div className={styles.stakRow}>
      <FlexBox className={styles.flex}>
        <div className={styles.cell}>
          <p>Staked Amount</p>
          <h5>124.472 $NEAL</h5>
          <span>$7.568,02</span>
        </div>
        <div className={styles.cell}>
          <p>Staked Available rewards</p>
          <h5>0.00 $NEAL</h5>
          <span>$0.00</span>
        </div>
      </FlexBox>
      <CustomButton variant="outlined" className={styles.claimBtn}>
        Claim
      </CustomButton>
    </div>
  );
};
export default StakMobile;
