import clsx from "clsx";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import LearnMore from "../../components/LearnMore";
import MonthButton from "../../components/MonthButton";
import Tab from "../../components/Tab";
import CustomModal from "../../components/common/CustomModal";
import FlexBox from "../../components/common/FlexBox";
import BackButton from "../BackBtn";
import { durationBtn } from "../SavingPlans/EditSavingPlan";
import styles from "./index.module.scss";

interface Props {
  item: {
    name: string;
    value: string;
  };
}
const tabs = ["Stake", "Unstake", "Claim"];
const data = [
  { name: "Stake-end-date", value: "01.02.2023  10:32" },
  { name: "Lock - up period", value: "1month" },
  { name: "You will receive (including fees)", value: "1238 SNEAL" },
  { name: "APR", value: "8.7%" },
  { name: "Exchange rate", value: "1 ETH = 1zETH" },
  { name: "Transaction cost", value: "$2.20" },
  { name: "Reward fee", value: "10%" },
];
const StakingNFTpage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedDuration, setSelectedDuration] = useState<number>(1);
  const handleTab = (index: number) => setTabIndex(index);
  const months = ["1 month", "6 month", "12 month", "18 month"];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggle = () => {
    if (isModalOpen == true) {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
  };
  const navigate = useNavigate();
  const handleSelectDuration = (val: number) => {
    setSelectedDuration(val);
  };
  return (
    <>
      <BackButton
        onClick={() => {
          navigate(-1);
        }}
      />
      <div className={styles.stakContainer}>
        <div className={styles.stakMainDiv}>
          <div className={styles.cardWrapper}>
            <h2>$NFTS TOKEN</h2>
            <div className={styles.buttons}>
              {tabs.map((tab, i) => (
                <Tab
                  key={tab}
                  tab={tab}
                  index={i}
                  tabIndex={tabIndex}
                  handleTab={handleTab}
                />
              ))}
            </div>
            {tabIndex === 0 && (
              <div>
                <h5 className={styles.claim}>Stake</h5>
                <div className={styles.titleInputMobContainer}>
                  <div className={styles.titleInputMob}>
                    <div className={styles.titleInputMobTop}>
                      <p>Amount</p>
                      <span>Available: 1238 $NEAL</span>
                    </div>
                    <div className={styles.inputContainerMob}>
                      <input type={"number"} placeholder="0.00" />
                      <div className={styles.max}>
                        <h4>Max</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <p className={styles.duration}>Lock up duration</p>
                <FlexBox className={styles.monthButtonParrent}>
                  {durationBtn.map((month, index) => {
                    return (
                      <MonthButton
                        key={index}
                        months={month.mainVal}
                        value={month.value}
                        clasName={styles.monthButton}
                        selectedDuration={selectedDuration}
                        onClick={() => handleSelectDuration(month.value)}
                      />
                    );
                  })}
                </FlexBox>
                <div className={styles.btnContainer}>
                  <CustomButton variant="outlined">Cancel</CustomButton>
                  <CustomButton onClick={() => setIsModalOpen(true)}>
                    Submit
                  </CustomButton>
                </div>

                <CustomModal isOpen={isModalOpen} toggle={toggle}>
                  <LearnMore
                    className={styles.transation}
                    title={"Transaction confirmed!"}
                    buttonContent={"Got it!"}
                    contnet={
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
                    }
                    onClick={toggle}
                  />
                </CustomModal>
                <div className={styles.tokenDetail}>
                  {data.map((item, i) => (
                    <TokenDetail item={item} key={i} />
                  ))}
                </div>
              </div>
            )}
            {tabIndex === 1 && (
              <div>
                <h5 className={styles.claim}>Unstake</h5>
                <div className={styles.titleInputMobContainer}>
                  <div className={styles.titleInputMob}>
                    <div className={styles.titleInputMobTop}>
                      <p>Amount</p>
                      <span>Available: 1238 $NEAL</span>
                    </div>
                    <div className={styles.inputContainerMob}>
                      <input type={"number"} placeholder="0.00" />
                      <div className={styles.max}>
                        <h4>Max</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.btnContainer}>
                  <CustomButton variant="outlined">Cancel</CustomButton>
                  <CustomButton>Submit</CustomButton>
                </div>
                <div className={styles.tokenDetail}>
                  {data.map((item, i) => (
                    <TokenDetail item={item} key={i} />
                  ))}
                </div>
              </div>
            )}
            {tabIndex === 2 && (
              <div>
                <h5 className={styles.claim}>Claim</h5>
                <div className={styles.titleInputMobContainer}>
                  <div className={styles.titleInputMob}>
                    <div className={styles.titleInputMobTop}>
                      <p>Amount</p>
                      <span>Available: 1238 $NEAL</span>
                    </div>
                    <div className={styles.inputContainerMob}>
                      <input type={"number"} placeholder="0.00" />
                      <div className={styles.max}>
                        <h4>Max</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.btnContainer}>
                  <CustomButton variant="outlined">Cancel</CustomButton>
                  <CustomButton>Submit</CustomButton>
                </div>
                <div className={styles.tokenDetail}>
                  {data.map((item, i) => (
                    <TokenDetail item={item} key={i} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const TokenDetail = ({ item }: Props) => {
  return (
    <div className={styles.item}>
      <span>{item.name}</span>
      <p className={clsx(item.name === "APR" && styles.colorChange)}>
        {item.value}
      </p>
    </div>
  );
};
export default StakingNFTpage;
