import clsx from "clsx";
import styles from "./index.module.scss";
type Props = {
  selectedDuration?: number;
  value?: number;
  clasName?: string;
  months?: string;
  onClick?: () => void;
};

export default function MonthButton(props: Props) {
  const { selectedDuration, value, clasName, months, onClick } = props;
  return (
    <div className={clsx(styles.Buttons, clasName)}>
      <button
        className={clsx(
          styles.month,
          selectedDuration === value && styles.active
        )}
        onClick={onClick}
      >
        {months}
      </button>
    </div>
  );
}
