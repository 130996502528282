import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Setting } from "../../../assets/icons/gear.svg";
import { AddIcon, Minus } from "../../../assets/index";
import star from "../../../assets/tok1.png";
import img from "../../../assets/tok2.png";
import CustomButton from "../../CustomButton";
import EditCancelPopup from "../../EditCacelPopup";
import Input from "../../Input";
import LearnMore from "../../LearnMore";
import Title from "../../Title";
import CustomModal from "../../common/CustomModal";
import EditSavingPlan from "../EditSavingPlan";
import style from "./index.module.scss";

type Props = {
  className?: string;
};
export const CustomExpandIcon = (props: Props) => {
  const { className } = props;
  return (
    <Box
      className={className}
      sx={{
        position: "relative",
        height: "35px",
        width: "35px",
        border: "2px solid #E8E1F6",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none",
        },
        ".expandIconWrapper": {
          display: "none",
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "flex",
        },
        ".collapsIconWrapper": {
          display: "flex",
          color: "#9B7CC8",
        },
      }}
    >
      <div className="expandIconWrapper">
        <Minus />
      </div>
      <div className="collapsIconWrapper">
        <img src={AddIcon} alt="AddIcon" />
      </div>
    </Box>
  );
};
export default function MyInvestPlan() {
  const MonthlyInvestments = [
    {
      investmentype: "Total monthly investments",
      investmenvalue: "300 $",
    },
    {
      investmentype: "Token 1",
      investmenvalue: "$100",
    },
    {
      investmentype: "Token 2",
      investmenvalue: "$200",
    },
    {
      investmentype: "Investment timeline",
      investmenvalue: "4 month",
    },
    {
      investmentype: "Rewards",
      investmenvalue: "6.4%",
    },
  ];

  const summeryInvestment = [
    {
      investmentype: "Monthly investment",
      investmenvalue: "100 $",
    },
    {
      investmentype: "Investment timeline",
      investmenvalue: "4 month",
    },
    {
      investmentype: "Rewards",
      investmenvalue: "6.4%",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expanded, setExpanded] = useState<string | false>("panel1");

  const toggle = () => {
    setIsModalOpen((isModalOpen) => !isModalOpen);
  };

  const [cancelPopup, setCancelPopup] = useState(false);
  const cancelSubPopup = () => {
    setCancelPopup((cancelPopup) => !cancelPopup);
  };

  const [editplan, setEditplan] = useState(false);
  const editSubplan = (e?: any) => {
    if (e) {
      e.stopPropagation();
    }
    setEditplan((editplan) => !editplan);
  };

  const [updateSuccessfully, setPdateSuccessfully] = useState(false);
  const updatePlan = () => {
    setPdateSuccessfully((updateSuccessfully) => !updateSuccessfully);
  };
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  return (
    <div className={style.myInvestPlan}>
      {/* <Dashboardtitle title={"My investing plan"} /> */}
      <div className={style.tokenHeader}>
        <Accordion
          className={style.customAccordion}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            sx={{
              padding: "0",
              border: "0",
              flexWrap: "wrap",
              ".css-o4b71y-MuiAccordionSummary-content": {
                flexWrap: "wrap",
                width: "80%",
              },
            }}
            expandIcon={<CustomExpandIcon />}
            aria-controls="panel1a-content"
            id="panel1-header"
            className={style.token}
          >
            <div className={style.tokenIcon}>
              <img src={img} />
              <div className={style.tokenTitle}>
                <h6>$NFTS TOKEN</h6>
                <Link className={style.tokenLink} to="/">
                  1 Crypto Token{" "}
                </Link>
              </div>
            </div>
            <div className={style.stakBtn}>
              <CustomButton
                className={style.editPlan}
                variant={"outlined"}
                onClick={editSubplan}
              >
                Edit Plan
              </CustomButton>
              <div className={style.cancelPlan}>
                <button className={style.CancelPlanLink} onClick={toggle}>
                  Cancel Plan
                </button>
                <CustomModal isOpen={isModalOpen} toggle={toggle}>
                  <EditCancelPopup
                    title={"Cancel investing plan?"}
                    updateBtn={"Yes, Cancel Plan"}
                    cancelBtn={"Cancel"}
                    cancelonClick={() => {
                      setIsModalOpen(false);
                    }}
                    updateonClick={() => {
                      setIsModalOpen(false);
                      cancelSubPopup();
                    }}
                  >
                    <p className={style.popupText}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore
                      magna.
                    </p>
                  </EditCancelPopup>
                </CustomModal>

                <CustomModal isOpen={editplan} toggle={editSubplan}>
                  <EditSavingPlan
                    saveChange={"Confirm"}
                    cancel={"Cancel"}
                    saveonClick={() => {
                      setEditplan(false);
                      updatePlan();
                    }}
                    cancelonClick={() => {
                      setEditplan(false);
                    }}
                    title="Edit Auto-Invest setup"
                    inputAmounttext={
                      <Input
                        label={"Amount"}
                        type={"text"}
                        id={"title"}
                        placeholder={"100$"}
                        name={"title"}
                      />
                    }
                    investmentext="Duration"
                    inputDuration={
                      <Input
                        label={"Custom duration"}
                        type={"text"}
                        id={"month"}
                        placeholder={"4 month"}
                        name={"month"}
                      />
                    }
                  />
                </CustomModal>

                <CustomModal isOpen={updateSuccessfully} toggle={updatePlan}>
                  <LearnMore
                    className={style.editPopup}
                    title={"Your setup was edited!"}
                    icon={<Setting />}
                    buttonContent={"Got it!"}
                    onClick={() => {
                      setPdateSuccessfully(false);
                    }}
                    contnet={"You successfully edited your Auto-Invest setup!"}
                  />
                </CustomModal>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className={style.content}>
            <Typography>
              <div className={style.savingPlanSummary}>
                <Title
                  title={"Saving plan summary"}
                  className={style.contentTitle}
                />
                <div className={style.monthlyInvesting}>
                  {summeryInvestment.map((summertItems, index) => {
                    return (
                      <div className={style.monthlyItems} key={index}>
                        <p>{summertItems.investmentype}</p>
                        <strong>{summertItems.investmenvalue}</strong>
                      </div>
                    );
                  })}
                </div>
                <div className={style.subscriptionDetails}>
                  <h5 className={style.subscriptionTitle}>
                    Subscription details
                  </h5>
                  <p className={style.subscriptonText}>
                    Your plan will be automatically renewed each month until{" "}
                    <b>Jul 16, 2023 </b>
                  </p>
                  <p className={style.subscriptonText}>
                    Next payment date: <b>Mar 16, 2023 </b>
                  </p>
                  <p className={style.subscriptonText}>
                    VAT and local taxes may apply
                  </p>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={style.customAccordion}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            sx={{
              padding: "0",
              border: "0",
              flexWrap: "wrap",
              ".css-o4b71y-MuiAccordionSummary-content": {
                flexWrap: "wrap",
                width: "80%",
              },
            }}
            expandIcon={<CustomExpandIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className={style.token}
          >
            <div className={style.tokenIcon}>
              <img src={img} />
              <img src={star} className={style.star} />
              <div className={style.tokenTitle}>
                <h6>$NFTS TOKEN</h6>
                <Link className={style.tokenLink} to="/">
                  2 Crypto Tokens
                </Link>
              </div>
            </div>
            <div className={style.stakBtn}>
              <CustomButton
                className={style.editPlan}
                variant={"outlined"}
                onClick={editSubplan}
              >
                Edit Plan
              </CustomButton>
              <div className={style.cancelPlan}>
                <button className={style.CancelPlanLink} onClick={toggle}>
                  Cancel Plan
                </button>
                <CustomModal isOpen={isModalOpen} toggle={toggle}>
                  <EditCancelPopup
                    title={"Do you want to cancel your setup?"}
                    updateBtn={"Yes, cancel"}
                    cancelBtn={"No, don’t cancel"}
                    cancelonClick={() => {
                      setIsModalOpen(false);
                    }}
                    updateonClick={() => {
                      setIsModalOpen(false);
                      cancelSubPopup();
                    }}
                  >
                    <p className={style.popupText}>
                      If you answer yes, the setup you created will be deleted
                      and you will have to set it up again.
                    </p>
                  </EditCancelPopup>
                </CustomModal>

                <CustomModal isOpen={cancelPopup} toggle={cancelSubPopup}>
                  <LearnMore
                    className={style.editPopup}
                    title={"Your Setup was canceled!"}
                    icon={<Setting />}
                    buttonContent={"Ok, Thanks!"}
                    onClick={() => {
                      setCancelPopup(false);
                    }}
                    contnet={
                      "You successfully canceled the selected setup. If you want to use Auto-Invest, you can simply create a new Setup!"
                    }
                  />
                </CustomModal>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className={style.content}>
            <Typography>
              <div className={style.savingPlanSummary}>
                <Title
                  title={"Saving plan summary"}
                  className={style.contentTitle}
                />
                <h4 className={style.MonthlyTitle}>Monthly investments</h4>
                <div className={style.monthlyInvesting}>
                  {MonthlyInvestments.map((summertItems, index) => {
                    return (
                      <div className={style.monthlyItems} key={index}>
                        <p>{summertItems.investmentype}</p>
                        <strong>{summertItems.investmenvalue}</strong>
                      </div>
                    );
                  })}
                </div>
                <div className={style.subscriptionDetails}>
                  <h5 className={style.subscriptionTitle}>
                    Subscription details
                  </h5>
                  <p className={style.subscriptonText}>
                    Your plan will be automatically renewed each month until{" "}
                    <b>Jul 16, 2023 </b>
                  </p>
                  <p className={style.subscriptonText}>
                    Next payment date: <b>Mar 16, 2023 </b>
                  </p>
                  <p className={style.subscriptonText}>
                    VAT and local taxes may apply
                  </p>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
