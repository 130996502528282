import { Button, CircularProgress, FormHelperText } from "@mui/material";
import transakSDK from "@transak/transak-sdk";
import clsx from "clsx";
import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InfoIcon } from "../../assets";
import { useAlertMessage } from "../../context/AlertContext";
import { environment } from "../../environment";
import { useGetBalanceQuery } from "../../service/api";
import { useAppSelector } from "../../store/hooks";
import CustomButton from "../CustomButton";
import CustomModal from "../common/CustomModal";
import FlexBox from "../common/FlexBox";
import style from "./index.module.scss";

export type TypeCard = "BUY_CRYPTO" | "BUY_N_TOKEN" | "SELL_N_TOKEN";

type Props = {
  title?: string;
  subtile?: string;
  desCription?: string;
  butBtn: String;
  to: string;
  icon?: ReactElement;
  isInfoIcon?: boolean;
  className?: string;
  type: TypeCard;
};

export default function WalletCard(Props: Props) {
  const {
    title,
    icon,
    isInfoIcon,
    subtile,
    desCription,
    butBtn,
    to,
    type,
    className,
  } = Props;
  const { user } = useAppSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { setAlertMsg } = useAlertMessage();

  const settings = {
    apiKey: environment.TRANSAK_API_KEY,
    environment: environment.TRANSAK_ENVIRONMENT, // STAGING/PRODUCTION
    network: "ethereum",
    walletAddress: user?.depositAddress?.address,
    cryptoCurrencyList: "DAI,USDT,USDC",
    themeColor: "#4E307A", // App theme color
    hostURL: window.location.origin,
    widgetHeight: "600px",
    widgetWidth: "450px",
    isAutoFillUserData: true,
    userData: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      mobileNumber: user?.phone,
      dob: "", //YYYY-MM-DD
      address: {
        addressLine1: user?.address,
        addressLine2: user?.address,
        city: "",
        state: "",
        postCode: "",
        countryCode: user?.countryOfResidence,
      },
    },
  };

  const cryptoBuy = () => {
    let transak = new transakSDK(settings);
    transak.init();
    // To get all the events
    transak.on(transak.ALL_EVENTS, (data: any) => {
      console.log(data);
    });
    // This will trigger when the user closed the widget
    transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (orderData: any) => {
      transak.close();
    });
    transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData: any) => {
      console.log(orderData);
      transak.close();
    });
  };

  const handleBuyTokenChange = (type: "FIAT" | "CRYPTO") => {
    const action = type === "CRYPTO" ? buyNealthyToken : cryptoBuy;
    setOpen(false);
    action();
  };

  const buyNealthyToken = async () => {
    if (!user?.depositAddress) {
      setAlertMsg?.({
        type: "error",
        msg: "Deposit address is not available for this user"
      });
      return;
    }
    navigate("/swap-token");
  };

  const sellNealthyToken = () => {
    navigate("/sell-token");
  };

  const action =
    type === "BUY_CRYPTO"
      ? cryptoBuy
      : type === "BUY_N_TOKEN"
        ? () => setOpen(true)
        : sellNealthyToken;

  return (
    <div className={clsx(style.walletCard, className)}>
      <div className={style.icon}>{icon}</div>
      <div className={style.icon2}>
        <InfoIcon />
      </div>
      <h4>{title}</h4>
      <p>{desCription}</p>
      <CustomButton loading={isLoading} onClick={action}>
        {butBtn}
      </CustomButton>
      <CustomModal isOpen={open} toggle={() => setOpen(false)}>
        <BuyTokenOptions
          onChange={handleBuyTokenChange}
          onCancel={() => setOpen(false)}
        />
      </CustomModal>
    </div>
  );
}

type BuyTokenOptionsProps = {
  onChange: (type: "FIAT" | "CRYPTO") => void;
  onCancel?: () => void;
};

type BuyTokenOptionsType = "FIAT" | "CRYPTO";

export function BuyTokenOptions(props: BuyTokenOptionsProps) {
  const { onChange, onCancel } = props;
  const { data, isLoading, error } = useGetBalanceQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [type, setType] = useState<BuyTokenOptionsType>("FIAT");

  const enable =
    data &&
    data.data.dai + data.data.nStable + data.data.usdc + data.data.usdt > 0;

  const handleChange = (type: BuyTokenOptionsType) => {
    return () => {
      setType(type);
    };
  };

  return (
    <FlexBox className={style.buttonBox}>
      <div className={style.header}>
        <h3>Buy nSTBL token</h3>
        <p>Please select your desired method.</p>
      </div>
      <FlexBox className={style.buttonBox}>
        <Button
          className={clsx(style.button, type === "FIAT" && style.active)}
          onClick={handleChange("FIAT")}
        >
          <h6>Fiat</h6>
          <p>Buy nSTBL with fiat</p>
        </Button>
        <div className={style.buttonWrapper}>
          <Button
            disabled={!enable}
            className={clsx(style.button, type === "CRYPTO" && style.active)}
            onClick={handleChange("CRYPTO")}
          >
            {isLoading && <CircularProgress size={20} color="primary" />}
            {!isLoading && (
              <>
                <h6>Crypto</h6>
                <p>Buy nSTBL with cryptocurrencies (USDT, USDC and DAI)</p>
              </>
            )}
          </Button>
          {!enable && !isLoading && (
            <FormHelperText sx={{ width: "100%", ml: 1 }} error>
              {error
                ? // @ts-ignore
                error?.data?.message ||
                "Something went wrong while fetching balance"
                : "Insufficient balance"}
            </FormHelperText>
          )}
        </div>
      </FlexBox>
      <FlexBox className={style.action}>
        {onCancel && (
          <CustomButton variant="outlined" onClick={() => onCancel()}>
            Cancel
          </CustomButton>
        )}
        <CustomButton onClick={() => onChange(type)}>Continue</CustomButton>
      </FlexBox>
    </FlexBox>
  );
}
