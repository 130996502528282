import clsx from "clsx";
import { nftToken } from "../../../assets";
import CustomButton from "../../CustomButton";
import FlexBox from "../../common/FlexBox";
import styles from "./index.module.scss";

type Data = {
  id: number;
  asset: string;
  repay: string;
  repayBefore: string;
};
interface Props {
  data: {
    id: number;
    asset: string;
    repay: string;
    repayBefore: string;
  }[];
}

type Item = {
  item: {
    id: number;
    asset: string;
    repay: string;
    repayBefore: string;
  };
};
const LoanMobile = ({ data }: Props) => {
  return (
    <div>
      {data.map((item, i) => (
        <StakRow item={item} key={i} />
      ))}
    </div>
  );
};
const StakRow = ({ item }: Item) => {
  return (
    <div className={styles.stakRow}>
      <FlexBox className={styles.flex}>
        <div className={clsx(styles.cell, styles.fullWidth, styles.flexBox)}>
          <div>
            {/* <NftLogo /> */}
            <img src={nftToken} width={48} height={48} />
          </div>
          <div>
            <p>NFT id</p>
            <h5>{item.asset}</h5>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.cell}>
            <p>Amount to repay</p>
            <h5>{item.repay}$NEAL</h5>
          </div>
          <div className={styles.cell}>
            <p>Repay before</p>
            <h5>{item.repayBefore}</h5>
          </div>
        </div>
      </FlexBox>
      <CustomButton variant="outlined" className={styles.claimBtn}>
        Repay
      </CustomButton>
    </div>
  );
};
export default LoanMobile;
