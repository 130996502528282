import ReactSelect from "../../Select";
import { SelectOptions } from "../DepositDetail";
import styles from "./index.module.scss";

type Props = {
  title: string;
  options: SelectOptions[];
  value?: SelectOptions;
  defaultValue?: SelectOptions;
  onChange: any;
  tokenBalance?: number;
  balanceText?: string;
};

const SelectItem = (props: Props) => {
  const {
    title,
    options,
    value,
    defaultValue,
    onChange,
    tokenBalance,
    balanceText,
  } = props;
  return (
    <div className={styles.SelectItem}>
      <div className={styles.SelectItemTitle}>
        <p>{title}</p>
        <p className={styles.balance}>
          {balanceText ?? ""} $ {tokenBalance?.toFixed(6)}
        </p>
      </div>
      <div className={styles.dropdown}>
        <ReactSelect
          options={options}
          id="currency"
          placeholder="Select Currency"
          label=""
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          classNames={styles.reactSelect}
          border={false}
        />
      </div>
    </div>
  );
};

export default SelectItem;
