import React from "react";
import Checkbox from "../Checkbox";
import Dashboardtitle from "../DashbordTitle";
import RadioBtn from "../RadioBtn";
import styles from "./index.module.scss";
import { ReactComponent as Maestro } from "../../assets/icons/Maestro.svg";
import { ReactComponent as Mastercard } from "../../assets/icons/Mastercard.svg";
import { ReactComponent as Visa } from "../../assets/icons/visa-logo.svg";
import { ReactComponent as Payment } from "../../assets/icons/Payment.svg";
import { ReactComponent as PaymentTwo } from "../../assets/icons/Paymenttwo.svg";
import { ReactComponent as PaymentThree } from "../../assets/icons/Paymentthree.svg";
import Input from "../Input";
import CustomButton from "../CustomButton";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

export default function CryptoPayment() {
  const navigate = useNavigate();
  return (
    <div className={styles.paymentInfo}>
      <Dashboardtitle
        className={styles.paymentTitle}
        title={"Select payment method"}
        description={""}
      />
      <div className={styles.paymentForm}>
        <div className={styles.cardDetails}>
          <RadioBtn
            className={styles.credit}
            label={"CREDIT / DEBIT CARD"}
            id={"checkbox"}
          />
          <div className={clsx(styles.visaCard, styles.mobileVisaCard)}>
            <button>{<Visa />}</button>
            <button>{<Mastercard />}</button>
            <button>{<Maestro />}</button>
          </div>
          <Input
            type="number"
            id="Cardnumber"
            name="Cardnumber"
            placeholder="**** **** **** ****"
            label={"Card number"}
          />
          <Input
            type="text"
            id="Cardholder"
            name="Cardholder"
            placeholder="Enter name"
            label={"Card holder"}
          />
          <div className={styles.paymentExpiry}>
            <div className={styles.expiryInput}>
              <Input
                type="text"
                id="Expiry"
                name="Expiry"
                placeholder="mm  / yy"
                label={"Expiry date"}
              />
            </div>
            <div className={styles.expiryInput}>
              <Input
                type="password"
                id="CVV"
                name="CVV"
                placeholder="***"
                label={"CVV"}
              />
            </div>
          </div>
        </div>
        <div className={styles.visaCard}>
          <button>{<Visa />}</button>
          <button>{<Mastercard />}</button>
          <button>{<Maestro />}</button>
        </div>
      </div>
      <div className={styles.checkBoxGroup}>
        <RadioBtn label={"APPLE PAY"} id={"paypal"} />
      </div>
      <div className={styles.checkBoxGroup}>
        <RadioBtn label={"GOOGLE PAY"} id={"klarna"} />
      </div>

      <div className={styles.cryptoBtn}>
        <div className={styles.btnChild}>
          <CustomButton variant={"outlined"}>Cancel</CustomButton>
        </div>
        <div className={styles.btnChild}>
          <CustomButton
            variant={"secondary"}
            type={"submit"}
            onClick={() => navigate("/confirmOrder")}
          >
            Submit
          </CustomButton>
        </div>
      </div>
    </div>
  );
}
