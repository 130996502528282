import clsx from "clsx";
import CompleteRegistration from "../../components/CompleteRegistration";
import DashboardReward from "../../components/DashboardReward";
import Dashboardtitle from "../../components/DashbordTitle";
import Blog from "../Blog";
import styles from "./index.module.scss";

const PendingOnBoardingDashboard = () => {
  return (
    <>
      <Dashboardtitle title={"Dashboard"} />
      <div>
        <CompleteRegistration />
      </div>
      <div className={clsx(styles.container)}>
        <DashboardReward />
        <Blog />
      </div>
    </>
  );
};

export default PendingOnBoardingDashboard;
