import { DepositIcon, IncIcon, WithDrawIcon } from "../assets";

type Styles = {
  Buy?: string;
  Sell?: string;
  Withdraw?: string;
  Deposit?: string;
};

export const getIconByType = (type: Transaction["type"], styles?: Styles) => {
  switch (type) {
    case "Sell":
      return <IncIcon className={styles?.[type]} />;
    case "Buy":
      return <IncIcon className={styles?.[type]} />;
    case "Withdraw":
      return <WithDrawIcon className={styles?.[type]} />;
    case "Deposit":
      return <DepositIcon className={styles?.[type]} />;
    default:
      return "";
  }
};

export const getLabelByType = (type: Transaction["type"]) => {
  switch (type) {
    case "Sell":
      return "Sell";
    case "Buy":
      return "Buy";
    case "Withdraw":
      return "Balance withdrawal";
    case "Deposit":
      return "Balance deposit";
    default:
      return "";
  }
};
