import { Box, FormHelperText } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ReactComponent as SelectIcon } from "../../assets/icons/select-icon.svg";
import { ReactComponent as SelectToken } from "../../assets/icons/select-token-icon.svg";
import { useAlertMessage } from "../../context/AlertContext";
import {
  useGetAccountConfigsQuery,
  useGetBalanceQuery,
  useSwapTokensInfoMutation,
  useSwapTokensMutation,
} from "../../service/api";
import { convertExponentialToString } from "../../utils/helper";
import AlertMessage from "../AlertMessage";
import BackButton from "../BackBtn";
import CustomButton from "../CustomButton";
import Dashboardtitle from "../DashbordTitle";
import style from "./index.module.scss";

type Props = {
  onCancel?: () => void;
};

export default function SwapToken(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const isTokenBuySellRoute =
    location.pathname.split("/")[1] === "token-buy-sell";
  const [to, setTo] = useState("nStable");
  const [from, setFrom] = useState("usdc");
  const [loading, setLoading] = useState(false);
  const [networkFees, setNetworkFees] = useState<number>();
  const [fromValue, setFromValue] = useState(0);
  const [toValue, setToValue] = useState("0");
  const [getSwapInfo] = useSwapTokensInfoMutation();
  const [swapToken] = useSwapTokensMutation();
  const [validationError, setValidationError] = useState("");
  const [touched, setTouched] = useState(false);
  const { alertMsg, setAlertMsg, onCloseAlert } = useAlertMessage();

  const { data, refetch } = useGetBalanceQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const { data: accountConfigsResults, isLoading: accountConfigLoading } =
    useGetAccountConfigsQuery(
      { symbol: from },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const accountConfigs = useMemo(() => {
    return (
      accountConfigsResults?.data || {
        minimumDepositLimit: "0",
        depositLimit: "0",
        minimumWithdrawalLimit: "0",
        withdrawalLimit: "0",
      }
    );
  }, [accountConfigsResults]);

  const { usdc, usdt, dai, nStable } = data?.data || {
    usdc: 0,
    usdt: 0,
    dai: 0,
    nStable: 0,
  };

  const tokenBalance = { usdt, usdc, dai, nStable }[from] || 0;

  const calculateNetworkFees = async () => {
    try {
      setLoading(true);
      const { data } = await getSwapInfo({
        from,
        to,
        value: fromValue,
      }).unwrap();
      setNetworkFees(data.networkFees);
      setToValue(convertExponentialToString(data.tokenValue));
    } catch (error: any) {
      setAlertMsg?.({
        type: "error",
        msg: error?.message || error?.data?.message || "Something went wrong!",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSwap = async () => {
    try {
      setLoading(true);
      await swapToken({
        from,
        to,
        value: convertExponentialToString(fromValue),
      }).unwrap();
      setAlertMsg?.({
        type: "success",
        msg: "Token transferred to your wallet successfully.",
      });

      resetStates();
    } catch (error: any) {
      setAlertMsg?.({
        type: "error",
        msg: error?.message || error?.data?.message || "Something went wrong!",
      });
    } finally {
      setLoading(false);
      refetch();
    }
  };

  const resetStates = () => {
    setNetworkFees(undefined);
    setToValue("");
    setFromValue(0);
  };

  function handleChange(cb: (value: string) => void) {
    return (e: any) => {
      resetStates();
      setTouched(true);
      cb(e.target.value);
    };
  }

  const handleMax = () => {
    const max = parseFloat(accountConfigs.depositLimit);
    setFromValue(() => {
      if (tokenBalance < max) {
        return tokenBalance;
      }
      return max;
    });
  };

  // useEffect(() => {
  //   if (!isLoading && !data) {
  //     navigate("/");
  //   }
  //   if (data && !balance) {
  //     navigate("/");
  //   }
  // }, [data, isLoading, balance]);

  useEffect(() => {
    setFromValue(parseFloat(accountConfigs.minimumDepositLimit));
  }, [accountConfigs]);

  useEffect(() => {
    if (!touched) return;
    setValidationError("");
    const max = parseFloat(accountConfigs.withdrawalLimit);
    const min = parseFloat(accountConfigs.minimumWithdrawalLimit);
    yup
      .number()
      .typeError("")
      .max(max, `This must be less than or equal to ${max}.`)
      .min(min, `This must be more than or equal to ${min}.`)
      .validate(fromValue)
      .then((value = 0) => {
        setValidationError(value > tokenBalance ? "Insufficient balance." : "");
      })
      .catch((error) => setValidationError(error?.message || ""));
  }, [accountConfigs, fromValue]);

  const hasNetworkFees = typeof networkFees !== "undefined";

  return (
    <div className={style.BuyNealthyToken}>
      {!isTokenBuySellRoute && (
        <BackButton
          onClick={() => {
            navigate(-1);
          }}
        />
      )}
      <Dashboardtitle
        className={style.BuyNealthyTitle}
        description={"Swap"}
        title={"Buy Crypto token"}
      />
      {alertMsg?.msg && (
        <AlertMessage type={alertMsg?.type} onClose={onCloseAlert}>
          {alertMsg?.msg}
        </AlertMessage>
      )}

      <div className={style.BuyNealthyCard}>
        <h6>From</h6>
        <span>Balance: {parseFloat(tokenBalance + "").toFixed(5)}</span>
        <div className={style.BuyNealthycrytoValue}>
          <div className={style.inputContainer}>
            <input
              type="number"
              value={fromValue}
              className={style.input}
              placeholder="0"
              onChange={handleChange((value) => {
                setFromValue(parseFloat(value));
              })}
            />
            {validationError && (
              <FormHelperText error>{validationError}</FormHelperText>
            )}
            <span>~ 0.00 (0.00%)</span>
          </div>
          <div className={style.BuyNealthyoptons}>
            <button onClick={handleMax}>Max</button>
            <select value={from} onChange={handleChange(setFrom)}>
              <option value="usdc">USDC</option>
              <option value="usdt">USDT</option>
              <option value="dai">DAI</option>
            </select>
            <SelectIcon />
          </div>
        </div>
      </div>
      <div className={style.BuyNealthyCard}>
        <SelectToken className={style.douwnArrow} />
        <h6>To</h6>
        <div className={style.BuyNealthycrytoValue}>
          <div className={style.inputContainer}>
            <input
              type="number"
              value={toValue}
              className={style.input}
              readOnly
            />
            <span>~ 0.00 (0.00%)</span>
          </div>
          <div className={style.BuyNealthyoptons}>
            <select
              value={to}
              onChange={handleChange(setTo)}
              className={style.select}
            >
              <option value="nStable">NSTBL</option>
              <option disabled value="cry10">
                Cry10
              </option>
            </select>
            <SelectIcon />
          </div>
        </div>
      </div>

      {hasNetworkFees && (
        <div className={style.orderSummary}>
          <h4>1 $NFTS = 0.00001 ETH</h4>
          <div className={style.orderList}>
            <p>Network Fee</p>
            <strong>{networkFees} ETH</strong>
          </div>
        </div>
      )}
      <Box gap="20px" flexDirection="row" display="flex">
        {props.onCancel && (
          <CustomButton
            className={style.BuyNealthyBtn}
            variant={"secondary"}
            disabled={loading}
            onClick={props.onCancel}
          >
            Cancel
          </CustomButton>
        )}
        {hasNetworkFees && (
          <CustomButton
            loading={loading}
            className={style.BuyNealthyBtn}
            variant={"primary"}
            disabled={!!validationError}
            onClick={handleSwap}
          >
            Confirm Buy
          </CustomButton>
        )}

        {!hasNetworkFees && (
          <CustomButton
            loading={loading}
            disabled={!!validationError}
            className={style.BuyNealthyBtn}
            variant={"primary"}
            onClick={calculateNetworkFees}
          >
            Buy
          </CustomButton>
        )}
      </Box>
    </div>
  );
}
