import React from "react";
import { Rewardsdiagram } from "../../../assets/index";
import FlexBox from "../../common/FlexBox";
import Dashboardtitle from "../../DashbordTitle";
import Input from "../../Input";
import MonthButton from "../../MonthButton";
import style from "./index.module.scss";
export default function InvestingPlanDetails() {
  const EarningItems = [
    {
      content: "Monthly earnings",
      value: "$6.40",
    },
    {
      content: "Total earnings",
      value: "$24.004",
    },
  ];
  return (
    <div className={style.InvestingPlanDetails}>
      <Dashboardtitle
        className={style.InvestingPlanTitle}
        title={"Auto-Invest setup"}
        description={
          "Choose how much you want to invest monthly and for how long"
        }
      />
      <div className={style.MinimumText}>
        <p>Minimum: 1$</p>
      </div>
      <Input
        type={"text"}
        placeholder={"$0"}
        label={"Amount"}
        id={"amount"}
        name={"amount"}
      />
      <div className={style.Rewardsdiagram}>
        <Rewardsdiagram />
      </div>
      <p className={style.timeline}>Duration</p>
      <FlexBox className={style.flexButton}>
        <MonthButton clasName={style.investButton} months={"1 month"} />
        <MonthButton months={"3 month"} />
        <MonthButton months={"6 month"} />
        <MonthButton months={"12 month"} />
        <MonthButton months={"Custom"} />
      </FlexBox>
      <div className={style.customTimeline}>
        <Input
          type={"number"}
          placeholder={" "}
          label={"Custom duration"}
          id={"timeline"}
          name={"timeline"}
        />
        <div className={style.month}>month(s)</div>
      </div>
      <div className={style.earningInquery}>
        <div className={style.earningRewards}>
          <p>Rewards</p>
          <strong className={style.Rewards}>6.4%</strong>
        </div>
        {EarningItems.map((items, index) => {
          return (
            <div className={style.earningItems} key={index}>
              <p>{items.content}</p>
              <strong>{items.value}</strong>
            </div>
          );
        })}
      </div>
    </div>
  );
}
