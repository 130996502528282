import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { RightNextIcon } from "../../assets";
import { StepsData, stepsData, stepsDataWithEmail } from "../../pages/Verify";
import { useAppSelector } from "../../store/hooks";
import Card from "../Card";
import CustomButton from "../CustomButton";
import StepInfo from "../StepInfo";
import styles from "./index.module.scss";

const CompleteRegistration = () => {
  const { user, isOnboardingSkipped } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const goToBoarding = async () => {
    navigate("/onboarding");
  };
  const findStep: StepsData[] =
    user?.provider === "facebook" ? stepsDataWithEmail : stepsData;
  return (
    <div
      className={clsx(styles.container, isOnboardingSkipped && styles.unFade)}
    >
      <Card className={styles.complete_Registration}>
        <div className={styles.cardInner}>
          <div className={styles.cardTop}>
            <div className={styles.cardTopLeft}>
              <h2>Complete your account registration!</h2>
              <p>
                In order to offer you as much as you truly deserve, we need to
                complete your registration by going through an effortless
                verification process. Don't be afraid, it doesn't take long!
              </p>
              <span>Pro tip: Have your Identity Documents ready!</span>
            </div>
            <CustomButton onClick={goToBoarding} className={styles.btn}>
              Let's get verified!
            </CustomButton>
          </div>
          <div className={styles.steps}>
            {findStep.map((item, i) => (
              <div key={item.title} className={styles.setIcon}>
                <StepInfo
                  className={styles.InnerContainer}
                  title={item.title}
                  step={i + 1}
                  time={item.time}
                  icon={item.icon}
                />
                <RightNextIcon className={styles.rightArrowIcon} />
              </div>
            ))}
          </div>
          <CustomButton onClick={goToBoarding} className={styles.btn1}>
            Complete Registration
          </CustomButton>
        </div>
      </Card>
    </div>
  );
};

export default CompleteRegistration;
