import clsx from "clsx";
import React from "react";
import { ReactComponent as One } from "../../assets/staking-page-icons/one.svg";
import { ReactComponent as RightArrow } from "../../assets/staking-page-icons/right-right.svg";
import style from "./index.module.scss";
type Props = {
  icon?: React.ReactElement;
  title?: string;
  content?: string;
  className?: string;
};
export default function HowItwork(Props: Props) {
  const { icon, content, title, className } = Props;
  return (
    <div className={clsx(style.howitwork, className)}>
      <div className={style.icon}>{icon}</div>
      <div className={style.content}>
        <h3>{title}</h3>
        <p>{content}</p>
      </div>
    </div>
  );
}
