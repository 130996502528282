import { StepLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { StepIconProps } from "material-ui-core";
import * as React from "react";
import useWindowResize from "../../hooks/useWindowResize";
import mixin from "../../styles/variables.module.scss";
import style from "./index.module.scss";
const steps = [
  "LVL 1  1.000 ",
  "LVL 2  2.500 ",
  "LVL 3  5.000  ",
  "LVL 4  10.000",
  "LVL 5  20.000 ",
];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 12px)",
    right: "calc(50% + 12px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: mixin["stepper-dark"],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: mixin["stepper-dark"],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark"
        ? theme.palette.grey[800]
        : mixin["stepper-light"],
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));
const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: mixin["stepper-dark"],
    }),
    "& .QontoStepIcon-completedIcon": {
      color: mixin["stepper-dark"],
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  })
);
function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed || active ? (
        <div className={style.outerCompleted}>
          <div className={clsx(style.innerCompleted)}></div>
        </div>
      ) : (
        <div className={style.outerNotCompleted}>
          <div className={style.innerNotCompleted}></div>
        </div>
      )}
    </QontoStepIconRoot>
  );
}
export default function LoyaltyStepper() {
  const [activeStep, setActiveStep] = React.useState(2);
  const [completed, setCompleted] = React.useState<any>({});
  const { width } = useWindowResize();
  const breakpoint = 768;
  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };
  return (
    <div className={style.loyaltyStepper}>
      <div className="stepperInnerSection">
        <Box
          sx={{ width: "100%", display: "flex" }}
          className={style.innerStepper}
        >
          {width > breakpoint ? (
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className={style.stepper}
              connector={<QontoConnector />}
            >
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepLabel
                    className={style.commonStep}
                    color="inherit"
                    onClick={handleStep(index)}
                    StepIconComponent={QontoStepIcon}
                    StepIconProps={{
                      classes: {
                        active: style.avtiveIcon,
                        text: style.iconText,
                        completed: style.completed,
                        root: style.root,
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          ) : (
            <Stepper
              orientation="vertical"
              activeStep={activeStep}
              connector={<QontoConnector />}
            >
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel
                      StepIconComponent={QontoStepIcon}
                      StepIconProps={{
                        classes: {
                          active: style.avtiveIcon,
                          text: style.iconText,
                          completed: style.completed,
                          root: style.root,
                        },
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}

          <React.Fragment>
            <div className={style.showInputstepperValue}>
              <h6 className={style.levelTitle}>TIER LEVEL</h6>
              {activeStep + 1}
              <p className={style.labelpoints}>2.600 points</p>
            </div>
          </React.Fragment>
        </Box>
      </div>
    </div>
  );
}
