import clsx from "clsx";
import React from "react";
import CustomButton from "../CustomButton";
import Title from "../Title";
import style from "./index.module.scss";
type Props = {
  title?: string;
  desc?: string;
  cancelBtn?: string;
  updateBtn?: string;
  className?: string;
  icon?: React.ReactElement;
  cancelonClick?: () => void;
  updateonClick?: () => void;
  loading?: boolean;
  disabled?: boolean
};
export default function EditCancelPopup(
  Props: Props & Omit<React.HTMLProps<HTMLButtonElement>, "type">
) {
  const {
    title,
    cancelBtn,
    updateBtn,
    icon,
    className,
    onClick,
    cancelonClick,
    updateonClick,
    loading,
    children,
    disabled,
    desc
  } = Props;
  return (
    <div className={clsx(style.EditCancelPopup, className)}>
      <div className={style.popupIcon}>{icon}</div>
      <Title
        className={style.basicTitle}
        title={title}
        content={desc}
      />
      {children}
      <div className={style.buttonGroup}>
        <div className={style.cancel}>
          <CustomButton variant="outlined" onClick={cancelonClick}>
            {cancelBtn}
          </CustomButton>
        </div>
        <div className={style.update}>
          <CustomButton
            onClick={updateonClick}
            loading={loading}
            disabled={disabled}
          >
            {updateBtn}
          </CustomButton>
        </div>
      </div>
    </div>
  );
}
