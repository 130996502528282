import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControlLabel,
  FormGroup,
  Switch,
  SwitchProps,
  Tooltip,
  Zoom,
} from "@mui/material";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import "react-calendar/dist/Calendar.css";
import { Controller, FieldError, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { InfoIcon } from "../../assets";
import { useAlertMessage } from "../../context/AlertContext";
import {
  UserUpdate,
  useSkipOnBoardingMutation,
  useUpdateUserMutation,
} from "../../service/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { changeSkipStatus, setUserType } from "../../store/reducers/authSlice";
import variable from "../../styles/variables.module.scss";
import { companySizeList, legalStructureList } from "../../utils/constant";
import countryData from "../../utils/country.json";
import { dateFormat } from "../../utils/formatter";
import { personalInfoSchema } from "../../utils/yupSchema";
import AlertMessage from "../AlertMessage";
import CustomButton from "../CustomButton";
import Input from "../Input";
import ReactDatePicker from "../ReactDatePicker";
import ReactSelect from "../Select";
import FlexBox from "../common/FlexBox";
import styles from "./index.module.scss";

export interface OnBoardingProps {
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  setEmail?: Dispatch<SetStateAction<string>>;
  email?: string;
  affliateEmailVerification?: boolean;
}

export const UsernameInfo = () => {
  return (
    <Tooltip
      title={
        "Username should consist of lowercase, alphanumeric and underscore. Ex - user_one123"
      }
      arrow
      TransitionComponent={Zoom}
    >
      <InfoIcon className={styles.infoIcon} />
    </Tooltip>
  );
};

const PersonalInfo = ({
  activeStep,
  setActiveStep,
  setEmail,
}: OnBoardingProps) => {
  const [openCalender, setOpenCalender] = useState<boolean>(false);
  const [nationalityArrayData, setNationalityArrayData] = useState<
    Array<object>
  >([]);
  const [dNationalityData, setDNationalityData] = useState<Array<object>>([]);
  const [isDNationality, setIsDNationality] = useState<boolean>(false);
  const [type, setType] = useState<{ private: boolean; business: boolean }>({
    private: false,
    business: true,
  });

  const { alertMsg, setAlertMsg, onCloseAlert } = useAlertMessage();

  const currentDate = dayjs(new Date()).format("YYYY-MM-DD");
  const { user } = useAppSelector((state) => state.auth);

  const getValue = (value?: string) => {
    if (!value) return;
    return countryData.find((item) => item.value === value);
  };

  const getLegalStructureValue = (value?: string) => {
    if (!value) return;
    return legalStructureList.find((item) => item.value === value);
  };

  const getDataCOR = getValue(user?.countryOfResidence);
  const getNationality = getValue(user?.nationality);
  const getDNationality = getValue(user?.dualNationality);
  const getCountryIncorporation = getValue(user?.countryIncorporation);
  const getCountryOfBusiness = getValue(user?.countryOfBusiness);
  const getTaxJurisdictionCountry = getValue(user?.taxJurisdictionCountry);
  const getLegalStructure = getLegalStructureValue(user?.legalStructure);

  const { control, handleSubmit, formState, setError, reset } = useForm({
    defaultValues: {
      userName: user?.userName ? user?.userName : "",
      firstName: user?.firstName ? user?.firstName : "",
      email: user?.email ? user?.email : "",
      lastName: user?.lastName ? user?.lastName : "",
      dob: currentDate,
      nationality: {
        label: getNationality?.label || "",
        value: getNationality?.value || "",
      },
      dualNationality: {
        label: getDNationality?.label || "",
        value: getDNationality?.value || "",
      },
      countryOfResidence: {
        label: getDataCOR?.label || "",
        value: getDataCOR?.value || "",
      },
      phone: user?.phone ? user?.phone : "",
      companyName: user?.companyName ?? "",
      companySize: user?.companySize ?? "",
      companyAddress: user?.companyAddress ?? "",
      companyAddedBy: user?.companyAddedBy ?? "",
      companyEmail: user?.companyEmail ?? "",
      companyIncorporationNumber: user?.companyIncorporationNumber ?? "",
      countryIncorporation: {
        label: getCountryIncorporation?.label || "",
        value: getCountryIncorporation?.value || "",
      },
      countryOfBusiness: {
        label: getCountryOfBusiness?.label || "",
        value: getCountryOfBusiness?.value || "",
      },
      legalStructure: {
        label: getLegalStructure?.label || "",
        value: getLegalStructure?.value || "",
      },
      listOfBusiness: user?.listOfBusiness ?? "",
      taxJurisdictionCountry: {
        label: getTaxJurisdictionCountry?.label || "",
        value: getTaxJurisdictionCountry?.value || "",
      },
      taxId: user?.taxId ?? "",
    },
    resolver: yupResolver(personalInfoSchema),
  });
  const [updateUser, { isLoading: loading }] = useUpdateUserMutation();
  const [skipOnBoarding, { isLoading }] = useSkipOnBoardingMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const { user }: any = useAppSelector((state) => state.auth);
  const {
    countryOfResidence,
    dob,
    email,
    firstName,
    lastName,
    nationality,
    dualNationality,
    phone,
    userName,
    companyName,
    companyAddress,
    companySize,
    companyAddedBy,
    companyEmail,
    companyIncorporationNumber,
    countryIncorporation,
    countryOfBusiness,
    legalStructure,
    listOfBusiness,
    taxJurisdictionCountry,
    taxId,
  } = formState.errors;

  const skipHandle = async () => {
    const payload = {
      id: user?.id,
      isOnboardingSkipped: true,
    };
    const { data, error } = (await skipOnBoarding(payload)) as SkipStep;
    if (data) {
      dispatch(changeSkipStatus(true));
      navigate("/", { replace: true });
    }
    if (error) {
      setAlertMsg?.({
        type: "error",
        msg: error?.message,
      });
    }
  };

  const validateBusiness = (event: PersonalInfoParam) => {
    if (event.companyName === "") {
      setError("companyName", {
        type: "required",
        message: "Full legal entity name is required!",
      });
      return false;
    } else if (event.companyAddress === "") {
      setError("companyAddress", {
        type: "required",
        message: "Company address is required!",
      });
      return false;
    } else if (event.companyEmail === "") {
      setError("companyEmail", {
        type: "required",
        message: "Company email is required!",
      });
      return false;
    } else if (event.companySize === "") {
      setError("companySize", {
        type: "required",
        message: "Company size is required!",
      });
      return false;
    } else if (event.companyAddedBy === "") {
      setError("companyAddedBy", {
        type: "required",
        message: "Your role in the company is required!",
      });
      return false;
    } else if (event.companyIncorporationNumber === "") {
      setError("companyIncorporationNumber", {
        type: "required",
        message: "Company incorporation number is required!",
      });
      return false;
    } else if (event.countryIncorporation.value === "") {
      setError("countryIncorporation", {
        type: "required",
        message: "Country of incorporation is required!",
      });
      return false;
    } else if (event.countryOfBusiness.value === "") {
      setError("countryOfBusiness", {
        type: "required",
        message: "Country of primary place of operations/business is required!",
      });
      return false;
    } else if (event.legalStructure.value === "") {
      setError("legalStructure", {
        type: "required",
        message: "Legal structure is required!",
      });
      return false;
    } else if (event.listOfBusiness === "") {
      setError("listOfBusiness", {
        type: "required",
        message:
          "List your company's business activities or services is required!",
      });
      return false;
    } else if (event.taxJurisdictionCountry.value === "") {
      setError("taxJurisdictionCountry", {
        type: "required",
        message: "What is your tax jurisdiction is required!",
      });
      return false;
    } else if (event.taxId === "") {
      setError("taxId", {
        type: "required",
        message: "Tax id is required!",
      });
      return false;
    }
    return true;
  };

  const handelUpdateUser = async (event: any) => {
    if (loading) return;
    if (isDNationality && event.dualNationality.value === "") {
      setError("dualNationality", {
        type: "required",
        message: "Dual nationality is required!",
      });
      return false;
    }

    if (type.business) {
      if (!validateBusiness(event)) {
        return;
      }
    }

    const {
      countryOfResidence: cor,
      email,
      nationality,
      dualNationality,
      countryIncorporation,
      countryOfBusiness,
      taxJurisdictionCountry,
      legalStructure,
      dob,
      ...rest
    } = event;
    let payload: UserUpdate = {
      userId: user?.id,
      nationality: nationality?.value,
      dualNationality: dualNationality?.value,
      countryIncorporation: countryIncorporation?.value,
      countryOfBusiness: countryOfBusiness?.value,
      taxJurisdictionCountry: taxJurisdictionCountry?.value,
      countryOfResidence: cor?.value,
      legalStructure: legalStructure?.value,
      dob: dateFormat(dob),
      isCompany: type.business === true,
      ...rest,
    };
    dispatch(setUserType(payload?.isCompany || false));
    if (user?.provider === "facebook") {
      payload.email = email;
    }
    const { data, error }: any = await updateUser({
      ...payload,
    });
    if (data) {
      if (user?.provider === "facebook") {
        localStorage.setItem("email", email);
        setActiveStep((prev) => prev + 1);
      } else {
        setActiveStep((prev) => prev + 1);
      }
    }
    if (error) {
      if (
        error.data.message ==
        "Username already exist, please enter a different name"
      ) {
        setError("userName", { type: "custom", message: error.data.message });
      } else {
        setAlertMsg?.({
          type: "error",
          msg: error?.data?.message,
        });
      }
    }
  };
  const openCalenderHandler = (val: boolean) => {
    setOpenCalender(val);
  };
  const mode: string = "light";
  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            mode === "dark"
              ? variable["text-dark"]
              : variable["btn-text-color"],
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: variable["btn-text-color"],
        border: "6px solid variable['input-border']",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: variable["light-gray"],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor:
        mode === "light"
          ? variable["input-border"]
          : variable["peragraph-text"],
      opacity: 1,
      transition: "0.5s all",
      // theme.transitions.create(['background-color'], {
      //   duration: 500,
      // }),
    },
  }));
  const onChangeSwitch = (e: SyntheticEvent<Element, Event>, type: string) => {
    if (type === "private") {
      setType({ private: true, business: false });
    }
    if (type === "business") {
      setType({ private: false, business: true });
    }
    reset();
    setAlertMsg?.({
      type: undefined,
      msg: "",
    });
  };

  const nationalityData = useWatch({
    control,
    name: "nationality",
  });
  const dNationalityD = useWatch({
    control,
    name: "dualNationality",
  });

  useEffect(() => {
    setNationalityArrayData(countryData);
  }, []);

  useEffect(() => {
    const finalDdata = countryData.filter(
      (val) => val.label != dNationalityD.label
    );
    setNationalityArrayData(finalDdata);
  }, [dNationalityD]);

  useEffect(() => {
    const finalDdata = countryData.filter(
      (val) => val.label != nationalityData.label
    );
    setDNationalityData(finalDdata);
  }, [nationalityData]);

  const preventSpace = (e: any) => {
    if (e.keyCode == 32) return e.preventDefault();
  };

  return (
    <div className={styles.personalInfo}>
      <div className={styles.header}>
        <div className={styles.skip}>
          <h2>General information</h2>
          <h5 className={styles.skipText} onClick={skipHandle}>
            {isLoading ? "Loading..." : "Skip for now"}
          </h5>
        </div>
        {alertMsg?.msg && (
          <AlertMessage type={alertMsg?.type} onClose={onCloseAlert}>
            {alertMsg?.msg}
          </AlertMessage>
        )}

        <div className={styles.switchContainer}>
          <FormGroup>
            <FormControlLabel
              control={
                <IOSSwitch sx={{ m: 1 }} defaultChecked={type.private} />
              }
              label="Individual"
              id="individual"
              name="user"
              value={type.private}
              onChange={(e) => onChangeSwitch(e, "private")}
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <IOSSwitch sx={{ m: 1 }} defaultChecked={type.business} />
              }
              label="Business"
              name="user"
              value={type.business}
              onChange={(e) => onChangeSwitch(e, "business")}
            />
          </FormGroup>
        </div>
        <h5 className={styles.h5}>Personal details </h5>
      </div>
      <form onSubmit={handleSubmit(handelUpdateUser)}>
        <FlexBox className={styles.flexBox}>
          <Controller
            control={control}
            name="userName"
            render={({ field }) => (
              <Input
                type="text"
                className={styles.personalInfo_Form_field}
                placeholder="Enter username, e.g. walt_snow "
                id="userName"
                label="Username"
                error={userName as FieldError}
                info={<UsernameInfo />}
                {...field}
                onKeyDown={(e) => preventSpace(e)}
                required={true}
              />
            )}
          />
          <Controller
            control={control}
            name="firstName"
            render={({ field }) => (
              <Input
                type="text"
                className={styles.personalInfo_Form_field}
                placeholder="Enter first name"
                id="firstName"
                label="First name"
                error={firstName as FieldError}
                {...field}
                onKeyDown={(e) => preventSpace(e)}
                required={true}
              />
            )}
          />
        </FlexBox>
        <FlexBox className={styles.flexBox}>
          <Controller
            control={control}
            name="lastName"
            render={({ field }) => (
              <Input
                type="text"
                className={styles.personalInfo_Form_field}
                placeholder="Enter last name"
                id="lastName"
                label="Last name"
                error={lastName as FieldError}
                {...field}
                onKeyDown={(e) => preventSpace(e)}
                required={true}
              />
            )}
          />
          <Controller
            control={control}
            name="dob"
            render={({ field }) => {
              return (
                <div
                  className={styles.dateContainer}
                  onClick={() => openCalenderHandler(true)}
                >
                  <ReactDatePicker
                    isOpen={openCalender}
                    label="Date of birth"
                    id="dob"
                    openCalenderHandler={openCalenderHandler}
                    {...field}
                    required={true}
                  />
                </div>
              );
            }}
          />
        </FlexBox>
        <FlexBox className={clsx(styles.flexBox)}>
          {nationalityArrayData.length > 0 && (
            <Controller
              control={control}
              name="nationality"
              render={({ field }) => {
                return (
                  <ReactSelect
                    options={nationalityArrayData}
                    value={field.value}
                    onChange={(e: any) => {
                      field.onChange(e);
                    }}
                    id="nationality"
                    placeholder="Select nationality"
                    label="Nationality"
                    error={nationality?.label as FieldError}
                    required={true}
                    errorPosition="left"
                  />
                );
              }}
            />
          )}
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <Input
                className={clsx(
                  user?.provider === "facebook"
                    ? styles.showEmail
                    : styles.hideEmail
                )}
                type="text"
                placeholder="Enter email"
                id="email"
                label="Email"
                error={lastName as FieldError}
                {...field}
              />
            )}
          />
          {/* </span> */}
        </FlexBox>

        <FlexBox className={clsx(styles.flexBoxhorizontal)}>
          <Input
            value={isDNationality ? "true" : "false"}
            name="terms"
            noWidth
            placeholder=""
            type="checkbox"
            id="checkbox"
            onChange={() => setIsDNationality(!isDNationality)}
            className={styles.checkboxsize}
          />
          <label htmlFor="checkbox" className={styles.dnationality}>
            I am a dual nationality holder
          </label>
          {dNationalityData.length > 0 && isDNationality && (
            <Controller
              control={control}
              name="dualNationality"
              render={({ field }) => {
                return (
                  <ReactSelect
                    options={dNationalityData}
                    disabled={!isDNationality}
                    value={field.value}
                    onChange={(e: any) => {
                      field.onChange(e);
                    }}
                    id="dualNationality"
                    placeholder="Select nationality"
                    label={"Second nationality"}
                    error={dualNationality as FieldError}
                    errorPosition="left"
                    required={isDNationality}
                  />
                );
              }}
            />
          )}
        </FlexBox>
        <h5 className={styles.loc}>Residency & contact</h5>
        <FlexBox className={styles.flexBox}>
          <Controller
            control={control}
            name="countryOfResidence"
            render={({ field }) => {
              return (
                <ReactSelect
                  options={countryData}
                  value={field.value}
                  onChange={(e: any) => {
                    field.onChange(e);
                  }}
                  id="countryOfResidence"
                  placeholder="Select country"
                  label="Country of residence"
                  error={countryOfResidence?.label as FieldError}
                  required={true}
                  errorPosition="left"
                />
              );
            }}
          />
          <Controller
            control={control}
            name="phone"
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Enter phone number e.g. +48 000 000 000"
                id="phone"
                className={styles.personalInfo_Form_field}
                label="Phone number"
                maxLength="15"
                error={phone as FieldError}
                max={""}
                {...field}
                required={true}
              />
            )}
          />
        </FlexBox>
        {type.business && (
          <div>
            <h5 className={styles.loc}>Company details </h5>
            <FlexBox className={styles.flexBox}>
              <Controller
                control={control}
                name="companyName"
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter legal entity name"
                    className={styles.personalInfo_Form_field}
                    id="companyName"
                    label="Full legal entity name"
                    error={companyName as FieldError}
                    max={""}
                    {...field}
                    required={true}
                  />
                )}
              />
              <Controller
                control={control}
                name="companyAddress"
                render={({ field }) => (
                  <Input
                    type="text"
                    className={styles.personalInfo_Form_field}
                    placeholder="Enter company address"
                    id="companyAddress"
                    label="Company address"
                    error={companyAddress as FieldError}
                    max={""}
                    {...field}
                    required={true}
                  />
                )}
              />
            </FlexBox>
            <FlexBox className={styles.flexBox}>
              <Controller
                control={control}
                name="companyEmail"
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter company email"
                    className={styles.personalInfo_Form_field}
                    id="companyEmail"
                    label="Company email address"
                    error={companyEmail as FieldError}
                    max={""}
                    {...field}
                    required={true}
                  />
                )}
              />
              <Controller
                control={control}
                name="companySize"
                render={({ field }) => {
                  return (
                    <ReactSelect
                      options={companySizeList}
                      // value={field.value}
                      onChange={(e: any) => {
                        field.onChange(e.value);
                      }}
                      id="companySize"
                      placeholder="Select company size "
                      label="Number of Employees within the Company"
                      error={companySize as FieldError}
                      required={true}
                    />
                  );
                }}
              />
            </FlexBox>
            <FlexBox className={styles.flexBox}>
              <Controller
                control={control}
                name="companyAddedBy"
                render={({ field }) => (
                  <Input
                    type="text"
                    className={styles.personalInfo_Form_field}
                    placeholder="E.g. Owner"
                    id="companyAddedBy"
                    label="Your role in the company"
                    error={companyAddedBy as FieldError}
                    {...field}
                    required={true}
                  />
                )}
              />
              <Controller
                control={control}
                name="companyIncorporationNumber"
                render={({ field }) => (
                  <Input
                    type="text"
                    className={styles.personalInfo_Form_field}
                    placeholder="E.g. MNOP1234"
                    id="companyIncorporationNumber"
                    label="Company incorporation number"
                    error={companyIncorporationNumber as FieldError}
                    {...field}
                    required={true}
                  />
                )}
              />
            </FlexBox>
            <FlexBox className={styles.flexBox}>
              <Controller
                control={control}
                name="countryIncorporation"
                render={({ field }) => {
                  return (
                    <ReactSelect
                      options={countryData}
                      value={field.value}
                      onChange={(e: any) => {
                        field.onChange(e);
                      }}
                      id="countryIncorporation"
                      placeholder="Select country"
                      label="Country of incorporation"
                      error={countryIncorporation as FieldError}
                      required={true}
                      errorPosition="left"
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="countryOfBusiness"
                render={({ field }) => {
                  return (
                    <ReactSelect
                      options={countryData}
                      value={field.value}
                      onChange={(e: any) => {
                        field.onChange(e);
                      }}
                      id="countryOfBusiness"
                      placeholder="Select country"
                      label="Country of primary place of operations/business"
                      error={countryOfBusiness as FieldError}
                      required={true}
                      errorPosition="left"
                    />
                  );
                }}
              />
            </FlexBox>
            <FlexBox className={styles.flexBox}>
              <Controller
                control={control}
                name="legalStructure"
                render={({ field }) => {
                  return (
                    <ReactSelect
                      options={legalStructureList}
                      value={field.value}
                      onChange={(e: any) => {
                        field.onChange(e);
                      }}
                      id="legalStructure"
                      placeholder="Select country"
                      label="Legal structure"
                      error={legalStructure as FieldError}
                      required={true}
                      errorPosition="left"
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="listOfBusiness"
                render={({ field }) => (
                  <Input
                    type="text"
                    className={styles.personalInfo_Form_field}
                    placeholder="E.g. buy/sell crypto"
                    id="listOfBusiness"
                    label="List your company's business activities or services"
                    error={listOfBusiness as FieldError}
                    {...field}
                    required={true}
                  />
                )}
              />
            </FlexBox>
            <FlexBox className={styles.flexBox}>
              <Controller
                control={control}
                name="taxJurisdictionCountry"
                render={({ field }) => {
                  return (
                    <ReactSelect
                      options={countryData}
                      value={field.value}
                      onChange={(e: any) => {
                        field.onChange(e);
                      }}
                      id="taxJurisdictionCountry"
                      placeholder="Select country"
                      label="What is your tax jurisdiction?"
                      error={taxJurisdictionCountry as FieldError}
                      required={true}
                      errorPosition="left"
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="taxId"
                render={({ field }) => (
                  <Input
                    type="text"
                    className={styles.personalInfo_Form_field}
                    placeholder="E.g. MNOP1234"
                    id="taxId"
                    label="What is your tax ID"
                    error={taxId as FieldError}
                    {...field}
                    required={true}
                  />
                )}
              />
            </FlexBox>
          </div>
        )}
        <div className={styles.btnContainer}>
          {/* <h5>Wallet info</h5>
                    <p>Do you have a wallet? Otherwise we will create an instant wallet for you.</p>
                    <div className={styles.walletBtn}>Connect My Wallet</div> */}
          <CustomButton
            className={styles.continueBtn}
            // disabled={!formState.isValid}
            loading={loading}
          >
            Continue
          </CustomButton>
        </div>
        <div className={styles.skipMobile}>
          <h5 className={styles.skipText} onClick={skipHandle}>
            {isLoading ? "Loading..." : "Skip for now"}
          </h5>
        </div>
      </form>
    </div>
  );
};

export default PersonalInfo;
