import clsx from "clsx";
import { KeyboardEventHandler, useState } from "react";
import OTPInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { Done } from "../../assets";
import AlertMessage from "../../components/AlertMessage";
import CustomButton from "../../components/CustomButton";
import { useAlertMessage } from "../../context/AlertContext";
import BasicLayout from "../../layout/BasicLayout";
import {
  useOtpVerify2faMutation
} from "../../service/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { checkUserRegister, loginUser } from "../../store/reducers/authSlice";
import styles from "../Register/index.module.scss";

export default function Otp2FA() {
  const {
    state: { email, type },
  } = useLocation();

  const [otp, setOtp] = useState<string>("");
  const [response, setResponse] = useState<string>("");
  const { alertMsg, setAlertMsg, onCloseAlert } = useAlertMessage();
  const { isRegistered } = useAppSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [verifyOtp, { isLoading }] = useOtpVerify2faMutation();

  const onSubmit = async () => {
    if (otp.length < 6 || isLoading) return;
    const payload = {
      email,
      otp,
    };
    const { data, error }: any = await verifyOtp(payload);
    if (data) {
      const { user, token, refreshToken } = data.data;
      dispatch(loginUser({ user, token, refreshToken }));
      dispatch(checkUserRegister({ isRegistered: isRegistered ?? false, registeredOtp: isRegistered ? true : false }));
      if (user?.isOnboardingCompleted === false) {
        return navigate("/boarding_details", {
          state: data,
          replace: true
        });
      } else if (user?.isOnboardingCompleted && user?.skipToDashboard == true) {
        localStorage.setItem("token", token);
        return navigate("/", {
          state: data,
          replace: true,
        });
      } else {
        localStorage.setItem("token", token);
        return navigate("/boarding", {
          state: data,
          replace: true,
        });
      }
    }
    if (error) {
      setAlertMsg?.({
        type: "error",
        msg: error?.data?.message
      });
    }
  };

  const isLetter = () => {
    if (otp.match(/[a-z]/i) === null) {
      return false;
    } else {
      return true;
    }
  };
  const enterKeyHandler: KeyboardEventHandler<HTMLFormElement> = (e) => {
    if (e.key === "Enter") {
      if (otp.length < 6) return;
      onSubmit();
    }
  };

  const forgotUi = () => {
    return (
      <div className={styles.forgotUI}>
        <Done />
        <h1>Congratulations</h1>
        <p>{response}</p>
        <div className={styles.loginBtnContainer}>
          <CustomButton onClick={() => navigate("/login", { replace: true })}>
            Login
          </CustomButton>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.bg}>
      <div className={styles.signup_page}>
        <BasicLayout>
          <div className={styles.sign_form}>
            {response ? (
              forgotUi()
            ) : (
              <div>
                <h2 className={styles.create}>Verify 2FA Authentication</h2>
                <p className={styles.createContent}>
                  Please enter 6-digit code we send to your authenticator.
                </p>
                {alertMsg?.msg && <AlertMessage type={alertMsg.type} onClose={onCloseAlert}>{alertMsg.msg}</AlertMessage>}
                <form
                  onKeyDown={enterKeyHandler}
                  className={styles.verify_code}
                >
                  <OTPInput
                    onChange={setOtp}
                    value={otp}
                    inputStyle="inputStyle"
                    containerStyle={styles.reactOtp}
                    numInputs={6}
                    shouldAutoFocus={true}
                    renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                    renderInput={(props) => {
                      return (
                        <input
                          {...props}
                          type="number"
                          value={props.value}
                          className={styles.inputOtp}
                          placeholder="0"
                        />
                      );
                    }}
                  />
                </form>
                <div
                  className={clsx(
                    styles.input_group,
                    styles.login,
                    styles.submit
                  )}
                >
                  <CustomButton
                    disabled={otp.length !== 6 || isLetter()}
                    onClick={onSubmit}
                  >
                    {isLoading ? "Loading..." : "Submit"}
                  </CustomButton>
                </div>
                {/* <ForgotTwoFAuth email={email} setResponse={setResponse} /> */}
              </div>
            )}
          </div>
        </BasicLayout>
      </div>
    </div>
  );
}
