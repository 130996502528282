import { configureStore } from "@reduxjs/toolkit";
import { api } from "../service/api";
import { onBoardSlice } from "./onBoardingUser";
import authSlice from "./reducers/authSlice";
// ...

export const store = configureStore({
  reducer: {
    user: onBoardSlice.reducer,
    auth: authSlice,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
