import clsx from "clsx";
import React from "react";
import style from "./index.module.scss";
type Props = {
  from?: string;
  chainIcon?: React.ReactElement;
  douwnIcon?: React.ReactElement;
  chooseitem?: string;
  className?: string;
  onClick?: () => void;
};
export default function ChooseToken(props: Props) {
  const { from, onClick, chainIcon, douwnIcon, chooseitem, className } = props;
  return (
    <div className={clsx(style.chooseChain, className)}>
      <p className={style.from}>{from}</p>
      <div className={style.selectchain}>
        <div className={style.Icom}>{chainIcon}</div>
        <div className={style.selectdropMenu} onClick={onClick}>
          {chooseitem} {douwnIcon}
        </div>
      </div>
    </div>
  );
}
