import CustomButton from "../../CustomButton";
import styles from "./index.module.scss";
interface STAK {
  stak: {
    amount: {
      min: string;
      max: string;
    };
    reward: {
      min: string;
      max: string;
    };
  };
}
const StakDesktop = ({ stak }: STAK) => {
  return (
    <div className={styles.stakContainer}>
      <p>
        {stak.amount.min} $NEAL<span>{stak.amount.max}</span>
      </p>
      <p>
        {stak.reward.min} $NEAL<span>{stak.reward.max}</span>
      </p>
      <div className={styles.claim}>
        <CustomButton variant="outlined" className={styles.claimBtn}>
          Claim
        </CustomButton>
      </div>
    </div>
  );
};
export default StakDesktop;
