import clsx from "clsx";
import React from "react";
import CustomButton from "../CustomButton";
import style from "./index.module.scss";
type Props = {
  scr: string;
  title: string;
  content: string;
  btn: string;
  ARP: string;
  icon?: React.ReactElement;
  ArpVAlue: any;
  totalStake: string;
  totalStakeValue: any;
  alt: string;
  className?: string;
};

export default function TokenCard(Props: Props) {
  const {
    scr,
    title,
    content,
    btn,
    alt,
    totalStakeValue,
    totalStake,
    ArpVAlue,
    icon,
    ARP,
    className,
  } = Props;
  return (
    <div className={clsx(style.mainBox, className)}>
      <div className={style.image}>
        {" "}
        <img src={scr} alt={alt} />
      </div>

      <h3>{title}</h3>
      <p>{content}</p>
      <div className={style.totalStake}>
        <div className={style.totalDetails}>
          <span>
            {ARP}
            {icon}
          </span>
          <strong>{ArpVAlue}</strong>
        </div>
        <hr className={style.boxBorder} />
        <div className={style.totalDetails}>
          <span>{totalStake}</span>
          <strong>{totalStakeValue}</strong>
        </div>
      </div>

      <CustomButton>{btn}</CustomButton>
    </div>
  );
}
