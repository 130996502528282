import clsx from "clsx";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  LeftDirectionicon,
  Rightdirection,
  UserAvatar,
  WalletIconOutline,
} from "../../assets";
import style from "../../components/headerIcon/index.module.scss";
import { useAppSelector } from "../../store/hooks";
import EditCancelPopup from "../EditCacelPopup";
import Notifiaction from "../Notifiaction";
import ProfileDropdown from "../ProfileDropdown";
import CustomModal from "../common/CustomModal";
export default function HeaderIcons() {
  const { user } = useAppSelector((state) => state.auth);

  // const Notifacations = [
  //   {
  //     subTitle: "Next step — Add funds to your crypto account!",
  //     contents: "To start using the crypto platform to its full potenti...",
  //     days: "2 days ago",
  //   },
  //   {
  //     subTitle: "Next step — Add funds to your crypto account!",
  //     contents: "To start using the crypto platform to its full potenti...",
  //     days: "2 days ago",
  //   },
  //   {
  //     subTitle: "Next step — Add funds to your crypto account!",
  //     contents: "To start using the crypto platform to its full potenti...",
  //     days: "2 days ago",
  //   },
  //   {
  //     subTitle: "Next step — Add funds to your crypto account!",
  //     contents: "To start using the crypto platform to its full potenti...",
  //     days: "2 days ago",
  //   },
  //   {
  //     subTitle: "Next step — Add funds to your crypto account!",
  //     contents: "To start using the crypto platform to its full potenti...",
  //     days: "2 days ago",
  //   },
  // ];

  const [notification, setNotifiacation] = useState(false);
  const openNotification = () => {
    setNotifiacation((notification) => !notification);
  };
  const [notifDetails, setNotifDetails] = useState(false);
  const openNotifDetails = () => {
    setNotifDetails((notifDetails) => !notifDetails);
    openNotification();
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ul className={style.flaticon}>
        <li>
          <Notifiaction />
        </li>
        <li className={clsx(style.camera, style.space)}>
          <Link to="/mywallet">
            <WalletIconOutline />
          </Link>
        </li>
        <li className={clsx(style.avatar, style.space)} onClick={handleClick}>
          {user?.image ? (
            <div className={style.picContainer}>
              <img src={user?.image} width={100} height={100} alt="avatar" />
            </div>
          ) : (
            <UserAvatar />
          )}
        </li>
      </ul>
      <ProfileDropdown
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />

      <CustomModal
        isOpen={notifDetails}
        toggle={openNotifDetails}
        classNames={{ modal: style.notifiDetailsPopUp }}
      >
        <EditCancelPopup cancelBtn="close" updateBtn="View More">
          <div className={style.notifDetails}>
            <Rightdirection />
            <h2 className={style.detailsTitle}>Notification details</h2>
            <LeftDirectionicon />
          </div>
          <div className={style.detailsDescription}>
            <h6 className={style.nextSteoTitle}>
              Next step — Add funds to your crypto account!{" "}
            </h6>
            <span className={style.date}>2023-03-01 09:43:56</span>
            <p className={style.peragraph}>
              To start using the crypto platform to its full potential and claim
              the many rewards you might earn, you will want to add funds to
              your account.
              <br />
              <br />
              Click on “View More” to begin your crypto journey!
            </p>
          </div>
        </EditCancelPopup>
      </CustomModal>
    </div>
  );
}
