import clsx from "clsx";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Plus } from "../../../assets/saving-plan-img/plus.svg";
import Card from "../../Card";
import FlexBox from "../../common/FlexBox";
import CustomButton from "../../CustomButton";
import CustomLink from "../../CustomLink";
import OverviewWallet from "../../overviewWallet";
import SavingPlanBox from "../../SavingPlanBox";
import Title from "../../Title";
import style from "./index.module.scss";
import {
  DollarIcon,
  RepeatIcon,
  SafeIcon,
  coinSample,
  coinSample2,
  NftToken,
  PiggyBank,
  Profit,
} from "../../../assets";
export default function MonthlyPlan() {
  const [thankyou, setThankyou] = useState(false);
  const thankyouPop = () => {
    setThankyou((thankyou) => !thankyou);
  };
  const navigate = useNavigate();

  return (
    <div className={style.monthlyPlan}>
      {/* <Dashboardtitle title={"Monthly investing plan"} /> */}
      <FlexBox className={style.flexMonthly}>
        <div className={style.lookinginvestment}>
          <p className={style.investmentText}>
            Are you the DCA kind of investor? We stand by your side with our
            novel Auto-Invest system and offer the perfect tool for simple and
            efficient Dollar-Cost Averaging.
          </p>
          <p className={style.investmentText}>
            With Auto-Invest, you can set your own budget however you like and
            steadily grow your portfolio on a monthly basis without having to
            stress about timing the market or missing an opportunity. Less
            stress is healthy, and if it’s healthy, it’s Crypto!
          </p>
          <CustomLink
            className={style.TokensLink}
            link={
              <div>
                Of course, if you’d rather go with a one-time buy, we got you
                covered <Link to=""> here.</Link>
              </div>
            }
          />
        </div>
        <div className={style.quickLinks}>
          <h4 className={style.quckTitle}>Quick links</h4>
          <Link to="/" className={style.linksText}>
            More details about Auto-Invest
          </Link>
          <Link to="/" className={style.linksText}>
            How to buy a Crypto token?
          </Link>
          <Link to="/subscribePlan" className={style.linksText}>
            How to buy a Crypto token?
          </Link>
        </div>
      </FlexBox>
      <Title title={"Auto-Invest in an Indicator Token"} />
      <FlexBox className={style.planFlex}>
        <SavingPlanBox
          className={clsx(style.removeIcon, style.autoInvestBox)}
          title={"$NFTS TOKEN"}
          // onClick={toggle}
          startIcon={
            <div>
              <img src={coinSample} alt="tokenone" />
            </div>
          }
          content={
            "$NFTS focuses on the 10 top trading assets on the NFT market."
          }
          average={"Average %"}
          duration={"Duration"}
          flexible={"Flexible"}
          value={"12.3%"}
          button={"Select"}
          nealthToken={"Single asset"}
        />
        <SavingPlanBox
          className={clsx(style.removeIcon, style.autoInvestBox)}
          title={"$CRY10 TOKEN"}
          startIcon={
            <div>
              <img src={coinSample2} alt="tokensecond" />
            </div>
          }
          content={
            "$CRY10 focuses on the 10 top trading assets on the cryptocurrency market."
          }
          average={"Average %"}
          duration={"Duration"}
          flexible={"Flexible"}
          value={"12.3%"}
          button={"Select"}
          nealthToken={"Single asset"}
        />
        <SavingPlanBox
          className={style.autoInvestBox}
          title={"$NFTS + $CRY10 TOKEN"}
          startIcon={
            <div>
              <img src={coinSample} alt="tokenone" />
            </div>
          }
          lastIcon={
            <div>
              <img src={coinSample2} alt="tokesecond" />
            </div>
          }
          content={
            "Combo Auto-Invest allows you to set up monthly purchase for each token, all at once."
          }
          average={"Average %"}
          duration={"Duration"}
          flexible={"Flexible"}
          value={"12.3%"}
          button={"Select"}
          plusicon={<Plus />}
          nealthToken={"All assets combo "}
        />
      </FlexBox>
      {/* <CustomModal isOpen={isModalOpen} toggle={toggle}>
        <EditCancelPopup
          title={"Cancel investing plan?"}
          updateBtn={"Yes, Cancel Plan"}
          cancelBtn={"Cancel"}
        >
          <p className={style.popupText}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna.
          </p>
        </EditCancelPopup>
      </CustomModal> */}
      {/* <CustomModal isOpen={thankyou} toggle={thankyouPop}>
        <EditCancelPopup
          title={"Cancel investing plan?"}
          updateBtn={"Ok, Thanks!"}
          onClick={thankyouPop}
        >
          <p className={style.popupText}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna.
          </p>
        </EditCancelPopup>
      </CustomModal> */}

      <Title
        className={style.investingTitle}
        title={"Custom Setup"}
        content={
          "Do you want to have full control of you investment process? You can also create your own custom Auto-Invest setup for maximum flexibility!"
        }
      />
      <FlexBox className={style.planFlex}>
        <Card>
          <OverviewWallet
            className={style.selectPlanBox}
            title={"FOR ANY DURATION"}
            Icon={<NftToken />}
            description={
              <div>
                Choose the duration that best suits your goals and needs, and
                respect your comfort zone.
              </div>
            }
          />
        </Card>
        <Card>
          <OverviewWallet
            className={style.selectPlanBox}
            title={"FOR ANY TOKEN"}
            Icon={<PiggyBank />}
            description={
              <div>
                Select only what you are interested in, be it a single asset or
                several.
              </div>
            }
          />
        </Card>
        <Card>
          <OverviewWallet
            className={style.selectPlanBox}
            title={"FOR ANY AMOUNT"}
            Icon={<Profit />}
            description={
              <div>
                Decide exactly how much is enough, so you are always in control
                of your budget.
              </div>
            }
          />
        </Card>
      </FlexBox>
      <CustomButton
        className={style.investingButton}
        onClick={() => navigate("/customsavingplan")}
      >
        Start Custom Setup
      </CustomButton>
    </div>
  );
}
