import clsx from "clsx";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Social } from ".";
import CustomButton from "../../components/CustomButton";
import Input from "../../components/Input";
import ReactSelect from "../../components/Select";
import FlexBox from "../../components/common/FlexBox";
import { socialmedia } from "../../utils/constant";
import styles from "./index.module.scss";

type Props = {
  socialDetails: Social;
  setSocialDetails: Dispatch<SetStateAction<Social>>;
  socialItem: Social[];
  setSocialItem: Dispatch<SetStateAction<Social[]>>;
};
const SocialAccount = (props: Props) => {
  const { socialDetails, setSocialDetails, socialItem, setSocialItem } = props;
  const [option, setOption] = useState(socialmedia)

  const filteredOption = socialmedia.filter(item => {
    return !socialItem.some(el => el.social.value === item.value)
  })

  useEffect(() => {
    setOption(filteredOption)
  }, [socialItem])

  const addSocialHandler = () => {
    setSocialItem([...socialItem, socialDetails]);
    setSocialDetails({
      social: {
        label: "",
        value: "",
      },
      username: "",
    });
  };

  const deleteSocialRow = (i: number) => {
    const row = [...socialItem];
    row.splice(i, 1);
    setSocialItem(row);
  };
  return (
    <>
      <div className={styles.socialMulti}>
        <FlexBox className={clsx(styles.flexBox, styles.flex)}>
          <div className={styles.field}>
            <ReactSelect
              // options={socialmedia}
              options={option}
              value={socialDetails.social.label ? socialDetails.social : null}
              onChange={(e: any) => {
                setSocialDetails({ ...socialDetails, social: e })
              }
              }
              id="socialAccounts"
              placeholder="Choose upto six Social Media platform"
              label={"Which other Social Media platform are you using?"}
            />
          </div>
          <div className={clsx(styles.field, styles.btnField)}>
            <Input
              type="text"
              id="username"
              placeholder="Enter your username"
              label={"User name"}
              name="username"
              value={socialDetails.username}
              onChange={(e) =>
                setSocialDetails({ ...socialDetails, username: e.target.value })
              }
            />
            <>
              <CustomButton
                type="button"
                id="addSocial"
                className={styles.addBtn}
                onClick={addSocialHandler}
                disabled={
                  !socialDetails.username || !socialDetails.social.value
                }
              >
                +
              </CustomButton>
            </>
          </div>
        </FlexBox>
      </div>
      {socialItem.map((item, i) => (
        <div className={styles.socialMulti} key={i}>
          <FlexBox className={clsx(styles.flexBox, styles.flex)}>
            <div className={clsx(styles.field, styles.disabledPoint)}>
              <ReactSelect
                options={socialmedia}
                value={socialItem[i].social}
                onChange={(e) => { }}
                id="socialAccounts"
                placeholder=""
                label={""}
              />
            </div>
            <div className={clsx(styles.field, styles.btnField)}>
              <Input
                type="text"
                id="username"
                placeholder=""
                label={""}
                name="username"
                value={socialItem[i].username}
                onChange={(e) => { console.log(e) }}
                className={styles.disabledPoint}
              />
              <CustomButton
                type="button"
                className={styles.crossBtn}
                variant="outlined"
                onClick={() => deleteSocialRow(i)}
              >
                x
              </CustomButton>
            </div>
          </FlexBox>
        </div>
      ))}
    </>
  );
};

export default SocialAccount;
