import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import DataTable, { TableColumn } from "react-data-table-component";
import { Douwnload, tok1 } from "../../../assets/index";
import useTransactions from "../../../hooks/useTransactions";
import { getImageBySymbol } from "../../../utils/constant";
import {
  convertNstableLabel,
  formatAmountToFixed,
} from "../../../utils/helper";
import { getIconByType, getLabelByType } from "../../../utils/transaction";
import CustomButton from "../../CustomButton";
import Spinner from "../../Spinner";
import FlexBox from "../../common/FlexBox";
import style from "../Transactions/index.module.scss";

type Props = {
  type?: Transaction["type"];
  title?: string;
};
const headers = [
  { label: "Transaction", key: "transaction" },
  { label: "Date", key: "date" },
  { label: "Amount", key: "amount" },
  { label: "Transaction Fee", key: "transactionFee" },
  { label: "Status", key: "status" },
];
export default function Transactions(props: Props) {
  const { title, type } = props;

  const {
    transactions,
    applyFilter,
    loadMoreCount,
    loadMore,
    filter,
    loading,
  } = useTransactions({ type });

  const columns: TableColumn<Transaction>[] = [
    {
      name: "Token",
      selector: (row) => row.currency,
      width: "100px",
      cell: (row) => {
        return (
          <div className={style.logoContainer}>
            <img
              src={getImageBySymbol[row.currency.toUpperCase()] || tok1}
              alt="Token"
            />
          </div>
        );
      },
    },
    {
      name: "Transaction",
      selector: (row) => row.type,
      cell: (row) => {
        return (
          <div className={style.transactionType}>
            <div className={style.iconContainer}>
              {getIconByType(row.type, { Buy: style.buyIcon })}
            </div>
            <p className={style.typeText}>{getLabelByType(row.type)}</p>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => dayjs(row.createdTimeStamp).format("DD.MM.YYYY"),
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => {
        return (
          <p className={style.amount}>{`${formatAmountToFixed(
            row.amount
          )} ${convertNstableLabel(row.currency)}`}</p>
        );
      },
      style: { textAlign: "left" },
    },
    {
      name: "Transaction fees",
      selector: (row) => "0",
      sortable: true,
      cell: (row) => {
        return (
          <p className={style.amount}>
            {`${(row.gasFees / 10e17).toFixed(8)}`} ETH
          </p>
        );
      },
    },
    {
      name: "Status",
      selector: (row) => "Successful",
      sortable: true,
      cell: (row) => {
        return <p className={style.amount}>{`${row.status}`}</p>;
      },
    },
  ];

  const tableCustomCss = {
    rows: {
      style: {
        backgroundColor: "#F9F7FC",
        border: "0 !important",
        borderRadius: "12px",
        marginBottom: "12px",
        minHeight: "64px",
      },
    },
    headCells: {
      style: {
        color: "#9599B5",
        fontSize: "12px",
        fontWeight: "500",
        border: "0 !important",
      },
    },
    cells: {
      style: {
        color: "#1B4458",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
  };

  const [downladableData, setDownladableData] = useState<DownloadTransaction[]>(
    []
  );

  useEffect(() => {
    const downloadD = transactions.map((datum) => {
      return {
        transaction: datum.type,
        date: datum.createdTimeStamp,
        amount: datum.amount,
        transactionFee: 0,
        // gasFees: datum.gasFees,
        status: datum.status ? datum.status : "SUCCESS",
      };
    });
    setDownladableData(downloadD);
  }, [transactions]);

  return (
    <div className={style.transactions}>
      {title && <h3>{title}</h3>}
      {!type && (
        <FlexBox className={style.mywalletTable}>
          <FlexBox className={style.tableButtons}>
            <CustomButton
              variant="outlined"
              className={clsx(style.eachButton, filter === "" && style.active)}
              onClick={applyFilter("")}
            >
              All<span>&nbsp;time</span>
            </CustomButton>
            <CustomButton
              variant="outlined"
              className={clsx(
                style.eachButton,
                filter === "7d" && style.active
              )}
              onClick={applyFilter("7d")}
            >
              Last 7 d<span>ays</span>
            </CustomButton>
            <CustomButton
              variant="outlined"
              className={clsx(
                style.eachButton,
                filter === "30d" && style.active
              )}
              onClick={applyFilter("30d")}
            >
              Last 30 d<span>ays</span>
            </CustomButton>
            <CustomButton
              variant="outlined"
              className={clsx(
                style.eachButton,
                filter === "6m" && style.active
              )}
              onClick={applyFilter("6m")}
            >
              Last 6 m<span>onths</span>
            </CustomButton>
          </FlexBox>

          <CustomButton variant="outlined" className={style.douwnload}>
            <Douwnload className={style.douwnloadIcon} />
            {downladableData && (
              <CSVLink
                data={downladableData}
                headers={headers}
                filename="transactions.csv"
                className="hidden"
                target="_blank"
              >
                Download CSV
              </CSVLink>
            )}
          </CustomButton>
        </FlexBox>
      )}
      <DataTable
        columns={columns}
        data={transactions}
        customStyles={tableCustomCss}
      />
      {loading && <Spinner />}
      {loadMoreCount !== 0 && (
        <CustomButton
          variant="outlined"
          className={style.loadMore}
          onClick={loadMore}
        >
          Load {loadMoreCount} More
        </CustomButton>
      )}
    </div>
  );
}
