import React, { useState } from "react";
import { Rewardsdiagram } from "../../../assets/index";
import FlexBox from "../../common/FlexBox";
import Dashboardtitle from "../../DashbordTitle";
import Input from "../../Input";
import MonthButton from "../../MonthButton";
import SelectBox from "../../SelectBox.tsx";
import style from "./index.module.scss";
import { durationBtn } from "../../SavingPlans/EditSavingPlan";
import clsx from "clsx";

export default function AutoSetup() {
  const [addElement, setAddElement] = useState(1);
  const EarningItems = [
    {
      content: "Monthly earnings",
      value: "$6.40",
    },
    {
      content: "Total earnings",
      value: "$24.004",
    },
  ];
  const secletToken = () => {};
  const optionData = [
    {
      id: 1,
      value: "Token name",
    },
    {
      id: 2,
      value: "Token name",
    },
    {
      id: 2,
      value: "Token name",
    },
  ];
  const AddInputElement = (props: { index: number }) => (
    <div>
      <h4 className={style.tokenHeading}>TOKEN {props.index}</h4>
      <FlexBox className={style.flexItems}>
        <div className={style.selsectBox}>
          <SelectBox
            label="Select token"
            placeholder="Token name"
            onChange={secletToken}
            id="tokenBox"
            data={optionData}
          />
        </div>
        <div className={style.selsectBox}>
          <div className={style.MinimumText}>
            <p>Minimum: 1$</p>
          </div>
          <Input
            type={"text"}
            placeholder={"$0"}
            label={"Amount (monthly)"}
            id={"amount"}
            name={"amount"}
          />
        </div>
      </FlexBox>
    </div>
  );
  const [selectedDuration, setSelectedDuration] = useState<number>(1);
  const handleSelectDuration = (val: number) => {
    setSelectedDuration(val);
  };
  return (
    <div className={style.autoPlanDetails}>
      <Dashboardtitle
        className={style.InvestingPlanTitle}
        title={"Auto-Invest setup"}
        description={
          "Choose how much you want to invest monthly and for how long, click on “Add Token” if you want to Auto-Invest into more than one."
        }
      />
      {[...Array(addElement)].map((_, i) => (
        <AddInputElement key={i} index={i + 1} />
      ))}
      <button
        className={style.addToken}
        onClick={() => setAddElement(addElement + 1)}
      >
        {addElement >= 2 ? (
          <span className={style.addAnotherToken}>Add Another Token</span>
        ) : (
          "Add Token"
        )}
      </button>
      <div className={style.Rewardsdiagram}>
        <Rewardsdiagram />
      </div>
      <p className={style.timeline}>Duration</p>
      <FlexBox className={style.flexButton}>
        {durationBtn.map((btn) => (
          <MonthButton
            key={btn.value}
            clasName={clsx(style.investButton)}
            selectedDuration={selectedDuration}
            value={btn.value}
            months={btn.mainVal}
            onClick={() => handleSelectDuration(btn.value)}
          />
        ))}
      </FlexBox>
      <div className={style.earningInquery}>
        <div className={style.earningRewards}>
          <p>Rewards</p>
          <strong className={style.Rewards}>6.4%</strong>
        </div>
        {EarningItems.map((items, index) => {
          return (
            <div className={style.earningItems} key={index}>
              <p>{items.content}</p>
              <strong>{items.value}</strong>
            </div>
          );
        })}
      </div>
    </div>
  );
}
