import { Step, StepLabel, Stepper } from "@mui/material";
import React, { FC, useState } from "react";
import OnBoardHeader from "../../components/BoardingHeader";
import EmailVerification from "../../components/EmailVerification";
import { useAppSelector } from "../../store/hooks";
import {
  affiliateSteps,
  stepsWithEmailVerification,
} from "../../utils/constant";
import AffiliateApply from "../AffiliateApply";
import styles from "./index.module.scss";

const AffiliateBoarding: FC = () => {
  const [email, setEmail] = useState<string>("");
  const { user, isLoading, onboardingStep } = useAppSelector(
    (state) => state.auth
  );

  const findStep = (val: number) => {
    if (user?.provider === "facebook") {
      return val;
    } else {
      return 0;
    }
  };
  // const [activeStep, setActiveStep] = React.useState<number>(findStep(user ?.onboardingStep));
  const [activeStep, setActiveStep] = React.useState<number>(0);

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <AffiliateApply
            // refetch={refetch}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        );
      case 1:
        return (
          <EmailVerification
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            email={email}
            affliateEmailVerification={true}
          />
        );
    }
  };

  const checkStep =
    user?.provider === "existing_user"
      ? stepsWithEmailVerification
      : affiliateSteps;

  return isLoading ? (
    <h1>Loading...</h1>
  ) : (
    <div className={styles.boarding}>
      <OnBoardHeader />
      <div className={styles.formWrapper}>
        <div className={styles.stepperContainer}>
          <Stepper activeStep={activeStep} className={styles.stepper}>
            {checkStep.map((step) => (
              <Step key={step}>
                <StepLabel
                  color="inherit"
                  style={{ whiteSpace: "nowrap" }}
                  StepIconProps={{
                    classes: {
                      active: styles.avtiveIcon,
                      text: styles.iconText,
                      completed: styles.completed,
                      // root: styles.iconNotActive
                    },
                  }}
                >
                  {step}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {getStepContent(activeStep)}
      </div>
    </div>
  );
};

export default AffiliateBoarding;
