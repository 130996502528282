import { useState } from "react";
import {
  CandleIcon,
  CloudIcon,
  Illustration,
  TagIcon,
  bannerImg,
  coinIcon1,
  coinIcon2,
  coinIcon3,
  CoinPrice,
  ApyLogo,
  StackedCoin,
} from "../../assets/index";
import blogimg from "../../assets/staking-page-icons/blogimg.jpg";
import { ReactComponent as Info } from "../../assets/staking-page-icons/info.svg";
import { ReactComponent as One } from "../../assets/staking-page-icons/one.svg";
import { ReactComponent as RightArrow } from "../../assets/staking-page-icons/right-right.svg";
import { ReactComponent as Three } from "../../assets/staking-page-icons/three.svg";
import { ReactComponent as Two } from "../../assets/staking-page-icons/two.svg";
import Accordion from "../Accordion/Accordion";
import Banner from "../Banner";
import CustomButton from "../CustomButton";
import Dashboardtitle from "../DashbordTitle";
import HowItwork from "../HowItwork";
import LearnMore from "../LearnMore";
import NFTBlog from "../NFTBlog";
import StakingCard from "../StakingCard";
import TokenCard from "../TokenCard";
import CustomModal from "../common/CustomModal";
import FlexBox from "../common/FlexBox";
import style from "./index.module.scss";

export const accordionItems = [
  {
    title: "What is Crypto?",
    content: (
      <div>
        <p>
          Lorem Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages, and more recently
          with desktop publishing software like Aldus PageMaker including
          versions of Lorem Ipsum.
        </p>
      </div>
    ),
  },
  {
    title: "What is Crypto?",
    content: (
      <div>
        <p>
          Lorem Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages, and more recently
          with desktop publishing software like Aldus PageMaker including
          versions of Lorem Ipsum.
        </p>
      </div>
    ),
  },
  {
    title: "What is Crypto?",
    content: (
      <div>
        <p>
          Lorem Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages, and more recently
          with desktop publishing software like Aldus PageMaker including
          versions of Lorem Ipsum.
        </p>
      </div>
    ),
  },
  {
    title: "What is Crypto?",
    content: (
      <div>
        <p>
          Lorem Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages, and more recently
          with desktop publishing software like Aldus PageMaker including
          versions of Lorem Ipsum.
        </p>
      </div>
    ),
  },
  {
    title: "What is Crypto?",
    content: (
      <div>
        <p>
          Lorem Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages, and more recently
          with desktop publishing software like Aldus PageMaker including
          versions of Lorem Ipsum.
        </p>
      </div>
    ),
  },
  {
    title: "What is Crypto?",
    content: (
      <div>
        <p>
          Lorem Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages, and more recently
          with desktop publishing software like Aldus PageMaker including
          versions of Lorem Ipsum.
        </p>
      </div>
    ),
  },
];
export default function StakeInfo() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggle = () => {
    if (isModalOpen == true) {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
  };
  return (
    <div className={style.stakeInfo}>
      {/* <Dashboardtitle title={"Staking info"} description={""} /> */}
      {/* <div className={style.stakeYour}>
        <div className={style.stakeYourText}>
          <Dashboardtitle title={"Stake your $NFTS"} description={""} />
        </div>
        <div className={style.stakeYourImg}>
          <img src={Car} alt="car" />
        </div>
      </div> */}
      <Banner
        title="Stake your $NFTS"
        content="Unlock the full potential of your digital assets with our staking platform and start earning passive income right now! Staking your $NFTS Tokens unlocks an attractive APY to further your return opportunities and maximize your portfolio’s performance!"
        contentsec="And that’s not all! By staking any Indicator tokens, you’ll get $NEAL tokens, unlocking yet another layer of benefits such as governance for example!"
        src={bannerImg}
        alt="bannerImg"
        icon={<Illustration />}
      ></Banner>
      <Dashboardtitle
        description={""}
        title={"Can we interest you in a quick staking?"}
      />
      <FlexBox className={style.tokenFlex}>
        <StakingCard
          className={style.stakeIcon}
          text={"Coin price"}
          values={"1.923,7eth"}
          icon={<CoinPrice />}
        />
        <StakingCard
          className={style.stakeIcon}
          text={"Current APY"}
          values={"7.6%"}
          icon={<ApyLogo />}
        />
        <StakingCard
          className={style.stakeIcon}
          text={"Staked amount"}
          values={"$10.00"}
          icon={<StackedCoin />}
        />
      </FlexBox>

      <div className={style.InfoNeal}>
        <Dashboardtitle
          title="Staking calculator"
          className={style.calculator}
        />
        <div className={style.innerLeft}>
          <div className={style.cryptoAmount}>
            <li>
              <p>Amount to stake ($NFTS)</p>
              <strong>0 $NEAL</strong>
            </li>
            <li>
              <p>Fiat value to stake (USD)</p>
              <strong className={style.textRight}>$0.00</strong>
            </li>
          </div>
          <div className={style.ProgressBar}></div>
          <label htmlFor="">Title</label>
          <input type="text" placeholder="0" className={style.titleInput} />
          <div className={style.mainTerms}>
            <span>Term</span>
            <div className={style.terms}>
              <button>1 month</button>
              <button>6 month</button>
              <button>12 month</button>
              <button>18 month</button>
            </div>
          </div>
        </div>
        <div className={style.innerRight}>
          <div className={style.rewards}>
            <ul>
              <li>
                <p>Rewards</p>
                <strong className={style.blue}>6.4%</strong>
              </li>
              <li>
                <CustomButton
                  onClick={() => setIsModalOpen(true)}
                  className={style.learnMore}
                  variant={"outlined"}
                >
                  Learn More
                </CustomButton>
                <CustomModal isOpen={isModalOpen} toggle={toggle}>
                  <LearnMore
                    title={"Learn more"}
                    buttonContent={"Ok, Got It!"}
                    contnet={
                      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC."
                    }
                  />
                </CustomModal>
              </li>
            </ul>
            <div className={style.cryptoAmount}>
              <li>
                <p>Daily earnings</p>
                <strong>
                  0.00 $NFTS <span>$0.00</span>
                </strong>
              </li>
              <li>
                <p>Yearly earnings</p>
                <strong>
                  0.00 $NFTS<span>$0.00</span>
                </strong>
              </li>
            </div>
          </div>
          <p>
            *Please note that these numbers are dynamic in nature and might
            change as they are partly influenced by a variety of external
            factors such as the token’s price, the general market and more.
          </p>
        </div>
      </div>
      <Dashboardtitle title={"Staking in 3 easy steps!"} />

      <div className={style.gridsystem}>
        <HowItwork
          title={"SELECT REQUIREMENT"}
          content={"Select requirement from the list below"}
          icon={<One />}
        />
        <RightArrow className={style.rightIcon} />
        <HowItwork
          title={"SELECT REQUIREMENT"}
          content={"Select requirement from the list below"}
          icon={<Two />}
        />
        <RightArrow className={style.rightIcon} />
        <HowItwork
          title={"SELECT REQUIREMENT"}
          content={"Select requirement from the list below"}
          icon={<Three />}
        />
      </div>

      <Dashboardtitle
        title={"Which token do you want to stake?"}
        description={""}
      />
      <FlexBox className={style.tokenFlex}>
        <TokenCard
          title={"$NFTS TOKEN"}
          content={
            "$NFTS focuses on the 10 top trading assets on the NFT market. Staking $NFTS earns you an APY and $NEAL tokens which unlock even more benefits on our platform!"
          }
          className={style.stakeBorderRemove}
          btn={"Stake Now"}
          scr={coinIcon1}
          totalStake={"Total staked"}
          totalStakeValue={"$124.472,8992"}
          ARP={"APR"}
          ArpVAlue={"4.9 %"}
          alt={"image"}
          icon={<Info />}
        />
        <TokenCard
          title={"$CRY10 TOKEN"}
          content={
            "$CRY10 focuses on the 10 top trading assets on the cryptocurrency market. Staking $CRY10 earns you an APY and $NEAL tokens which unlock even more benefits on our platform!"
          }
          btn={"Buy"}
          scr={coinIcon2}
          totalStake={"Total staked"}
          totalStakeValue={"$124.472,8992"}
          ARP={"APR"}
          ArpVAlue={"4.9 %"}
          alt={"image"}
          icon={<Info />}
        />
        <TokenCard
          className={style.disabledBox}
          title={"$GAME TOKEN"}
          content={
            "$GAME will focus on the blockchain-based gaming market. From equipment to lands, the most valuable items will be covered!"
          }
          btn={"Coming Soon"}
          scr={coinIcon3}
          totalStake={"Total staked"}
          totalStakeValue={"$124.472,8992"}
          ARP={"APR"}
          ArpVAlue={"4.9 %"}
          alt={"image"}
          icon={<Info />}
        />
      </FlexBox>
      <Dashboardtitle title={"FAQ"} description={""} />
      <Accordion items={accordionItems} />
      <Dashboardtitle
        title={"From the blog"}
        description={"Learn more about the Crypto, our technology & earnings"}
      />
      <FlexBox className={style.tokenFlex}>
        <NFTBlog
          blogTitle={"NFTs: What is the hype about?"}
          alt={"blog-image"}
          date={"Feb 20, 2023"}
          byTime={"by Tim Pascual"}
          src={blogimg}
        />
        <NFTBlog
          blogTitle={"NFTs: What is the hype about?"}
          alt={"blog-image"}
          date={"Feb 20, 2023"}
          byTime={"by Tim Pascual"}
          src={blogimg}
        />
        <NFTBlog
          blogTitle={"NFTs: What is the hype about?"}
          alt={"blog-image"}
          date={"Feb 20, 2023"}
          byTime={"by Tim Pascual"}
          src={blogimg}
        />
      </FlexBox>
    </div>
  );
}
