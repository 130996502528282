export const dateAndTimeConversion = (val: number) => {
  const getYear = new Date(val).getFullYear();
  const getMonth = new Date(val).getMonth();
  const getDay = new Date(val).getUTCDate();
  const getHrs = new Date(val).getHours();
  const getMin = new Date(val).getMinutes();
  return { getYear, getMonth, getDay, getHrs, getMin };
};

export const dateAndTimeFormat = (sortVal: string) => {
  if (sortVal === "24h") {
    return { format: "MMM DD(HH:mm)", type: "hrs" };
  } else if (sortVal === "1w" || sortVal === "1m") {
    return { format: "MMM DD", type: "" };
  } else if (sortVal === "1y") {
    return { format: "YYYY", type: "" };
  } else {
    return { format: "YYYY", type: "" };
  }
};

export const sum = (previous: number, current: number) => {
  const total = previous + current;
  return total;
};

export const convertExponentialToString = (value: number) => {
  if (value.toString().includes("e")) {
    return value.toFixed(Math.abs(parseInt(`${value}`.replace(/.e/, ""))));
  }
  return value.toString();
};

export const sliceTextWithThreeDots = (text?: string) => {
  return text?.slice(0, 10) + "...";
};

export const convertNstableLabel = (currency: string) => {
  return currency.toLowerCase() === "nstable" ? "NSTBL" : currency;
};
export const formatAmountToFixed = (amount: any) => {
  return parseFloat(amount.toString()).toFixed(6);
};
