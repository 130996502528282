import clsx from "clsx";
import { forwardRef, useState } from "react";
import DatePicker from "react-date-picker";
import { FieldError } from "react-hook-form";
import "./datePicker.module.scss";
import styles from "./index.module.scss";

type Props = {
  isOpen?: boolean;
  label?: string;
  id?: string;
  openCalenderHandler?: ((val: boolean) => void) | undefined;
  error?: FieldError | undefined;
  required?: boolean;
};

const ReactDatePicker = forwardRef((props: Props, ref) => {
  const { id, isOpen, label, openCalenderHandler, error, required, ...rest } =
    props;
  const [value, setValue] = useState(new Date());
  const onChange = (val: any) => {
    setValue(val);
  };
  const handleCalendarClose = () => {
    openCalenderHandler?.(false);
  };
  return (
    <div className={styles.dateMain}>
      <label htmlFor={id} className={clsx(required && styles.star)}>
        {label}
      </label>
      <div className={clsx(styles.datePicker, isOpen && styles.active)}>
        <DatePicker
          className={styles.custom_date_picker}
          onChange={onChange}
          value={value}
          clearIcon={null}
          calendarIcon={null}
          isOpen={isOpen}
          onCalendarClose={handleCalendarClose}
          maxDate={new Date()}
          {...rest}
        />
      </div>
      <div className={clsx(styles.error, !error && styles.displayNone)}>
        {error?.message}
      </div>
    </div>
  );
});

export default ReactDatePicker;
