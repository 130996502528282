import { FormHelperText } from "material-ui-core";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { WalletIconOutlineWhite, tok1 } from "../../assets";
import { useAlertMessage } from "../../context/AlertContext";
import { useGetBalanceQuery, useSellTokensMutation } from "../../service/api";
import { formatAmountToFixed } from "../../utils/helper";
import BackButton from "../BackBtn";
import CustomButton from "../CustomButton";
import Dashboardtitle from "../DashbordTitle";
import style from "./index.module.scss";

export default function SellToken() {
  const navigate = useNavigate();
  const location = useLocation();
  const isTokenBuySellRoute =
    location.pathname.split("/")[1] === "token-buy-sell";

  const [loading, setLoading] = useState(false);

  const [usdc, setUsdc] = useState<number | undefined>(0);
  const [usdt, setUsdt] = useState<number | undefined>(0);
  const [dai, setDai] = useState<number | undefined>(0);
  const { setAlertMsg } = useAlertMessage();

  const balance = {
    dai: dai ? dai : 0,
    usdt: usdt ? usdt : 0,
    usdc: usdc ? usdc : 0,
  };

  const [sellToken] = useSellTokensMutation();

  const { data, refetch } = useGetBalanceQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const handleSell = async () => {
    try {
      setLoading(true);
      await sellToken(balance).unwrap();
      setAlertMsg?.({
        type: "success",
        msg: "Token transferred to your wallet successfully.",
      });
      resetStates();
    } catch (error: any) {
      setAlertMsg?.({
        type: "error",
        msg: error?.message || error?.data?.message || "Something went wrong!",
      });
    } finally {
      setLoading(false);
      refetch();
    }
  };

  const resetStates = () => {
    setUsdc(0);
    setUsdt(0);
    setDai(0);
  };

  function handleChange(cb: (value?: number) => void) {
    return (e: any) => {
      cb(e.target.value ? parseFloat(e.target.value) : undefined);
    };
  }

  const nStable = data?.data?.nStable || 0;
  const nStableOnHold = data?.data?.nStableHold || 0;
  const total = balance.dai + balance.usdc + balance.usdt;

  const disabled =
    balance.dai < 0 ||
    balance.usdt < 0 ||
    balance.usdc < 0 ||
    nStable < total ||
    total === 0;

  return (
    <div className={style.SellNealthyToken}>
      {!isTokenBuySellRoute && (
        <BackButton
          onClick={() => {
            navigate(-1);
          }}
        />
      )}
      <div className={style.titleContainer}>
        <Dashboardtitle
          className={style.SellNealthyTitle}
          title={"Sell Crypto token"}
        />
        <div className={style.nStableContainer}>
          <WalletIconOutlineWhite />
          <span>nSTBL: {formatAmountToFixed(nStable)} </span>
        </div>
      </div>
      {nStableOnHold !== 0 && (
        <span className={style.nStableBalance}>
          <img src={tok1} alt="nSTBL token" />
          nSTBL(Sell in progress): {formatAmountToFixed(nStableOnHold)}
        </span>
      )}
      <div className={style.SellNealthyCard}>
        <h6>USDT</h6>
        <div className={style.SellNealthycrytoValue}>
          <div className={style.inputContainer}>
            <input
              type="number"
              value={usdt}
              className={style.input}
              onChange={handleChange(setUsdt)}
            />
            {balance.usdt < 0 && (
              <FormHelperText error className={style.error}>
                Value should be a positive number
              </FormHelperText>
            )}
          </div>
        </div>
      </div>
      <div className={style.SellNealthyCard}>
        <h6>USDC</h6>
        <div className={style.SellNealthycrytoValue}>
          <div className={style.inputContainer}>
            <input
              type="number"
              value={usdc}
              className={style.input}
              onChange={handleChange(setUsdc)}
            />
            {balance.usdc < 0 && (
              <FormHelperText error className={style.error}>
                Value should be a positive number
              </FormHelperText>
            )}
          </div>
        </div>
      </div>
      <div className={style.SellNealthyCard}>
        <h6>DAI</h6>
        <div className={style.SellNealthycrytoValue}>
          <div className={style.inputContainer}>
            <input
              type="number"
              value={dai}
              className={style.input}
              onChange={handleChange(setDai)}
            />
            {balance.dai < 0 && (
              <FormHelperText error className={style.error}>
                Value should be a positive number
              </FormHelperText>
            )}
          </div>
        </div>
      </div>
      {total > nStable - nStableOnHold && (
        <FormHelperText error className={style.error}>
          Insufficient balance
        </FormHelperText>
      )}
      <CustomButton
        loading={loading}
        className={style.SellNealthyBtn}
        variant={"primary"}
        disabled={disabled}
        onClick={handleSell}
      >
        Sell
      </CustomButton>
    </div>
  );
}
