import { SerializedError } from "@reduxjs/toolkit";

import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { environment } from "../environment";
import { logoutUser, setTokens } from "../store/reducers/authSlice";
import { RootState } from "../store/store";

type ApiResponse<T> = {
  data: T;
};

type StatusResponse = {
  errorType: string;
};

export type emailResponse = {
  email?: string;
  affiliateCode?: string;
  data: {
    message: string;
    data?: {
      user?: User;
      token?: string;
    };
  };
  error: {
    data: {
      message: string;
      data?: StatusResponse;
    };
  };
};

type Social = {
  email: string;
  provider?: string;
};
export type Email = {
  email: string;
  affiliateCode?: string;
  isCompany?: boolean;
};
export type FB = {
  facebookUserId: string;
  provider: string;
};
type EmailOtp = {
  email?: string;
  otp?: string;
};
type ID = {
  userId: string;
};
interface QuestionReq {
  userId: string | undefined;
  option: Options[];
}
export type Options = {
  // id: string;
  answer?: string;
  type?: string;
  // title?: string;
  createdTimeStamp: string;
  id: string;
  title: string;
  comment?: string;
  updatedTimeStamp: string;
  question?: string;
};

type SubmitQuestion2 = {
  answer?: string;
  question: string;
  type?: string;
};
type SubmitQuestion = {
  data: {
    data: {
      userDetails: {
        questions: SubmitQuestion2[];
      };
    };
  };
};
export type Question = {
  createdTimeStamp: string;
  id: string;
  options: Options[];
  question: string;
  updatedTimeStamp: string;
  type: string;
  userId?: string | undefined;
  isKybQue: boolean;
  isChildQue: boolean;
  parentQueId: string;
  dependentAnsId: string;
  isTooltip: boolean;
  toolTips: string;
  heading: string;
};
interface Questions {
  data: {
    data: Question[];
  };
}

export type Token = {
  token: string;
  userId?: string | undefined;
};

interface Tokens {
  data: Token;
}

export type UserUpdate = {
  userId?: string;
  otp?: string;
  email?: string;
  userName?: string;
  firstName?: string;
  lastName?: string;
  dob?: string;
  nationality?: string;
  dualNationality?: string;
  countryOfResidence?: string;
  phone?: string;
  is2FAEnable?: boolean;
  isCompany?: boolean;
};
interface UpdateProfiles {
  data: {
    updatedUser: {
      affected: number;
      generatedMaps: string[];
      raw: string[];
    };
  };
  message: string;
  status: boolean;
}
export interface ResponseData {
  data?: UpdateProfiles;
  error?: FetchBaseQueryError | SerializedError;
}
type Country = {
  country: string | undefined;
};
export type UpdateProfileBody = {
  userId: string | undefined;
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  address?: Country;
  userName?: string;
  timeZone?: string | undefined;
  language?: string | undefined;
  countryOfResidence?: string | undefined;
  nationality?: string | undefined;
};

type UpdateDashboardBody = {
  userId: string | undefined;
  skipToDashboard: boolean;
};

type UpdateDocument = {
  userId?: string;
  file?: string;
};
export type FBLOGIN = {
  data: {
    token: string;
    user: User;
    refreshToken: string;
  };
  message: string;
  success: boolean;
};
// const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiOTk3MWU1NjMtNWU4MS00MGI3LWE4NjMtMjgwYWMxMmVlMjhmIiwiZW1haWwiOiJ1c2VyM0B0ZXN0LmluIiwidXNlck5hbWUiOiIiLCJmaXJzdE5hbWUiOiIiLCJsYXN0TmFtZSI6IiIsImZ1bGxOYW1lIjoiIiwiaXNLWUNlZCI6ZmFsc2UsInN0YXR1cyI6InBlbmRpbmciLCJjcmVhdGVkVGltZVN0YW1wIjoiMjAyMy0wNS0yNFQwNzoxMzoyOC44NDNaIiwidXBkYXRlZFRpbWVTdGFtcCI6IjIwMjMtMDUtMjRUMDc6MTM6MjguODQzWiIsImlzVGVybXNBY2NlcHRlZCI6ZmFsc2UsInRlcm1zQWNjZXB0ZWREYXRlIjpudWxsLCJkb2IiOm51bGwsIm5hdGlvbmFsaXR5IjoiIiwicGhvbmUiOiIiLCJpc0NvbXBhbnkiOmZhbHNlLCJwcm92aWRlciI6Im1hbnVhbCIsImlzT25ib2FyZGluZ0NvbXBsZXRlZCI6ZmFsc2UsIm9uYm9hcmRpbmdTdGVwIjoxLCJhZGRyZXNzIjpudWxsLCJkb2N1bWVudCI6bnVsbCwiaW1hZ2UiOiIiLCJxdWVzdGlvbnMiOm51bGx9LCJpYXQiOjE2ODUwMTAzMzN9.mJijk_OMVQxLkFZNMafCypU4B6E6cVygHui-Y8VJwAg"
type SKIP = {
  id: string | undefined;
  isOnboardingSkipped: boolean;
};

type KYC = {
  reviewId: string;
  attemptId: string;
  attemptCnt: number;
  levelName: string;
  createDate: string;
  reviewReasonCode: string;
  moderationTierType: string | null;
  reviewStatus: string;
  priority: number;
  reviewResult?: {
    reviewAnswer?: string;
  };
};
interface KYC_STAT {
  data: {
    data: KYC;
  };
  message: string;
  success: boolean;
}

interface PORT_FOLIO {
  data: {
    data: portfolio_history[];
    assetsData: assets_data[];
    currentValue: number;
  };
  message: string;
  success: boolean;
}

type ProfileResponse = {
  data: {
    user: User;
  };
};

type Otp2FA = {
  userId?: string;
  email?: string;
  otp?: string;
};

type CheckAffiliateCode = {
  affiliateCode?: string;
};

export type LevelName = "basic-kyc-level" | "verified-plus" | "basic-kyb-level";

export const enum AccountStatusType {
  PENDING = "PENDING",
  VERIFIED_PLUS = "VERIFIED_PLUS",
  REJECTED = "REJECTED",
}

const baseQuery = fetchBaseQuery({
  baseUrl: `${environment.BASE_API_URL}/api`,
  prepareHeaders: async (headers, { getState }) => {
    const { token } = (getState() as RootState).auth;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

type GetAccessTokenApiResponse = {
  data: { accessToken: string; refreshToken: string };
};
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const { data } = await baseQuery(
      {
        url: "/user/get-access-token",
        method: "POST",
        body: {
          refreshToken: localStorage.getItem("refreshToken"),
          type: "user",
        },
      },
      api,
      extraOptions
    );
    const tokenResult = data as GetAccessTokenApiResponse;
    if (tokenResult && tokenResult.data) {
      // store the new token
      api.dispatch(setTokens(tokenResult.data as any));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logoutUser());
    }
  }
  return result;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    emailLogin: builder.mutation<emailResponse, Email>({
      query: (body) => {
        return {
          url: "/user/login",
          method: "POST",
          body,
        };
      },
    }),
    emailSingup: builder.mutation<Email, Email>({
      query: (body) => {
        return {
          url: "/user/signup",
          method: "POST",
          body,
        };
      },
    }),
    getEmailOtp: builder.query<any, string>({
      query: (id) => {
        return {
          url: `/user/${id}/emailVerificationOtp`,
          method: "GET",
        };
      },
    }),
    verifyFbEmailOtp: builder.mutation<UserUpdate, ID>({
      query: (body) => {
        const { userId, ...rest } = body;
        return {
          url: `/user/${userId}/verifyEmail`,
          method: "PATCH",
          body: rest,
        };
      },
    }),
    verifyOtp: builder.mutation<EmailOtp, EmailOtp>({
      query: (body) => {
        return {
          url: "/user/verify",
          method: "POST",
          body,
        };
      },
    }),
    updateUser: builder.mutation<UserUpdate, UserUpdate>({
      query: (body) => {
        const { userId, ...rest } = body;
        return {
          url: `/user`,
          method: "PUT",
          body: rest,
        };
      },
    }),
    getProfile: builder.query<ProfileResponse, null>({
      query: (body) => `/user/profile`,
    }),
    updateProfile: builder.mutation<ResponseData, UpdateProfileBody>({
      query: (body) => {
        const { userId, ...rest } = body;
        return {
          url: `/user/update-profile`,
          method: "PUT",
          body: rest,
        };
      },
    }),
    updateDocuments: builder.mutation<UpdateDocument, UpdateDocument>({
      query: (body) => {
        return {
          url: `/user/itentity-verification`,
          method: "PUT",
          body,
        };
      },
    }),
    getQuestions: builder.query<Questions, void>({
      query: () => "/questions/list",
      // providesTags:["Questions"]
    }),
    submitQuestion: builder.mutation<SubmitQuestion, QuestionReq>({
      query: (body) => {
        const { userId, option } = body;
        return {
          url: `/user/question`,
          method: "PUT",
          body: { questions: option },
        };
      },
    }),
    loginByFacebook: builder.mutation<
      ApiResponse<{ user: User; token: string; refreshToken: string }>,
      { token: string; affiliateCode?: string }
    >({
      query: (body) => {
        return {
          url: "/user/auth/facebook",
          method: "POST",
          body: body,
        };
      },
    }),
    loginByGoogle: builder.mutation<
      ApiResponse<{ user: User; token: string; refreshToken: string }>,
      { access_token: string; affiliateCode: string }
    >({
      query: (body) => {
        return {
          url: "/user/auth/google",
          method: "POST",
          body: body,
        };
      },
    }),
    skipOnBoarding: builder.mutation<SkipOnBoardingResponse, SKIP>({
      query: (body) => {
        const { id, isOnboardingSkipped } = body;
        return {
          url: `/user/skipOnboarding`,
          method: "PATCH",
          body: { isOnboardingSkipped },
        };
      },
    }),
    getSumsubToken: builder.query<Tokens, LevelName>({
      query: (levelName) => `/user/sumsub-token?levelName=${levelName}`,
    }),
    getKYC: builder.query<KYC_STAT, string | null | undefined>({
      query: (body) => {
        return {
          url: "/user/sumsub-verification-status",
          method: "POST",
          body: { applicantId: body },
        };
      },
    }),
    updateSkipDashboard: builder.mutation<ResponseData, UpdateDashboardBody>({
      query: (body) => {
        const { userId, ...rest } = body;
        return {
          url: `/user`,
          method: "PUT",
          body: rest,
        };
      },
    }),
    contactManager: builder.mutation<ResponseData, ContactManager>({
      query: (body) => {
        return {
          url: `/affiliate/question`,
          method: "POST",
          body,
        };
      },
    }),
    getAffiliateOverview: builder.query<OverviewRes, string | undefined>({
      query: (id) => `/affiliate/overview`,
    }),
    getAffiliateStatistics: builder.query<
      AffiliateStatsRes,
      { id: string | undefined; sortValue: string }
    >({
      query: ({ id, sortValue }) => `/affiliate/statistics?filter=${sortValue}`,
    }),
    getInvitees: builder.query<InviteesRes, InviteesRequest>({
      query: ({ id, page, limit, sortValue, filter }) =>
        `/affiliate/invitees?page=${page}&pageLimit=${limit}&sorting=${sortValue}&filter=${filter}`,
    }),
    getAffiliateActivity: builder.query<AffiliateActivityResponse, void>({
      query: () => `/affiliate/activities`,
    }),
    affiliateActivityInvite: builder.mutation<InviteResponse, InviteRequest>({
      query: (body) => {
        return {
          url: `/affiliate/invitees`,
          method: "POST",
          body,
        };
      },
    }),
    affiliateApply: builder.mutation<AffiliateApplyResponse, AffiliateRequest>({
      query: (body) => {
        // const { userId, ...rest } = body;
        return {
          url: `/user/affiliate-apply`, // `/affiliate/apply`,
          method: "POST",
          body,
        };
      },
    }),
    changePassword: builder.mutation<PasswordChangeRes, PasswordChange>({
      query: (body) => {
        const { userId, ...rest } = body;
        return {
          url: `/admin/change-password/${userId}`,
          method: "PUT",
          body: rest,
        };
      },
    }),
    uploadProfilePicture: builder.mutation<PasswordChangeRes, UploadPhoto>({
      query: (body) => {
        const { userId, formData } = body;
        return {
          url: `/user/upload`,
          method: "PUT",
          body: formData,
        };
      },
    }),
    deleteProfilePicture: builder.mutation<
      PasswordChangeRes,
      { userId: string | undefined }
    >({
      query: ({ userId }) => {
        return {
          url: `/user/removeProfilePic`,
          method: "DELETE",
        };
      },
    }),
    assignSubAffiliate: builder.mutation<PasswordChangeRes, SubAffiliateReq>({
      query: (body) => {
        const { userId, ...rest } = body;
        return {
          url: `/affiliate/subaffiliate/${userId}`,
          method: "PATCH",
          body: rest,
        };
      },
    }),
    blockUser: builder.mutation<PasswordChangeRes, SubAffiliateReq>({
      query: (body) => {
        const { userId, ...rest } = body;
        return {
          url: `/affiliate/invitees/${userId}`,
          method: "PATCH",
          body: rest,
        };
      },
    }),
    campaignLink: builder.mutation<PasswordChangeRes, CampaingLink>({
      query: (body) => {
        // const { userId, ...rest } = body;
        return {
          url: `/affiliate/code`,
          method: "POST",
          body,
        };
      },
    }),
    verifyAffiliateCode: builder.mutation<
      VerifyCodeResponse,
      { affiliateCode: string }
    >({
      query: (body) => {
        const { affiliateCode } = body;
        return {
          url: `/affiliate/code-check`,
          method: "POST",
          body: { affiliateCode },
        };
      },
    }),
    getAffiliateCode: builder.query<InviteesRes, AffiliateCodeRequest>({
      query: ({ id, page, limit, sortValue }) =>
        `/affiliate/code?page=${page}&pageLimit=${limit}&filter=${sortValue}`,
    }),
    editComissionRate: builder.mutation<
      EditComissionRes,
      { id: string | undefined; commissionRate: string | undefined }
    >({
      query: ({ id, ...rest }) => {
        return {
          url: `/affiliate/standard-link/${id}`,
          method: "PUT",
          body: rest,
        };
      },
    }),
    closeUserAccount: builder.mutation<
      EditComissionRes,
      { id: string | undefined; requestType: string }
    >({
      query: ({ id, ...rest }) => {
        return {
          url: `/user/close`,
          method: "DELETE",
          body: rest,
        };
      },
    }),
    updateApplicantId: builder.mutation<UserUpdate, UserUpdate>({
      query: (body) => {
        const { userId, ...rest } = body;
        return {
          url: `/user/update-applicant`,
          method: "PUT",
          body: rest,
        };
      },
    }),
    enableDisable2fa: builder.mutation<UserUpdate, UserUpdate>({
      query: (body) => {
        const { userId, ...rest } = body;
        return {
          url: `/user/2fa-auth`,
          method: "PUT",
          body: rest,
        };
      },
    }),
    otpVerify2fa: builder.mutation<UserUpdate, Otp2FA>({
      query: (body) => {
        // const { userId, ...rest } = body;
        return {
          // url: `/user/2fa/${userId}`,
          url: `/user/2fa`,
          method: "POST",
          body: body,
        };
      },
    }),
    inactiveSumSubDocument: builder.mutation<null, { imageIds: number[] }>({
      query: (body) => {
        return {
          url: "/user/sumsub-inactive",
          method: "DELETE",
          body,
        };
      },
    }),
    getSumSubDocuments: builder.query<SumSubDocumentApiResponse, LevelName>({
      query: (levelName) => `/user/sumsub-documents?levelName=${levelName}`,
    }),
    getSumSubApplicant: builder.query<SumSubDocumentApiResponse, string>({
      query: (userId) => `/user/sumsub-get-data`,
    }),
    checkValidCode: builder.mutation<UserUpdate, CheckAffiliateCode>({
      query: (body) => {
        return {
          url: `/user/check-code`,
          method: "POST",
          body: body,
        };
      },
    }),
    getAuthToken: builder.mutation<
      { token: string; refreshToken: string },
      string
    >({
      query: (refreshToken) => {
        return {
          url: `/user/get-refresh-token`,
          method: "POST",
          body: { refreshToken },
        };
      },
    }),
    disable2FA: builder.mutation<apiResponse, Disable2FA>({
      query: (body) => {
        return {
          url: "/admin/disable2fa-email",
          method: "POST",
          body,
        };
      },
    }),
    getNews: builder.query<NewsResponse | undefined, void>({
      query: (userId) => `/admin/news`,
    }),
    loginByApple: builder.mutation<
      ApiResponse<{ user: User; token: string; refreshToken: string }>,
      { id_token: string; affiliateCode: string }
    >({
      query: (body) => {
        return {
          url: "/user/auth/apple",
          method: "POST",
          body,
        };
      },
    }),
    kycStatusUpdate: builder.mutation<null, null>({
      query: () => {
        return {
          url: "/user/update-kyc-status",
          method: "PATCH",
        };
      },
    }),
    getAllNotifications: builder.query<NotificationRes, NotificationRequest>({
      query: ({ limit, page, search = "" }) =>
        `/user/notification?pageLimit=${limit}&page=${page}&search=${search}`,
    }),
    getDepositAddress: builder.query<ApiResponse<DepositAddress>, void>({
      query: () => `/user/deposit-address`,
    }),
    getTransactions: builder.query<
      ApiResponse<{ transactions: Transaction[]; count: number }>,
      {
        page?: string;
        limit?: string;
        filter?: string;
        type?: Transaction["type"];
      }
    >({
      query: (params) => {
        const urlSearchParams = new URLSearchParams(params).toString();
        return `/user/transactions?${urlSearchParams}`;
      },
    }),
    getTokens: builder.query<ApiResponse<AssetToken[]>, { filter?: string }>({
      query: (params) => {
        const urlSearchParams = new URLSearchParams(params).toString();
        return `/user/tokens?${urlSearchParams}`;
      },
    }),
    getAccountConfigs: builder.query<
      ApiResponse<AccountConfig>,
      { symbol: string }
    >({
      query: ({ symbol }) => {
        return `/user/account-configs?symbol=${symbol}`;
      },
    }),
    readNotification: builder.mutation<NotificationRes, ReadNotification>({
      query: (ids) => ({
        url: `/user/notification`,
        method: "PATCH",
        body: ids,
      }),
    }),
    getBalance: builder.query<ApiResponse<BCBalance>, void>({
      query: () => `/blockchain/balance`,
    }),
    swapTokens: builder.mutation<
      ApiResponse<{}>,
      { value: string; to: string; from: string }
    >({
      query: (body) => ({
        url: `/blockchain/swap`,
        method: "POST",
        body,
      }),
    }),
    swapTokensInfo: builder.mutation<
      ApiResponse<{ networkFees: number; tokenValue: number }>,
      { value: number; to: string; from: string }
    >({
      query: (body) => ({
        url: `/blockchain/swap-info`,
        method: "POST",
        body,
      }),
    }),
    withdrawalToken: builder.mutation<
      ApiResponse<{}>,
      { amount: number; token: string; address: string }
    >({
      query: (body) => ({
        url: `/blockchain/withdrawal`,
        method: "POST",
        body,
      }),
    }),
    requestAccountRestore: builder.mutation<
      EditComissionRes,
      { email: string }
    >({
      query: (body) => {
        return {
          url: `/user/restore-request`,
          method: "POST",
          body,
        };
      },
    }),
    getSellOrders: builder.query<
      ApiResponse<{ orders: SellOrder[]; count: number }>,
      {
        page?: string;
        limit?: string;
        filter?: string;
        status?: SellOrder["status"];
      }
    >({
      query: (params) => {
        const urlSearchParams = new URLSearchParams(params).toString();
        return `/blockchain/sell?${urlSearchParams}`;
      },
    }),
    getBuyOrders: builder.query<
      ApiResponse<{ buyOrdersData: BuyOrder[]; count: number }>,
      {
        page?: string;
        limit?: string;
        filter?: string;
        status?: BuyOrder["status"];
      }
    >({
      query: (params) => {
        const urlSearchParams = new URLSearchParams(params).toString();
        return `/blockchain/buy?${urlSearchParams}`;
      },
    }),
    sellTokens: builder.mutation<
      ApiResponse<{}>,
      { usdt: number; usdc: number; dai: number }
    >({
      query: (body) => ({
        url: `/blockchain/sell`,
        method: "POST",
        body,
      }),
    }),
    getRandomToken: builder.query<
      ApiResponse<{ token: string; address: string }>,
      {}
    >({
      query: () => {
        return `/blockchain/token-to-buy`;
      },
    }),
    getDashboardChart: builder.query<
      ApiResponse<DashboardChatData[]>,
      { filter?: string }
    >({
      query: (params) => {
        const urlSearchParams = new URLSearchParams(params).toString();
        return `/user/dashboard-chart?${
          urlSearchParams ? urlSearchParams : "filter=all"
        }`;
      },
    }),
  }),
});
export const {
  useEmailLoginMutation,
  useEmailSingupMutation,
  useVerifyOtpMutation,
  useUpdateUserMutation,
  useGetProfileQuery,
  useUpdateDocumentsMutation,
  useGetQuestionsQuery,
  useSubmitQuestionMutation,
  useLoginByFacebookMutation,
  useGetEmailOtpQuery,
  useVerifyFbEmailOtpMutation,
  useSkipOnBoardingMutation,
  useGetSumsubTokenQuery,
  useGetKYCQuery,
  useUpdateProfileMutation,
  useUpdateSkipDashboardMutation,
  useContactManagerMutation,
  useGetAffiliateOverviewQuery,
  useGetAffiliateStatisticsQuery,
  useGetInviteesQuery,
  useGetAffiliateActivityQuery,
  useAffiliateActivityInviteMutation,
  useAffiliateApplyMutation,
  useChangePasswordMutation,
  useUploadProfilePictureMutation,
  useDeleteProfilePictureMutation,
  useAssignSubAffiliateMutation,
  useBlockUserMutation,
  useCampaignLinkMutation,
  useVerifyAffiliateCodeMutation,
  useGetAffiliateCodeQuery,
  useEditComissionRateMutation,
  useCloseUserAccountMutation,
  useUpdateApplicantIdMutation,
  useEnableDisable2faMutation,
  useOtpVerify2faMutation,
  useInactiveSumSubDocumentMutation,
  useGetSumSubDocumentsQuery,
  useCheckValidCodeMutation,
  useGetSumSubApplicantQuery,
  useDisable2FAMutation,
  useGetNewsQuery,
  useLoginByAppleMutation,
  useKycStatusUpdateMutation,
  useLoginByGoogleMutation,
  useGetAllNotificationsQuery,
  useGetDepositAddressQuery,
  useReadNotificationMutation,
  useLazyGetTokensQuery,
  useLazyGetTransactionsQuery,
  useGetAccountConfigsQuery,
  useGetBalanceQuery,
  useSwapTokensMutation,
  useSwapTokensInfoMutation,
  useWithdrawalTokenMutation,
  useRequestAccountRestoreMutation,
  useLazyGetSellOrdersQuery,
  useLazyGetBuyOrdersQuery,
  useSellTokensMutation,
  useGetRandomTokenQuery,
  useLazyGetDashboardChartQuery,
} = api;
