import clsx from "clsx";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackOrange } from "../../assets";
import CustomButton from "../../components/CustomButton";
import Tab from "../../components/Tab";
import CustomModal from "../common/CustomModal";
import LearnMore from "../LearnMore";
import style from "./index.module.scss";

interface Props {
  item: {
    name: string;
    value: string;
  };
}
const tabs = ["Borrow", "Repay"];
const data = [
  { name: "Loan amount", value: "1230 $NEAL" },
  { name: "Total interest", value: "120 $NEAL" },
  { name: "You will receive(including fees)", value: "11230 $NEAL" },
  { name: "Daily interest rate", value: "2.4%" },
  { name: "Loan term", value: "6 month" },
  { name: "Repay before", value: "April 4, 2023  10:30" },
  { name: "Total repayable amount", value: "1246 $NEAL" },
];
const RepayPage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [confirmTransaction, setConfirmTransaction] = useState(false);
  const handleTab = (index: number) => setTabIndex(index);

  const transactionPopup = () => {
    setConfirmTransaction((confirmTransaction) => !confirmTransaction);
  };
  const navigate = useNavigate();
  return (
    <>
      <div className={style.back} onClick={() => navigate(-1)}>
        <BackOrange />
        <p>Back</p>
      </div>
      <div className={style.stakContainer}>
        <div className={style.stakMainDiv}>
          <div className={style.cardWrapper}>
            <h2>$NFTS TOKEN</h2>
            <div className={style.buttons}>
              {tabs.map((tab, i) => (
                <Tab
                  key={tab}
                  tab={tab}
                  index={i}
                  tabIndex={tabIndex}
                  handleTab={handleTab}
                />
              ))}
            </div>
            <h5 className={style.claim}>Repay</h5>
            <h5 className={style.claim}>Total to repay: 1246 $NEAL</h5>
            <div className={style.title}>
              <p>Title</p>
              <span>Available amount: 1238 $NEAL</span>
            </div>
            <div className={style.titleInput}>
              <input />
              <div className={style.max}>
                <h4>Max</h4>
              </div>
            </div>
            <div className={style.titleInputMobContainer}>
              <div className={style.titleInputMob}>
                <div className={style.titleInputMobTop}>
                  <p>Amount</p>
                  <span>Available: 1238 $NEAL</span>
                </div>
                <div className={style.inputContainerMob}>
                  <input type={"number"} />
                  <div className={style.max}>
                    <h4>Max</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.btnContainer}>
              <CustomButton variant="outlined">Cancel</CustomButton>
              <CustomButton onClick={transactionPopup}>Confirm</CustomButton>
            </div>
            <CustomModal isOpen={confirmTransaction} toggle={transactionPopup}>
              <LearnMore
                className={style.comfirmContent}
                title={"Transaction confirmed!"}
                buttonContent={"Got it!"}
                contnet={
                  " You successfully repaid your loan in full. Way to go!"
                }
              />
            </CustomModal>

            <div className={style.tokenDetail}>
              {data.map((item, i) => (
                <TokenDetail item={item} key={i} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const TokenDetail = ({ item }: Props) => {
  return (
    <div className={style.item}>
      <span>{item.name}</span>
      <p
        className={clsx(
          item.name === "Total repayable amount" && style.colorChange
        )}
      >
        {item.value}
      </p>
    </div>
  );
};
export default RepayPage;
