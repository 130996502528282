import clsx from "clsx";
import { FieldError } from "react-hook-form";
import styles from "./index.module.scss";

export type DATA = {
  id: number | string;
  value?: string | undefined;
};
interface Props {
  label?: string;
  id: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  data: DATA[] | undefined;
  placeholder: string;
  error?: FieldError | undefined;
  value?: string;
}
const SelectBox = (props: Props) => {
  const { label, data, value, onChange, error, placeholder } = props;
  return (
    <div className={styles.inputContainer}>
      <div className={styles.labelError}>
        <label htmlFor={props.id}>{label}</label>
        <div className={styles.error}>{error?.message}</div>
      </div>
      <select
        value={value}
        onChange={onChange}
        className={clsx(
          styles.selectBox,
          styles.decorated,
          error ? styles.borderError : styles.borderNormal
        )}
        id={props.id}
      >
        <option className={styles.labelSelected} value="" disabled selected>
          {placeholder}
        </option>
        {data?.map((item) => (
          <option value={item.id} key={item.id}>
            {item.value}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectBox;
