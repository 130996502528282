import clsx from "clsx";
import styles from "./index.module.scss";
interface Props {
  tab: string;
  index: number;
  tabIndex: number;
  handleTab: (index: number) => void;
}
const Tab = (props: Props) => {
  const { tab, tabIndex, index, handleTab } = props;
  return (
    <div
      className={clsx(styles.tab, index === tabIndex && styles.active)}
      onClick={() => handleTab(index)}
    >
      <p>{tab}</p>
    </div>
  );
};

export default Tab;
