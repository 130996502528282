import clsx from "clsx";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Setting } from "../../assets/icons/gear.svg";
import CustomButton from "../CustomButton";
import EditCancelPopup from "../EditCacelPopup";
import Input from "../Input";
import LearnMore from "../LearnMore";
import EditSavingPlan from "../SavingPlans/EditSavingPlan";
import CustomModal from "../common/CustomModal";
import style from "./index.module.scss";

type props = {
  title?: string;
  startIcon?: React.ReactNode;
  lastIcon?: React.ReactNode;
  content?: string;
  average?: any;
  duration?: string;
  flexible?: string;
  value?: any | number;
  button?: string;
  plusicon?: React.ReactElement;
  className?: string;
  nealthToken?: string;
  onClick?: () => void;
};
export default function SavingPlanBox(props: props) {
  const {
    title,
    startIcon,
    lastIcon,
    content,
    average,
    duration,
    flexible,
    value,
    button,
    plusicon,
    className,
    nealthToken,
    onClick,
  } = props;

  const [subscribe, setSubscribe] = useState(false);
  const Subscribe = () => {
    setSubscribe(true);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggle = () => {
    setIsModalOpen((isModalOpen) => !isModalOpen);
  };

  const [edutPlan, setEditPlan] = useState(false);
  const openEditpopup = () => {
    setEditPlan((edutPlan) => !edutPlan);
  };

  const [cancelPlan, setCancelPlan] = useState(false);
  const subscriptionCanceled = () => {
    setCancelPlan((cancelPlan) => !cancelPlan);
  };

  const [updatePlan, setUpdarePlan] = useState(false);
  const updateSuccessfully = () => {
    setUpdarePlan((updatePlan) => !updatePlan);
  };

  const cancelPopup = () => {
    setIsModalOpen(false);
    setCancelPlan(false);
    setEditPlan(false);
  };

  return (
    <div className={clsx(style.SavingPlan, className)}>
      <h3 className={style.title}>{title}</h3>
      <Link className={style.link} to="/">
        {nealthToken}
      </Link>
      <div className={style.boxImg}>
        <span className={style.startIcon}>{startIcon}</span>
        <span className={clsx(style.plusicon, className)}>{plusicon}</span>
        <span className={style.lastIcon}>{lastIcon} </span>
      </div>
      <p className={style.content}>{content}</p>
      <div className={style.boxDetials}>
        <div className={style.boxItems}>
          <p>{average}</p>
          <strong>{value}</strong>
        </div>
        <div className={style.divide} />
        <div className={clsx(style.boxItemsec, style.boxItems)}>
          <p>{duration}</p>
          <strong>{flexible}</strong>
        </div>
      </div>
      {subscribe != true ? (
        <CustomButton className={style.savingBoxbutton} onClick={Subscribe}>
          {button}
        </CustomButton>
      ) : (
        <div className={style.editCancelPlan}>
          <div className={style.editplan}>
            <CustomButton
              variant={"outlined"}
              className={style.savingBoxbutton}
              onClick={openEditpopup}
            >
              Edit my Setup
            </CustomButton>
          </div>
          <div className={style.cancelPlan}>
            <CustomButton
              variant={"outlined"}
              className={style.savingBoxbutton}
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Cancel Plan
            </CustomButton>
          </div>
        </div>
      )}
      <CustomModal isOpen={isModalOpen} toggle={toggle}>
        <EditCancelPopup
          title={"Do you want to cancel your setup?"}
          updateBtn={"Yes, Cancel Plan"}
          cancelBtn={"No, don’t cancel"}
          cancelonClick={cancelPopup}
          updateonClick={() => {
            cancelPopup();
            subscriptionCanceled();
            setSubscribe(false)
          }}
        >
          <p className={style.popupText}>
            If you answer yes, the setup you created will be deleted and you
            will have to set it up again.
          </p>
        </EditCancelPopup>
      </CustomModal>
      <CustomModal isOpen={cancelPlan} toggle={subscriptionCanceled}>
        <LearnMore
          className={style.editPopup}
          title={"Your Setup was canceled!"}
          icon={<Setting />}
          buttonContent={"Ok, Thanks!"}
          onClick={() => {
            cancelPopup();
          }}
          contnet={
            "You successfully canceled the selected setup. If you want to use Auto-Invest, you can simply create a new Setup!"
          }
        />
      </CustomModal>

      <CustomModal isOpen={edutPlan} toggle={openEditpopup}>
        <EditSavingPlan
          cancelonClick={() => {
            cancelPopup();
          }}
          saveonClick={() => {
            setEditPlan(false);
            updateSuccessfully();
          }}
          cancel={"Cancel"}
          saveChange={"Save Changes"}
          title="Edit Auto-Invest setup"
          inputAmounttext={
            <Input
              label={"Amount to invest (monthly)"}
              type={"text"}
              id={"title"}
              placeholder={"100$"}
              name={"title"}
            />
          }
          investmentext="Investment timeline"
          inputDuration={
            <Input
              label={"Duration of the Auto-Invest"}
              type={"text"}
              id={"month"}
              placeholder={"4 month"}
              name={"month"}
            />
          }
        />
      </CustomModal>
      <CustomModal isOpen={updatePlan} toggle={updateSuccessfully}>
        <LearnMore
          className={style.editPopup}
          title={"Your setup was edited!"}
          icon={<Setting />}
          buttonContent={"Ok, Thanks!"}
          onClick={() => {
            setUpdarePlan(false);
          }}
          contnet={"You successfully edited your Auto-Invest setup!"}
        />
      </CustomModal>
    </div>
  );
}
