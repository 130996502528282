import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import style from "./index.module.scss";
type Props = {
  icon?: React.ReactElement;
  text?: string;
  visit?: string;
  classname?: string;
  points?: any;
};
export default function RatingCard(Props: Props) {
  const { icon, text, visit, points, classname } = Props;
  return (
    <div className={clsx(style.ratingcard, classname)}>
      <div className={style.icon}>{icon}</div>
      <div className={style.iconText}>
        <div className={style.text}>
          <p>{text}</p>
          <span>{visit}</span>
        </div>
        <div className={style.points}>
          <span>{visit}</span>
          <Link to="">{points}</Link>
        </div>
      </div>
    </div>
  );
}
