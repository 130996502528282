import { TestToken, MonkeyColored, Reward } from "../../../assets";
import CustomButton from "../../CustomButton";
import styles from "./index.module.scss";
interface LOAN {
  loan: {
    id: number;
    asset: string;
    repay: string;
    repayBefore: string;
  };
}
const LoanComponent = ({ loan }: LOAN) => {
  return (
    <div className={styles.stakContainer}>
      <div className={styles.loanHead}>
        <MonkeyColored />
        <div>
          <h5>NFT id</h5>
          <p>{loan.asset}</p>
        </div>
      </div>
      <p>{loan.repay}$NEAL</p>
      <h5>{loan.repayBefore}</h5>
      <div className={styles.claim}>
        <CustomButton variant="outlined" className={styles.claimBtn}>
          Repay
        </CustomButton>
      </div>
    </div>
  );
};

export default LoanComponent;
