import { WarningIcon2 } from "../../../assets";
import styles from "./index.module.scss";

type Props = {
  type: string;
};

const AboutDeposit = (props: Props) => {
  const { type } = props;
  const renderComponent = (t: string) => {
    if (t === "deposit") {
      return (
        <>
          <div className={styles.aboutTop}>
            <WarningIcon2 />
            <h4>About Deposits</h4>
          </div>
          <p className={styles.caption}>
            Only ETH and supported ERC-20 tokens can be deposited to this
            address. Any other deposits will be lost.{" "}
          </p>
        </>
      );
    } else {
      return (
        <>
          <div className={styles.aboutTop}>
            <WarningIcon2 />
            <h4>About Withdrawal</h4>
          </div>
          <p className={styles.caption}>
            Only ETH and supported ERC-20 tokens can be withdrawn to this
            address. Any other withdrawal will be lost.{" "}
          </p>
        </>
      );
    }
  };
  return <div className={styles.about}>{renderComponent(type)}</div>;
};

export default AboutDeposit;
