import React from "react";
const useWindowResize = () => {
  const [dimension, setWindowDimension] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  React.useEffect(() => {
    const handleWindowResize = () =>
      setWindowDimension({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  return dimension;
};
export default useWindowResize;
