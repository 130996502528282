import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import { AddIcon, Minus } from "../../assets/index";
import useWindowResize from "../../hooks/useWindowResize";
import FlexBox from "../common/FlexBox";
import CustomButton from "../CustomButton";
import Dashboardtitle from "../DashbordTitle";
import DataTables from "../DataTables";
import { CustomExpandIcon } from "../SavingPlans/MyInvestPlan";
import StakingCard from "../StakingCard";
import NFTSToken from "../stakingNftsToken";
import Title from "../Title";
import style from "./index.module.scss";

export const CustomExpandIconInner = () => {
  const [visible, setVisible] = useState(true);
  return (
    <>
      {visible == true ? (
        <div
          onClick={() => {
            setVisible(false);
          }}
          className={style.innderIconAccord}
        >
          <img src={AddIcon} alt="AddIcon" />
        </div>
      ) : (
        <div
          onClick={() => {
            setVisible(true);
          }}
          className={style.innderIconAccord}
        >
          <Minus />
        </div>
      )}
    </>
  );
};
type TABLE = {
  id: number;
  tAmount: {
    value: string;
    actual: number;
  };
  texpiry: string;
  treward: {
    value: string;
    actual: number;
  };
  tClaimDate: string;
};
type Child = {
  id: number;
  stakedAmount: {
    value: string;
    actual: string;
  };
  totalReward: {
    value: string;
    actual: string;
  };
  availReward: {
    value: string;
    actual: string;
  };
  period: string;
  duration: string;
  // tableData: TABLE[]
};
type DATA = {
  id: number;
  name: string;
  buttonText: string;
  amount: {
    value: string;
    actual: string;
  };
  apr: number;
  reward: number;
  btnText: string;
  child: Child[];
};
const data: DATA[] = [
  {
    id: 1,
    buttonText: "Stake",
    name: "$NFTS TOKEN",
    amount: { value: "123123.3123 $NFTS", actual: "$3123.2" },
    apr: 6.7,
    reward: 0,
    btnText: "stake",
    child: [
      {
        id: 11,
        stakedAmount: {
          value: "124.472 $NEAL",
          actual: "$1312421.2",
        },
        totalReward: {
          value: "124.472 $NEAL",
          actual: "$1312421.2",
        },
        availReward: {
          value: "1321.472 $NEAL",
          actual: "$232.2",
        },
        period: "6months",
        duration: "23d:4h:22m",
        // tableData: [
        //   {
        //     id: 110,
        //     tAmount: {
        //       value: "100 $NEAL",
        //       actual: "$54"
        //     },
        //     texpiry: "02.02.2023",
        //     treward: {
        //       value: "100 $NEAL",
        //       actual: "$54"
        //     },
        //     tClaimDate: "02.02.2023",
        //   },
        //   {
        //     id: 110,
        //     tAmount: {
        //       value: "100 $NEAL",
        //       actual: "$54"
        //     },
        //     texpiry: "02.02.2023",
        //     treward: {
        //       value: "100 $NEAL",
        //       actual: "$54"
        //     },
        //     tClaimDate: "02.02.2023",
        //   },
        // ]
      },
      {
        id: 12,
        stakedAmount: { value: "124.472 $NEAL", actual: "$1312421.2" },
        totalReward: { value: "124.472 $NEAL", actual: "$1312421.2" },
        availReward: { value: "1321.472 $NEAL", actual: "$232.2" },
        period: "6months",
        duration: "23d:4h:22m",
      },
    ],
  },
  {
    id: 2,
    buttonText: "Stake",
    name: "$CRY10 TOKEN",
    amount: { value: "123123.3123 $NFTS", actual: "$3123.2" },
    apr: 6.7,
    reward: 0,
    btnText: "stake",
    child: [
      {
        id: 101,
        stakedAmount: { value: "12 $NEAL", actual: "$4.2" },
        totalReward: { value: "234 $NEAL", actual: "$33.2" },
        availReward: { value: "25 $NEAL", actual: "$32.2" },
        period: "16months",
        duration: "23d:4h:22m",
      },
    ],
  },
];
type Columns = {
  name: string;
  selector: (row: any) => string;
  sortable: boolean;
};
const btn = [
  { title: "All time" },
  { title: "Last month" },
  { title: "Last week" },
];
export default function Staking() {
  const [accordianIndex, setAccordianIndex] = useState<string | false>(
    "panel1"
  );
  const [selectedBtn, setSelectedBtn] = useState<string>("All time");
  const dummyData = [
    {
      id: 1,
      stakAmount: "1100 $NEAL $98.00",
      expDate: "02.05.2023",
      rewardAmount: "120$NEAL $114.00",
      claimable: "22.02.2023",
    },
    {
      id: 2,
      stakAmount: "400 $NEAL $98.00",
      expDate: "02.01.2023",
      rewardAmount: "12012$NEAL $114.00",
      claimable: "22.02.2023",
    },
    {
      id: 3,
      stakAmount: "600 $NEAL $98.00",
      expDate: "02.08.2023",
      rewardAmount: "12120$NEAL $114.00",
      claimable: "22.02.2023",
    },
    {
      id: 4,
      stakAmount: "1200 $NEAL $98.00",
      expDate: "02.04.2023",
      rewardAmount: "320$NEAL $114.00",
      claimable: "22.02.2023",
    },
    {
      id: 5,
      stakAmount: "234324 $NEAL $98.00",
      expDate: "02.04.2024",
      rewardAmount: "32234$NEAL $1124.00",
      claimable: "22.02.2025",
    },
    {
      id: 6,
      stakAmount: "12345 $NEAL $98.00",
      expDate: "02.04.2223",
      rewardAmount: "523$NEAL $114.00",
      claimable: "22.02.3023",
    },
    {
      id: 7,
      stakAmount: "564232 $NEAL $98.00",
      expDate: "02.04.2343",
      rewardAmount: "63423$NEAL $114.00",
      claimable: "22.12.2023",
    },
  ];
  const columns: Columns[] = [
    {
      name: "Staked amount",
      selector: (row) => row.stakAmount,
      sortable: true,
    },
    {
      name: "Lock-up expiration date",
      selector: (row) => row.expDate,
      sortable: true,
    },
    {
      name: "Rewards amount",
      selector: (row) => row.rewardAmount,
      sortable: true,
    },
    {
      name: "Claimed date",
      selector: (row) => row.claimable,
      sortable: true,
    },
  ];

  const { width } = useWindowResize();
  const breackpoint = 575;

  const onChangeAccordian = (val: string) => {
    setAccordianIndex((prev) => (prev === val ? false : val));
  };
  const selectFilterHandler = (val: string) => {
    setSelectedBtn(val);
  };
  return (
    <div className="accordionSection">
      <FlexBox className={style.StakingCard}>
        <FlexBox width="100%" className={style.availableBalance}>
          <StakingCard
            className={style.balanceAdd}
            text={"Available balance"}
            values="1708.3$"
          />
          <CustomButton variant="outlined" className={style.addButton}>
            Add
          </CustomButton>
        </FlexBox>
        <StakingCard
          className={style.tokenCard}
          text={width > breackpoint ? "Available for claim" : "For Claim"}
          values={0.0}
        />
        <StakingCard
          className={style.tokenCard}
          text={"My Tokens"}
          values={2}
        />
        <StakingCard
          className={style.tokenCard}
          text={width > breackpoint ? "Total staked Tokens" : "Staked Tokens"}
          values={1}
        />
      </FlexBox>
      <Dashboardtitle title={"Staking assets "} description={""} />
      {/* <Accordion items={accordionItems} /> */}
      {/* <NFTSToken className="" />
      <div className={style.cryptoToken}>
        <NFTSToken className={style.nftCrypto} />
      </div> */}

      {data.map((item, i) => (
        <Accordion
          className={style.customAccordion}
          expanded={accordianIndex === `panel${i + 1}`}
        >
          <AccordionSummary
            sx={{
              paddingRight: "20px",
              border: "0",
              flexWrap: "wrap",
              ".css-o4b71y-MuiAccordionSummary-content": {
                flexWrap: "wrap",
                width: "80%",
              },
            }}
            expandIcon={<CustomExpandIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={style.token}
            onClick={() => onChangeAccordian(`panel${i + 1}`)}
          >
            <NFTSToken
              className={style.nftCrypto}
              buttonText={item.buttonText}
            />
          </AccordionSummary>
          <AccordionDetails className={style.content}>
            <hr />
            <Title title="Staking overview" className={style.innerTableTitle} />
            <div style={{ width: "100%" }}>
              <div>
                {item?.child?.map((inner) => (
                  <Accordion
                    className={style.customAccordionChild}
                    onChange={(e) => e.stopPropagation()}
                  >
                    <AccordionSummary
                      sx={{
                        padding: "0",
                        border: "0",
                        flexWrap: "wrap",
                        ".css-o4b71y-MuiAccordionSummary-content": {
                          flexWrap: "wrap",
                          width: "80%",
                        },
                      }}
                      expandIcon={<CustomExpandIconInner />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className={style.token}
                    >
                      <div className={style.tableRow}>
                        <div className={style.td}>
                          {inner.stakedAmount.value}
                          {"  "}
                          <span>{inner.stakedAmount.actual}</span>
                        </div>
                        <div className={style.td}>
                          {inner.totalReward.value}
                          {"  "}
                          <span>{inner.totalReward.actual}</span>
                        </div>
                        <div className={style.td}>
                          {inner.availReward.value}
                          {"  "}
                          <span>{inner.availReward.actual}</span>
                        </div>
                        <div className={style.td}>
                          {inner.period} <span>{inner.duration}</span>
                        </div>
                        <div
                          className={style.btnContainer}
                          style={{ paddingRight: 20 }}
                        >
                          <div className={style.btn}>Claim</div>
                          <div className={style.btn}>Unstake</div>
                        </div>
                      </div>
                      {/* <NFTSToken className={style.nftCrypto} /> */}
                      {/* <div>{inner.id}</div> */}
                    </AccordionSummary>
                    <AccordionDetails className={style.innerContent}>
                      <Title
                        title="Reward history"
                        className={style.innerTableTitle}
                      />
                      <div className={style.btnContainer}>
                        {btn.map((item) => (
                          <div
                            key={item.title}
                            className={clsx(
                              style.btn,
                              selectedBtn === item.title ? style.active : ""
                            )}
                            onClick={() => selectFilterHandler(item.title)}
                          >
                            {item.title}
                          </div>
                        ))}
                        {/* <div className={style.btn}>Last month</div>
                        <div className={style.btn}>Last week</div> */}
                      </div>
                      <DataTables
                        columns={columns}
                        data={dummyData}
                        className={style.stakingTable}
                      />
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
