import clsx from "clsx";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  binance,
  Chain,
  Currencyfive,
  Currencyfour,
  currencyone,
  Currencysix,
  Currencythree,
  Down,
  Exchange,
  tokenhead,
  Tokenhead2,
  tokenhead3,
} from "../../../assets/index";
import BackButton from "../../BackBtn";
import ChooseToken from "../../ChooseToken";
import CustomModal from "../../common/CustomModal";
import FlexBox from "../../common/FlexBox";
import CustomButton from "../../CustomButton";
import Dashboardtitle from "../../DashbordTitle";
import LearnMore from "../../LearnMore";
import Title from "../../Title";
import SelectToken from "../SelectToken";
import Swap from "../Swap";
import style from "./index.module.scss";

export default function STBLAmount() {
  const [swap, setSwap] = useState(false);
  const [selectchain, setSelectchain] = useState(false);
  const [selectToken, setSelectToken] = useState(false);
  const [confirmSwap, setConfirmSwap] = useState(false);
  const navigate = useNavigate();
  const openSwap = () => {
    setSwap((swap) => !swap);
  };
  const Openselectchain = () => {
    setSelectchain((selectchain) => !selectchain);
  };

  const Openselecttoekn = () => {
    setSelectToken((selectToken) => !selectToken);
  };
  const openConfirmSwap = () => {
    setConfirmSwap((confirmSwap) => !confirmSwap);
  };
  const chooseChainData = [
    {
      title: "BUSD",
      tokenIcon: <img src={binance} alt="binance" />,
      tokenText: "Binance USD",
    },
    {
      title: "BUSD",
      tokenIcon: <img src={currencyone} alt="binance" />,
      tokenText: "Binance USD",
    },
    {
      title: "BUSD",
      tokenIcon: <img src={Currencythree} alt="binance" />,
      tokenText: "Binance USD",
    },
    {
      title: "BUSD",
      tokenIcon: <Currencyfour />,
      tokenText: "Binance USD",
    },
    {
      title: "BUSD",
      tokenIcon: <Currencyfive />,
      tokenText: "Binance USD",
    },
    {
      title: "BUSD",
      tokenIcon: <Currencysix />,
      tokenText: "Binance USD",
    },
  ];
  const tokenHeader = [
    {
      title: "ETH",
      tokenIcon: <img src={tokenhead} alt="binance" />,
      tokenText: "Ethereum",
    },
    {
      title: "BSC",
      tokenIcon: <Tokenhead2 />,
      tokenText: "Title",
    },
    {
      title: "AVAX",
      tokenIcon: <img src={tokenhead3} alt="binance" />,
      tokenText: "Title",
    },
  ];
  const printPrice = [
    {
      received: "Est. received",
      recValue: "0.0195548 AAVE",
    },
    {
      received: "Min. received",
      recValue: "0.0195548 AAVE",
    },
  ];
  return (
    <div className={style.stbleAmountMain}>
      <BackButton
        onClick={() => {
          navigate(-1);
        }}
      />
      <div className={style.stbleAmount}>
        <Dashboardtitle title="Swap" />
        <FlexBox className={style.chooseToken}>
          <ChooseToken
            chainIcon={<Chain />}
            from="From"
            douwnIcon={<Down />}
            chooseitem="Choose chain"
            onClick={Openselectchain}
          />
          <CustomModal isOpen={selectchain} toggle={Openselectchain}>
            <Title title={"Choose chain"} className={style.selectPopupTitle} />
            <FlexBox className={style.choosechainFlexbox}>
              <SelectToken tokenData={chooseChainData} />
            </FlexBox>
          </CustomModal>
          <Exchange className={style.exchangeIcon} />
          <ChooseToken
            chainIcon={<Chain />}
            from="To"
            douwnIcon={<Down />}
            chooseitem="Choose token"
            onClick={Openselecttoekn}
          />
          <CustomModal isOpen={selectToken} toggle={Openselecttoekn}>
            <Title title={"Choose token"} className={style.selectPopupTitle} />
            <FlexBox className={style.chooseTokenflexbox}>
              <SelectToken tokenData={tokenHeader} />
            </FlexBox>

            <FlexBox className={style.choosechainFlexbox}>
              <SelectToken tokenData={chooseChainData} />
            </FlexBox>
          </CustomModal>
        </FlexBox>
        <div className={style.coinDetail}>
          <Swap
            type="Enter the amount"
            startIcon={<Chain />}
            amount={0.0}
            isMax={false}
            isDropMenu={false}
          />
          <Exchange className={style.exchangeIcon} />
          <Swap
            type="You’ll get"
            startIcon={<Chain />}
            amount={0.0}
            isMax={false}
            isDropMenu={false}
          />
        </div>
        <div className={style.priceImpact}>
          <FlexBox className={style.priceImpactflexbox}>
            <p>Price impact</p>
            <p className={style.priceValue}>0.57%</p>
          </FlexBox>
          {printPrice.map((priceItems, index) => {
            return (
              <FlexBox className={style.priceImpactflexbox} key={index}>
                <p>{priceItems.received}</p>
                <strong className={style.receivedValue}>
                  {priceItems.recValue}
                </strong>
              </FlexBox>
            );
          })}
        </div>
        <CustomButton className={style.confirmButton} onClick={openConfirmSwap}>
          Confirm Swap
        </CustomButton>
        <CustomModal isOpen={confirmSwap} toggle={openConfirmSwap}>
          <LearnMore
            className={style.transactionPopup}
            title={"Transaction confirmed!"}
            buttonContent={"Got it!"}
            contnet={
              "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words"
            }
          />
        </CustomModal>
      </div>
      <div className={style.faqSection}>
        <Dashboardtitle title="FAQ" className={style.faqtitle} />
        <Link
          className={clsx(style.swapLink, style.swapLinkSpace)}
          to=""
          onClick={openSwap}
        >
          What is a Swap?
        </Link>
        <CustomModal isOpen={swap} toggle={openSwap}>
          <Title title={"What is a Swap?"} className={style.swapPoptitle} />
          <p>
            A multichain swap is a way to exchange one cryptocurrency for
            another directly, without needing a middleman, by connecting
            different blockchains together. It makes swapping cryptocurrencies
            easier and more efficient.
            <br></br>
            <br></br>
            In our way you can even earn money, if you swap cryptocurrencies in
            order to get the vault to equilibrium.{" "}
          </p>
          <CustomButton className={style.gotItButton} onClick={openSwap}>Got it!</CustomButton>
        </CustomModal>
        <Link className={style.swapLink} to="">
          How to buy an Indicator Token?
        </Link>
      </div>
    </div>
  );
}
