import { useNavigate } from "react-router-dom";
import CustomButton from "../CustomButton";
import styles from "./index.module.scss";

type Props = {
  email: string;
  user: User;
};
const QRCode = (props: Props) => {
  const navigate = useNavigate();

  const { email, user } = props;

  const handleNext = () => {
    navigate("/2fa-auth", { state: { email } });
  };

  return (
    <div className={styles.container}>
      <h2>2FA Authentication </h2>
      <div className={styles.welcome}>
        <h3>
          Welcome, <span></span>
        </h3>
        <p>Please scan this QR code to you Authenticator!</p>
        <p>
          Here, you will find your 6 digits OTP that is needed for verification.
        </p>
        <p>It is mandatory for 2FA Authentication!</p>
        <div className={styles.scannerImg}>
          <img src={user.qrCode2FA ?? ""} />
        </div>
      </div>
      <CustomButton onClick={handleNext} data-cy="next">
        Next
      </CustomButton>
    </div>
  );
};
export default QRCode;
