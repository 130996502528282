import React from "react";
import useWindowResize from "../../../hooks/useWindowResize";
import ProgressBar from "../../ProgressBar";
import style from "./index.module.scss";
// type Data = {

// };
type Props = {
  sn: number;
  title: string;
  headerValue: any;
};
export default function AccordionHeader(props: Props) {
  const { sn, title, headerValue } = props;
  const { width } = useWindowResize();
  const breakpoint = 576;
  const [progress, setProgress] = React.useState(50);

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) =>
  //       prevProgress >= 100 ? 10 : prevProgress + 10
  //     );
  //   }, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);
  return (
    <div className={style.accordionHeader}>
      <div className={style.serialNumber}>
        {width < breakpoint ? <p className={style.breackpoint}>Pos</p> : ""}
        {sn}
      </div>
      <div className={style.title}>
        {width < breakpoint ? (
          <p className={style.breackpoint}>Collection</p>
        ) : (
          ""
        )}
        <h4> {title}</h4>
        <p>By Larva Labs </p>
      </div>
      <div className={style.ProgressBar}>
        {width < breakpoint ? <p className={style.breackpoint}>NFTs</p> : ""}
        <ProgressBar value={progress} />
      </div>
      <div className={style.headerValue}>
        {width < breakpoint ? (
          <p className={style.breackpoint}>Price (USD)</p>
        ) : (
          ""
        )}
        {headerValue}
      </div>
      <div className={style.selectValue}>
        {width < breakpoint ? (
          <p className={style.breackpoint}>Change VS USD (24H)</p>
        ) : (
          ""
        )}
        2.55%
      </div>
    </div>
  );
}
