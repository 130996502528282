import clsx from "clsx";
import styles from "./index.module.scss";
import { ArrowUpB } from "../../assets";
import { AllocationType } from "../../pages/MyToken";

const Allocation = ({
  data: { collection, by, nfts, price, change },
  index,
}: AllocationType) => {
  return (
    <div className={styles.allocationRow}>
      <div className={clsx(styles.allocationRowCell, styles.pos)}>
        <p>{index + 1}</p>
      </div>
      <div className={styles.allocationRowCell}>
        <p>{collection}</p>
        <span>{by}</span>
      </div>
      <div className={styles.allocationRowCell}>
        <p>{nfts}</p>
        <div className={styles.pContainer}>
          <div className={styles.progress}>
            <div className={styles.progressInner}></div>
          </div>
        </div>
      </div>
      <div className={styles.allocationRowCell}>
        <p>${price}</p>
      </div>
      <div
        className={clsx(styles.allocationRowCell, styles.flex, styles.change)}
      >
        <ArrowUpB />
        <h6 className={styles.blue}>{change}%</h6>
      </div>
    </div>
  );
};

export default Allocation;
