import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../store/reducers/authSlice";
import CustomButton from "../CustomButton";
import Title from "../Title";
import CustomModal from "../common/CustomModal";
import FlexBox from "../common/FlexBox";
import style from "./index.module.scss";

type Props = {
  isOpen: boolean;
  toggle: () => void;
};
const Logout = (props: Props) => {
  const { isOpen, toggle } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutHandler = () => {
    dispatch(logoutUser());
    navigate("/login");
  };
  return (
    <CustomModal isOpen={isOpen} toggle={toggle}>
      <Title title="Are you sure you want to logout?" className={style.title} />
      <FlexBox className={style.flex}>
        <CustomButton variant="outlined" onClick={toggle}>
          Cancel
        </CustomButton>
        <CustomButton onClick={logoutHandler}>Logout</CustomButton>
      </FlexBox>
    </CustomModal>
  );
};

export default Logout;
