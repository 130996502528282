import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export interface InitialState {
  user: User | null;
  isCompleted: boolean;
}

const initialState: InitialState = {
  user: null,
  isCompleted: false,
};

export const onBoardSlice = createSlice({
  name: "onBoard",
  initialState,
  reducers: {
    onBoardingUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    completeRegistration: (state, action: PayloadAction<boolean>): void => {
      state.isCompleted = action.payload;
    },
    print: (state) => {},
  },
});

export const { onBoardingUser, completeRegistration, print } =
  onBoardSlice.actions;

export default onBoardSlice.reducer;
