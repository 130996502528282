import clsx from "clsx";
import React from "react";
import { ReactComponent as Payment } from "../../../assets/saving-plan-img/Payment.svg";
import { ReactComponent as PaymentTwo } from "../../../assets/saving-plan-img/Paymenttwo.svg";
import CardVisa from "../../CardVisa";
import Checkbox from "../../Checkbox";
import Dashboardtitle from "../../DashbordTitle";
import Input from "../../Input";
import RadioBtn from "../../RadioBtn";
import style from "./index.module.scss";
export default function CustomPayment() {
  return (
    <div className={style.customePlanPayment}>
      <Dashboardtitle
        className={style.investingTitle}
        title={"Payment method"}
        description={"Select payment method"}
      />
      <div className={style.creditDebitCard}>
        <RadioBtn
          className={style.creditCardText}
          label={"CREDIT / DEBIT CARD"}
          id={"creditDebitCard"}
        />
        <CardVisa className={style.visacard} />
      </div>
      <div className={style.paymentForm}>
        <Input
          placeholder={"**** **** **** ****"}
          id={"cardnumber"}
          name={"cardnumber"}
          type={"password"}
          label={"Card number"}
        />
        <Input
          placeholder={"Enter name"}
          id={"holder"}
          name={"holder"}
          type={"text"}
          label={"Card holder"}
        />
        <div className={style.formCccNumber}>
          <div className={style.expirydate}>
            <Input
              placeholder={"mm  / yy"}
              id={"expiry"}
              name={"Expiry"}
              type={"date"}
              label={"Expiry date"}
            />
          </div>
          <div className={style.Cvv}>
            <Input
              placeholder={"***"}
              id={"cvv"}
              name={"cvv"}
              type={"password"}
              label={"CVV"}
            />
          </div>
        </div>
      </div>
      <div className={style.selectItems}>
        <div className={clsx(style.multipleItems, style.crypto)}>
          <RadioBtn label={"CRYPTO"} id={"crypto"} />
        </div>
        <div className={style.multipleItems}>
          <RadioBtn label={"PAYPAL"} id={"payapal"} />
          <Payment />
        </div>
        <div className={style.multipleItems}>
          <RadioBtn label={"KLARNA"} id={"klarna"} />
          <PaymentTwo />
        </div>
        <div className={style.multipleItems}>
          <RadioBtn label={"AMAZON PAY"} id={"amazon"} />
          <Payment />
        </div>
      </div>
      <div className={style.eachMonth}>
        <Checkbox
          className={style.eachMonthCheckbox}
          id={"eachmonth"}
          label={"Bill me now and then on the 16th of each month."}
        />
      </div>
    </div>
  );
}
