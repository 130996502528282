import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import { DollarIcon } from "../../../assets/index";
import useTokens from "../../../hooks/useTokens";
import { formatAmountToFixed } from "../../../utils/helper";
import CustomButton from "../../CustomButton";
import FlexBox from "../../common/FlexBox";
import DepositToken from "../DepositToken";
import style from "../Mywallet/index.module.scss";
import WalletToken from "../WalletToken";

export default function Mywallet() {
  const [showView, setShowView] = useState<string>("token");
  const { tokens, walletBalance, availableWalletBalance } = useTokens();

  const renderViewHandler = () => {
    if (showView === "token") {
      return <WalletToken tokens={tokens} />;
    } else {
      return <DepositToken type={showView} />;
    }
  };

  return (
    <div className={style.myWallet}>
      <FlexBox className={style.mywalletCard}>
        <FlexBox>
          <div className={style.walletBalanceIcon}>
            <DollarIcon />
          </div>
          <div>
            <p className={style.walletBalance}>Total Wallet Balance</p>
            <strong className={style.wallerPrice}>
              ${formatAmountToFixed(walletBalance)}
            </strong>
          </div>
          {showView === "withdraw" && <div className={style.avlWalletBal}>
            <p className={style.walletBalance}>Available Balance to Withdraw</p>
            <strong className={style.wallerPrice}>
              ${formatAmountToFixed(availableWalletBalance)}
            </strong>
          </div>}
        </FlexBox>
        <FlexBox className={style.buttonGroups}>
          <>
            {showView !== "token" && (
              <ArrowBackIcon
                color="primary"
                className={style.backArrowIcon}
                onClick={() => setShowView("token")}
              />
            )}
            <CustomButton
              className={style.addButton}
              onClick={() => setShowView("deposit")}
              variant={showView === "deposit" ? "primary" : "outlined"}
            >
              Deposit Crypto
            </CustomButton>
            <CustomButton
              className={style.withdraw}
              variant={showView === "withdraw" ? "primary" : "outlined"}
              onClick={() => setShowView("withdraw")}
            >
              Withdraw Crypto
            </CustomButton>
          </>
        </FlexBox>
      </FlexBox>
      {renderViewHandler()}
    </div>
  );
}
