import { useState } from "react";
import { BlogImg } from "../../assets";
import { dateFormat } from "../../utils/formatter";
import CustomButton from "../CustomButton";
import Title from "../Title";
import CustomModal from "../common/CustomModal";
import styles from "./index.module.scss";

const BlogCard = (props: BlogProps) => {
  const {
    createdByAdmin: { firstName, lastName },
    title,
    createdAt,
    image,
    description,
    setIsAutoPlay,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggle = () => setIsModalOpen((isModalOpen) => !isModalOpen);
  return (
    <div className={styles.blogCard}>
      <div className={styles.blogCardImg}>
        <img
          src={image ? image : BlogImg}
          alt={`${title} - image`}
          onClick={() => {
            toggle();
            setIsAutoPlay(false);
          }}
        />
      </div>
      <div className={styles.blogCardWrapper}>
        <h3
          onClick={() => {
            toggle();
            setIsAutoPlay(false);
          }}
        >
          {title}
        </h3>
        <div className={styles.blogFooter}>
          <p>
            by {firstName} {lastName}
          </p>
          <h6>{dateFormat(createdAt, "MMM DD, YYYY")}</h6>
        </div>
      </div>

      <CustomModal isOpen={isModalOpen} toggle={toggle}>
        <div className={styles.modalWrapper}>
          <div className={styles.modalContainer}>
            <Title className={styles.modalTitle} title={title}></Title>
            <h6>{dateFormat(createdAt, "MMM DD, YYYY")}</h6>
          </div>
          <div className={styles.imgContainer}>
            <img src={image ? image : BlogImg} alt={`${title}`} />
          </div>
          <p className={styles.para}>{description}</p>
        </div>
        <div className={styles.btnContainer}>
          <CustomButton
            onClick={() => {
              toggle();
              setIsAutoPlay(true);
            }}
            className={styles.btn}
          >
            Close
          </CustomButton>
        </div>
      </CustomModal>
    </div>
  );
};

export default BlogCard;
