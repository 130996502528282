import clsx from "clsx";
import { Cell, Pie, PieChart } from "recharts";
import useTokens from "../../hooks/useTokens";
import Card from "../Card";
import ChartListItem from "./ChartListItem";
import styles from "./index.module.scss";
import { convertNstableLabel } from "../../utils/helper";

const COLORS = ["#4E307A", "#9C86CE", "#D4C7F0", "#E5E7EB"];

export default function TokensCard() {
  const { tokens, walletBalance } = useTokens();

  const pieChartData = tokens.map((asset) => {
    return {
      name: convertNstableLabel(asset.symbol),
      value: parseFloat(asset.balance),
    };
  });

  const chartClass = tokens.length !== 0 ? null : styles.chartSingle;
  const chartTextClass =
    tokens.length !== 0 ? styles.chartTextContainer : styles.displayNone;

  return (
    <Card className={styles.dashboardCard}>
      <div className={styles.tokenContainer}>
        <h2>My Tokens</h2>
        {tokens.length === 0 && (
          <p className={styles.notFound}>No data found!</p>
        )}
        <div className={clsx(styles.chart, chartClass)}>
          <div className={styles.pie}>
            <PieChart width={180} height={220}>
              <Pie
                data={pieChartData}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {pieChartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    style={{ outline: 'none' }}
                  />
                ))}
              </Pie>
            </PieChart>
            {pieChartData.length !== 0 && (
              <div className={styles.total}>
                <h2 title={`$${walletBalance}`}>${walletBalance}</h2>
                <p>Total</p>
              </div>
            )}
          </div>
          <div className={chartTextClass}>
            {tokens?.map((item, index) => (
              <div className={styles.pieInner} key={index}>
                <div className={styles.pieLeft}>
                  <div
                    className={styles.bullet}
                    style={{ backgroundColor: COLORS[index] }}
                  ></div>
                  <h4>{convertNstableLabel(item.name || item.symbol)}</h4>
                </div>
                <p>{parseFloat(item.balance).toFixed(6)}</p>
              </div>
            ))}
          </div>
        </div>
        {tokens?.map((item, i) => (
          <ChartListItem data={item} key={i} />
        ))}
      </div>
    </Card>
  );
}
