import FlexBox from "../../common/FlexBox";
import DepositDetail from "../DepositDetail";
import DepositInfo from "../DepositInfo";
import Transactions from "../Transactions";
import styles from "./index.module.scss";

type Props = {
  type: string;
};
const DepositToken = (props: Props) => {
  const { type } = props;
  return (
    <>
      <FlexBox className={styles.flex}>
        <DepositDetail
          title={type == "deposit" ? "Deposit Crypto" : "Withdraw Crypto"}
          type={type}
        />
        <DepositInfo type={type} />
      </FlexBox>
      <Transactions
        key={type}
        type={type === "deposit" ? "Deposit" : "Withdraw"}
        title={type === "deposit" ? "Deposit  history" : "Withdrawal  history"}
      />
    </>
  );
};

export default DepositToken;
