import { LogoSmall } from "../../assets";
import styles from "./index.module.scss";

const OnBoardHeader = () => {
  return (
    <div className={styles.container}>
      <LogoSmall />
    </div>
  );
};
export default OnBoardHeader;
