import FlexBox from "../../common/FlexBox";
import styles from "./index.module.scss";
type Props = {
  title: string;
  type: string;
};
const FundLimit = (props: Props) => {
  const { title, type } = props;
  const renderComponent = (t: string) => {
    if (t === "deposit") {
      return (
        <FlexBox className={styles.flex}>
          <div className={styles.detailInfo}>
            <p>Daily Deposits</p>
            <h4>$0.00 of Unlimited USD</h4>
          </div>
          <div className={styles.detailInfo}>
            <p>Daily Deposit Limit</p>
            <h4>Unlimited USD</h4>
          </div>
        </FlexBox>
      );
    } else {
      return (
        <FlexBox className={styles.flex}>
          <div className={styles.detailInfo}>
            <p>Daily Withdrawal</p>
            <h4>$0.00 of Unlimited USD</h4>
          </div>
          <div className={styles.detailInfo}>
            <p>Daily Withdrawal Limit</p>
            <h4>Unlimited USD</h4>
          </div>
        </FlexBox>
      );
    }
  };
  return (
    <div className={styles.fundLimit}>
      <h3>{title}</h3>
      {renderComponent(type)}
    </div>
  );
};

export default FundLimit;
