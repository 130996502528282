import { useEffect, useMemo, useState } from "react";
import { useSocket } from "../context/Socket";
import { useLazyGetSellOrdersQuery } from "../service/api";
import { events } from "../utils/constant";

export type DayFilter = "" | "7d" | "30d" | "6m";

type Payload = {
  page?: string;
  limit?: string;
  filter?: string;
  status?: SellOrder["status"];
};

type Props = {
  defaultPage?: number;
  limit?: number;
  defaultFilter?: DayFilter;
  status?: SellOrder["status"];
};

export default function useSellOrder(props: Props) {
  const { defaultPage = 1, limit = 10, defaultFilter = "", status } = props;
  const [page, setPage] = useState(defaultPage);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState<DayFilter>(defaultFilter);
  const [sellOrders, setSellOrder] = useState<SellOrder[]>([]);
  const { io } = useSocket();
  const [loading, setLoading] = useState(false);

  const loadMoreCount = useMemo(() => {
    const restTransactions = count ? count - sellOrders.length : 0;
    const result = sellOrders.length ? restTransactions : 0;
    return result > 0 ? result : 0;
  }, [count, sellOrders]);

  const [getSellOrders] = useLazyGetSellOrdersQuery();

  const fetchOrders = async (
    page: number,
    filter: DayFilter = "",
    refresh = false
  ) => {
    try {
      if (loading) return;
      setLoading(true);

      const payload: Payload = {
        page: `${page}`,
        limit: `${limit}`,
      };

      if (filter) {
        payload.filter = filter;
      }

      if (status) {
        payload.status = status;
      }

      const { data } = await getSellOrders(payload).unwrap();
      setSellOrder((sellOrders) => {
        if (refresh) return data.orders;
        return [...sellOrders, ...data.orders];
      });
      setCount(data.count);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const loadMore = async () => {
    if (count > page * limit) {
      await fetchOrders(page + 1, filter);
      setPage(page + 1);
    }
  };

  const applyFilter = (filter: DayFilter) => {
    return () => {
      setFilter(filter);
      fetchOrders(page, filter, true);
    };
  };

  const handleSyncOrders = async (data?: SellOrder) => {
    setSellOrder((items) => {
      if (!data || filter) return items;
      const index = items.findIndex((item) => item.id === data.id);
      if (index > -1) {
        items[index] = data;
        return [...items];
      }
      return [data, ...items];
    });
    if (!data) {
      await fetchOrders(page, filter, true);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchOrders(page, "", true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      io?.on(events.syncOrders, handleSyncOrders);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [io]);

  return {
    sellOrders,
    loadMoreCount,
    loadMore,
    applyFilter,
    page,
    filter,
    loading,
  };
}
