import { ArrowUpB } from "../../assets";
import { AllocationType } from "../../pages/MyToken";
import styles from "./index.module.scss";

const AllocationMobile = ({
  data: { collection, by, nfts, price, change },
  index,
}: AllocationType) => {
  return (
    <div className={styles.alloMobContainer}>
      <div className={styles.alloMobInner}>
        <p>Pos</p>
        <h5>{index + 1}</h5>
      </div>
      <div className={styles.alloMobInner}>
        <p>Collection</p>
        <h5>{collection}</h5>
        <h6>{by}</h6>
      </div>
      <div className={styles.alloMobInner}>
        <p>NFTs</p>
        <h5>{nfts}</h5>
        <div className={styles.pContainer}>
          <div className={styles.progress}>
            <div className={styles.progressInner}></div>
          </div>
        </div>
      </div>
      <div className={styles.alloMobInner}>
        <p>Price</p>
        <h5>{price}$</h5>
      </div>
      <div className={styles.alloMobInner}>
        <p>Change VS USD (24H)</p>
        <div className={styles.change}>
          <ArrowUpB />
          <h5 className={styles.blue}>{change}%</h5>
        </div>
      </div>
    </div>
  );
};

export default AllocationMobile;
