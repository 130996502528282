import Dashboardtitle from "../../components/DashbordTitle";
import styles from "./index.module.scss";

const UserAgreement = () => {
  return (
    <div className={styles.terms}>
      <Dashboardtitle
        title="User Agreement"
        description={`
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.`}
      ></Dashboardtitle>
    </div>
  );
};

export default UserAgreement;
