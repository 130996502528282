import React, { useState } from "react";
import { schweinBanner } from "../../../assets";
import { ReactComponent as Rewardsdiagram } from "../../../assets/saving-plan-img/Rewardsdiagram.svg";
import useWindowResize from "../../../hooks/useWindowResize";
import Banner from "../../Banner";
import CustomModal from "../../common/CustomModal";
import CustomButton from "../../CustomButton";
import Dashboardtitle from "../../DashbordTitle";
import Input from "../../Input";
import LearnMore from "../../LearnMore";
import style from "./index.module.scss";
export default function InvestmentInfoPlan() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggle = () => {
    setIsModalOpen((isModalOpen) => !isModalOpen);
  };
  const { width } = useWindowResize();
  const breackpoint = 576;
  return (
    <div className={style.InvestmentInfoPlan}>
      {/* <div className={style.stakeYour}>
        <div className={style.stakeYourText}>
          <Dashboardtitle
            title={"Less stress with a reliable process"}
            description={
              "Are you the DCA kind of investor? We stand by your side with our novel Auto-Invest system and offer the perfect tool for simple and efficient Dollar-Cost Averaging."
            }
          />
          <p className={style.bannerText}>
            With Auto-Invest, you can set your own budget however you like and
            steadily grow your portfolio on a monthly basis without having to
            stress about timing the market or missing an opportunity. Less
            stress is healthy, and if it’s healthy, it’s Crypto!
          </p>
        </div>
        <div className={style.stakeYourImg}>
          <img src={schwein} alt="car" />
        </div>
        {width < breackpoint ? (
          <CustomButton className={style.installButton}>
            Invest Now
          </CustomButton>
        ) : (
          ""
        )}
      </div> */}
      <Banner
        src={schweinBanner}
        alt="schweinBanner"
        contentsec="With Auto-Invest, you can set your own budget however you like and
        steadily grow your portfolio on a monthly basis without having to
        stress about timing the market or missing an opportunity. Less
        stress is healthy, and if it’s healthy, it’s Crypto!"
        content="Are you the DCA kind of investor? We stand by your side with our novel Auto-Invest system and offer the perfect tool for simple and efficient Dollar-Cost Averaging."
        title="Less stress with a reliable process"
      >
        {width < breackpoint ? (
          <CustomButton className={style.installButton}>
            Invest Now
          </CustomButton>
        ) : (
          ""
        )}
      </Banner>
      <div className={style.InfoNeal}>
        <Dashboardtitle
          title="Auto-Invest calculator"
          className={style.autocalcutaltorTitle}
        />
        <div className={style.innerLeft}>
          <div className={style.cryptoAmount}>
            <li>
              <p>Amount (monthly)</p>
              <strong>0 $</strong>
            </li>
            <li>
              <p>$NEAL Amount (monthly)</p>
              <strong className={style.textRight}>0 $NEAL</strong>
            </li>
          </div>
          <div className={style.ProgressBar}></div>
          <div className={style.inputClass}>
            <p className={style.usd}>USD</p>
            <Input
              type={"number"}
              label={"Monthly investment"}
              placeholder={"0"}
              id={"investment"}
              name={"investment"}
            />
          </div>
          <div className={style.Investmentline}>
            <p>Duration</p>
            <h5>0 month</h5>
          </div>
          <div className={style.ProgressBar}></div>
          <div className={style.inputClass}>
            <p className={style.usd}>month(s)</p>
            <Input
              type={"number"}
              label={"Duration"}
              placeholder={"0"}
              id={"timeline"}
              name={"timeline"}
            />
          </div>
          <div className={style.mainTerms}>
            <span>Average xpected returns %</span>
            <div className={style.terms}>
              <button>5%</button>
              <button>10% </button>
              <button>15%</button>
              <button>25%</button>
            </div>
          </div>
        </div>
        <div className={style.innerRight}>
          <div className={style.rewards}>
            <ul>
              <li>
                <p>Rewards</p>
                <strong className={style.blue}>6.4%</strong>
              </li>
              <li>
                <CustomButton
                  onClick={() => setIsModalOpen(true)}
                  className={style.learnMore}
                  variant={"outlined"}
                >
                  Learn More
                </CustomButton>
                <CustomModal isOpen={isModalOpen} toggle={toggle}>
                  <LearnMore
                    title={"Learn more"}
                    buttonContent={"Ok, Got It!"}
                    contnet={
                      "Auto-Invest is an automated investment tool which allows its users to set parameters to off-load some of the investment process’ weight.Auto-Invest works strictly within those parameters. It will only ever invest the amount you specified, and only for the specified duration. Note that Auto-Invest strictly follows these 2 parameters and does not take into consideration any price, price movement, or special event of any kind.Of course, you can create several Auto-Invest setups, for different tokens and with different parameters to create the investment environment that is best for you.Upon using Auto-Invest you will earn a reward distributed in $NEAL, Crypto’s ecosystem token. Said reward amount is dynamically calculated based on the Auto-Invest usage and other parameters. $NEAL can be staked to earn an APY, and used to participate in decisions submitted to Governance."
                    }
                  />
                </CustomModal>
              </li>
            </ul>
            <div className={style.cryptoAmount}>
              <li>
                <p>Total deposited amount</p>
                <strong>
                  0.00 $NEAL <span>$0.00</span>
                </strong>
              </li>
              <li>
                <p>Interest payments received</p>
                <strong>
                  0.00 $NEAL<span>$0.00</span>
                </strong>
              </li>
            </div>
          </div>
          <p>
            <Rewardsdiagram />
          </p>
        </div>
      </div>
      <div className={style.whyInvestText}>
        <Dashboardtitle title={"Why use Auto-Invest?"} />
        <div className={style.whyInvestingContent}>
          <div className={style.contentLeft}>
            <p className={style.content}>
              With our unique Auto-Invest system, you can stay in total control
              of your investment habits, without the need to dedicate chunks of
              your valuable time to make sure that your hard-earned money is
              well spent. Simply tell the system how much you want to invest,
              for how long and into which asset, and it will take care of the
              rest.
            </p>
          </div>
          <div className={style.contentRight}>
            <p className={style.content}>
              All you have to do is set the Auto-Invest up with the parameters
              of your choice and let it do the work for you, on your terms.
              Quick, effortless and extremely flexible, it also presents further
              benefits by distributing rewards in $NEAL, our ecosystem token,
              which allows to take part in Governance and can also be staked to
              generate even more passive returns. With Crypto, it’s always about
              how we can give you more!
            </p>
          </div>
        </div>
        <div className={style.whyInvestingContent}>
          <div className={style.contentLeft}>
            <p className={style.activeContent}>
              “There’s a time and a place for everything.” You found the place,
              make sure to not miss the time and start your journey towards
              financial freedom with Auto-Invest and all the amazing tools and
              features Crypto has created for you!
            </p>
          </div>
          <div className={style.contentRight}>
            <CustomButton className={style.installNow}>
              Now’s the time!
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
}
