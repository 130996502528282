import { TabContext, TabList, TabPanel } from "@mui/lab";
// import TabList from "@mui/lab";
// import TabPanel from "@mui/lab";
import { Box, Tab } from "@mui/material";
import clsx from "clsx";
import React from "react";
import AlertMessage from "../../components/AlertMessage";
import Dashboardtitle from "../../components/DashbordTitle";
import Airdrop from "../../components/LoyaltyComponent/AirDrop";
import LoyaltyProgram from "../../components/LoyaltyComponent/loyaltyProgram";
import { useAlertMessage } from "../../context/AlertContext";
import { useAppSelector } from "../../store/hooks";

export default function LoyaltyHeader() {
  const [value, setValue] = React.useState("1");
  const { alertMsg, setAlertMsg, onCloseAlert } = useAlertMessage();
  const { isKYCed } = useAppSelector((state) => state.auth);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div
      className={clsx(
        "material-ui-tab"
        // !isKYCed && "fade"
      )}
    >
      <Dashboardtitle title={"Loyalty program"} />
      {alertMsg?.msg && (
        <AlertMessage type={alertMsg?.type} onClose={onCloseAlert}>
          {alertMsg.msg}
        </AlertMessage>
      )}

      <Box
        sx={{
          width: "100%",
          typography: "body1",
        }}
      >
        <TabContext value={value}>
          <Box>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              textColor="inherit"
              TabIndicatorProps={{ sx: { backgroundColor: "#FFB000" } }}
              sx={{
                "& button": {
                  padding: "0",
                  color: "#2e637d",
                  fontSize: "16px",
                  marginRight: "15px",
                  textTransform: "capitalize",
                  fontFamily: "Nunito Sans",
                },
                "& button.Mui-selected ": {
                  color: "#1B4458",
                  fontWeight: "600",
                  fontFamily: "Nunito Sans",
                },
              }}
            >
              <Tab label="Loyalty program" value="1" />
              <Tab label="Airdrop" value="2" />
            </TabList>
          </Box>
          <TabPanel sx={{ padding: 0 }} value="1">
            <LoyaltyProgram />
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value="2">
            <Airdrop />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
