import clsx from "clsx";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as Menu } from "../../assets/icons/menu-icon.svg";
import useWindowResize from "../../hooks/useWindowResize";
import { useAppSelector } from "../../store/hooks";
import Searchinput from "../Searchbar";
import HeaderIcons from "../headerIcon";
import Style from "./index.module.scss";

type Props = {
  toggleSideBar?: () => void;
};

export default function DashboardHeader(props: Props) {
  const { isOnboardingSkipped } = useAppSelector((state) => state.auth);
  const { width } = useWindowResize();
  const breakpoint = 768;
  return (
    <div
      className={clsx(
        Style.mainHeader,
        width > breakpoint && isOnboardingSkipped && ""
      )}
    >
      <div className={Style.headerInner}>
        <div className={Style.mobileIcon}>
          <Menu onClick={props.toggleSideBar} />
          <Logo />
        </div>
        {/* <BackButton className={Style.backButton}/> */}
        <Searchinput />
        <HeaderIcons />
      </div>
    </div>
  );
}
