import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Dashboardtitle from "../../components/DashbordTitle";
import CRYTokenPage from "../../components/ProductComponents/CRYTokenPage";
import NFTSToeknPage from "../../components/ProductComponents/NFTSTokenPage";
import Overview from "../../components/ProductComponents/Overview";
import ProductHelpCenter from "../../components/ProductComponents/ProductHelpCenter";
import { environment } from "../../environment";
import { PRODUCT_TABS } from "../../utils/constant";

const tokenCoin = ["overview", "nstbl", "nfts", "help"];
export default function ProductIndexHeader() {
  const [value, setValue] = React.useState("1");
  const [searchParam, setSearchParam] = useSearchParams();
  const navigate = useNavigate();
  const queryParam = searchParam.get("q")?.toLowerCase();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setSearchParam({ q: tokenCoin[Number(newValue) - 1] });
  };
  const setTabValue = () => {
    switch (queryParam) {
      case tokenCoin[1]:
        setValue("2");
        navigate(`/product/nstbl?q=${queryParam}`);
        break;
      case tokenCoin[2]:
        setValue("3");
        navigate(`/product/nfts?q=${queryParam}`);
        break;
      case tokenCoin[3]:
        setValue("4");
        navigate(`/product/help?q=${queryParam}`);
        break;
      case tokenCoin[0]:
        setValue("1");
        navigate(`/product`);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    setTabValue();
  }, [searchParam]);
  const pathNotAllow = environment.REMOVED_ACCESS_MODULE;
  const allowedTab = PRODUCT_TABS?.filter(
    (item) => !pathNotAllow.includes(item.label)
  );
  return (
    <div className="Indicator-Tokens">
      <Dashboardtitle title={"Index Tokens"} />
      <Box>
        <TabContext value={value}>
          <Box
            sx={{
              width: "100%",
              typography: "body1",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{ sx: { backgroundColor: "#FFB000" } }}
              sx={{
                "& button": {
                  padding: "0",
                  color: "#2e637d",
                  fontSize: "16px",
                  marginRight: "15px",
                  textTransform: "inherit",
                  fontFamily: "Nunito Sans",
                },
                "& button.Mui-selected ": {
                  color: "#1B4458",
                  fontWeight: "600",
                  fontFamily: "Nunito Sans",
                },
              }}
            >
              {allowedTab.map(({ label, value }) => (
                <Tab label={label} value={value} />
              ))}
            </TabList>
          </Box>
          <TabPanel sx={{ padding: 0 }} value="1">
            <Overview />
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value="2">
            <CRYTokenPage />
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value="3">
            <NFTSToeknPage />
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value="4">
            <ProductHelpCenter />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
