import { yupResolver } from "@hookform/resolvers/yup";
import { AlertColor } from "@mui/material";
import clsx from "clsx";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import AlertMessage from "../../components/AlertMessage";
import AppleLogin from "../../components/AppleLogin";
import CustomButton from "../../components/CustomButton";
import EditCancelPopup from "../../components/EditCacelPopup";
import FBLogin from "../../components/FBLogin";
import GoogleLogin from "../../components/GoogleLogin";
import Input from "../../components/Input";
import Title from "../../components/Title";
import CustomModal from "../../components/common/CustomModal";
import FlexBox from "../../components/common/FlexBox";
import { useAlertMessage } from "../../context/AlertContext";
import { environment } from "../../environment";
import BasicLayout from "../../layout/BasicLayout";
import {
  Email,
  emailResponse,
  useEmailLoginMutation,
  useRequestAccountRestoreMutation,
} from "../../service/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { rememberMeEmail } from "../../store/reducers/authSlice";
import { loginSchema } from "../../utils/yupSchema";
import styles from "../Register/index.module.scss";
import style from "./index.module.scss";

export type AlertType = {
  type: AlertColor | undefined;
  msg: string;
};
export default function Login() {
  const [rememberMe, setRememberMe] = useState(false);
  const [isAccountDelete, setIsAccountDelete] = useState<boolean>(false);
  const [isAccountClose, setIsAccountClose] = useState<boolean>(false);
  const [accountStatusMsg, setAccountStatusMsg] = useState<string>("");
  const [isEmail, setIsEmail] = useState<string>("");

  const { alertMsg, setAlertMsg, onCloseAlert } = useAlertMessage();

  const [emailLogin, { isLoading: loading }] = useEmailLoginMutation();
  const [requestUserAccount, { isLoading: accountLoading }] =
    useRequestAccountRestoreMutation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    auth: { authenticated, user, email: storedEmail },
  } = useAppSelector((state) => state);

  useEffect(() => {
    if (authenticated && user?.isOnboardingSkipped) navigate("/");
    if (authenticated && !user?.isOnboardingCompleted)
      navigate("/boarding_details");
    if (authenticated && user?.isOnboardingCompleted) navigate("/");
    if (storedEmail) setRememberMe(storedEmail !== "");
  }, [authenticated, user]);
  const methods = useForm({
    defaultValues: {
      email: storedEmail ?? "",
    },
    resolver: yupResolver(loginSchema),
  });
  const { email } = methods.formState.errors;

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setIsEmail(value);
  };

  const emailLoginHandler = async (e: Email) => {
    const { email } = e;
    storeEmailInLocal(email);
    const { data, error } = (await emailLogin({ email })) as emailResponse;
    if (data) {
      setAlertMsg?.({
        type: "success",
        msg: data?.message,
      });
      navigate("/otp", {
        state: {
          email,
          type: "login",
        },
      });
    }
    if (error) {
      setIsEmail(email);
      setAccountStatusMsg(error?.data?.message);
      if (error?.data?.data?.errorType == "IS_CLOSED") {
        toggleAccountClosed();
      } else if (error?.data?.data?.errorType == "IS_DELETED") {
        toggleAccountDelete();
      } else {
        setAlertMsg?.({
          type: "error",
          msg: error?.data?.message,
        });
      }
      console.log(error, "error");
    }
  };
  const storeEmailInLocal = (email: string) => {
    if (rememberMe) {
      dispatch(rememberMeEmail({ email, isAdd: true }));
    } else {
      dispatch(rememberMeEmail({ email, isAdd: false }));
    }
  };

  const onChangeRemember = (e: ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  };

  const toggleAccountDelete = () => {
    setIsAccountDelete((isAccountDelete) => !isAccountDelete);
  };

  const toggleAccountClosed = () => {
    setIsAccountClose((isAccountClose) => !isAccountClose);
  };

  const requestAccountHandler = async () => {
    if (isEmail.length === 0) {
      setAlertMsg?.({
        type: "error",
        msg: "Fill email id",
      });
      return;
    }
    const { data, error } = (await requestUserAccount({
      email: isEmail,
    })) as apiResponse;
    if (data) {
      toggleAccountClosed();
      setAlertMsg?.({
        type: "success",
        msg: data?.message,
      });
    }
    if (error) {
      setAlertMsg?.({
        type: "error",
        msg: error?.data?.message,
      });
    }
  };
  const socialBtnList = [
    {
      tag: "google",
      btnComponent: (
        <GoogleLogin
          navigateTo="/boarding_details"
          classes={{ inputGroup: styles.input_group, button: styles.btn }}
        />
      ),
    },
    {
      tag: "facebook",
      btnComponent: (
        <FBLogin
          navigateTo="/boarding_details"
          classes={{ inputGroup: styles.input_group, button: styles.btn }}
        />
      ),
    },
    {
      tag: "apple",
      btnComponent: (
        <div className={styles.input_group}>
          <AppleLogin navigateTo="/boarding_details" className={styles.btn} />
        </div>
      ),
    },
  ];
  const notAllowSocialButton = environment.REMOVED_SOCIAL_BUTTON;

  const allowedSocialButton = socialBtnList.filter(
    (item) => !notAllowSocialButton.includes(item.tag)
  );
  return (
    <div className={styles.bg}>
      <div className={clsx(styles.signup_page)}>
        <BasicLayout>
          <div className={styles.sign_form}>
            <form onSubmit={methods.handleSubmit(emailLoginHandler)}>
              <h2>Login to your account!</h2>
              {alertMsg?.msg && (
                <AlertMessage type={alertMsg?.type} onClose={onCloseAlert}>
                  {alertMsg.msg}
                </AlertMessage>
              )}
              <div className={styles.input_group}>
                <Controller
                  control={methods.control}
                  name="email"
                  render={({ field }) => (
                    <Input
                      type="text"
                      placeholder="Enter your e-mail address here"
                      id="Email"
                      label="E-mail"
                      error={email as FieldError}
                      {...field}
                      onKeyDown={handleChange}
                    />
                  )}
                />
              </div>
              <div className={styles.input_checkbox}>
                <Input
                  name="remember"
                  noWidth
                  placeholder=""
                  type="checkbox"
                  id="checkbox"
                  checked={rememberMe}
                  onChange={onChangeRemember}
                />
                <label htmlFor="checkbox" className={styles.rememberText}>
                  Remember me
                </label>
              </div>
              <div className={styles.or}>
                <span></span>
                Or
                <span></span>
              </div>
              {allowedSocialButton.map((socialButton) => (
                <>{socialButton.btnComponent}</>
              ))}
              <div className={clsx(`${styles.input_group} ${styles.login}`)}>
                <CustomButton
                  type="submit"
                  loading={loading}
                  disabled={!methods.formState.isValid}
                >
                  Login
                </CustomButton>
              </div>
              <div className={styles.registered}>
                <p>
                  Not registered?
                  <Link to="/register">
                    <span>Sign up!</span>
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </BasicLayout>
      </div>
      <CustomModal isOpen={isAccountDelete} toggle={toggleAccountDelete}>
        <FlexBox className={style.deleteModal}>
          <Title
            className={style.titleModal}
            title="Your account has been deleted!"
            content={accountStatusMsg}
          />
          {/* <p> {accountStatusMsg} </p> */}
          <CustomButton
            className={style.closeBtn}
            onClick={toggleAccountDelete}
            id="closeAccount"
          >
            Close
          </CustomButton>
        </FlexBox>
      </CustomModal>

      <CustomModal isOpen={isAccountClose} toggle={toggleAccountClosed}>
        <EditCancelPopup
          title={"Request admin to restore account!"}
          desc={accountStatusMsg}
          loading={accountLoading}
          updateBtn={"Confirm"}
          cancelBtn={"Cancel"}
          updateonClick={requestAccountHandler}
          cancelonClick={toggleAccountClosed}
        />
      </CustomModal>
    </div>
  );
}
