import { ChangeEvent, useState } from "react";
// import { useAppContext } from "../../context/AppContext"
// import { useAppContext } from "../../context/AppContext"
import { useNavigate } from "react-router-dom";
import { Done, WarningIcon } from "../../assets";
import { useAlertMessage } from "../../context/AlertContext";
import {
  useSkipOnBoardingMutation,
  useUpdateDocumentsMutation,
} from "../../service/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  changeSkipStatus,
  updateOnBoardingStep,
} from "../../store/reducers/authSlice";
import CustomButton from "../CustomButton";
import Input from "../Input";
import { OnBoardingProps } from "../PersonalInfo";
import SelectBox from "../SelectBox.tsx";
import SumsubSdk from "../SumsubSdk";
import CustomModal from "../common/CustomModal";
import UploadDoc from "./UploadDoc";
import styles from "./index.module.scss";

const IdentityVerification = ({
  activeStep,
  setActiveStep,
  stepCompletedCB,
  actions = true,
}: OnBoardingProps & { stepCompletedCB?: () => void; actions?: boolean }) => {
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDocNotApprove, setIsDocNotApprove] = useState<boolean>(false);
  const [isDocApprove, setIsDocApprove] = useState<boolean>(false);
  const [isDocSelected, setIsDocSelected] = useState<boolean>(false);
  const [isSelfieSelected, setIsSelfieSelected] = useState<boolean>(false);
  const [file, setFile] = useState<FileList | undefined | null>(null);

  const [sumsumAppId, setSumsumAppId] = useState<string>("");

  const [updateUser, { isLoading: loading }] = useUpdateDocumentsMutation();
  const [skipOnBoarding, { isLoading }] = useSkipOnBoardingMutation();

  const [modalStep, setModalStep] = useState<string>("DOC");
  const [state, setState] = useState({
    country: "",
    doc: "",
    idType: "",
    userId: "",
  });

  const [isDocTypeSelected, setIsDocTypeSelected] = useState<boolean>(false);
  const { setAlertMsg } = useAlertMessage()
  const { user } = useAppSelector((state) => state.auth);

  // const { login } = useAppContext()
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleClick = async () => {
    setActiveStep((prev) => prev + 1);
    dispatch(updateOnBoardingStep(3));

    const body: any = {
      userId: user?.id,
      onboardingStep: 3,
      file: file,
    };

    let formData = new FormData();
    Object.keys(body).forEach((data: string, key: number) => {
      formData.append(data, body[data]);
    });
    const { data, error }: any = await updateUser(body);
  };
  const toggle = () => setIsOpen(!isOpen);
  const toggleApprove = () => {
    setIsDocNotApprove(!isDocNotApprove);
  };

  const handleUpload = () => {
    setIsOpen(true);
  };

  const countryList = [
    { id: 1, value: "India" },
    { id: 2, value: "USA" },
    { id: 3, value: "UK" },
  ];
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prev) => {
      return {
        ...prev,
        country: e.target.value,
      };
    });
  };

  const handleContinue = async () => {
    const body: any = {
      userId: user?.id,
      onboardingStep: 3,
      file: file,
    };

    // const file: string = payload.file;

    let formData = new FormData();

    Object.keys(body).forEach((data: string, key: number) => {
      formData.append(data, body[data]);
    });

    const { data, error }: any = await updateUser(body);
    if (data) {
    }
    if (error) {
      console.log(error, "error");
    }

    setModalStep("UPLOAD");
  };

  const handleClickIdType = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsDocTypeSelected(true);
  };

  type OptionType = {
    value: string;
    label: string;
  };

  const options: OptionType[] = [
    { value: "India", label: "India" },
    { value: "USA", label: "USA" },
    { value: "UK", label: "UK" },
  ];

  const ChooseDoc = () => {
    // const [selectedOption, setSelectedOption] = useState<ValueType<OptionType>>(options[0]);

    // const handleChange = (option: ValueType<OptionType>) => {
    //   setSelectedOption(option);
    // };

    return (
      <>
        <h2 className={styles.modalTitle}>Identity photo</h2>
        <SelectBox
          id="country"
          placeholder="Select country"
          label="Select country that issued your document"
          data={countryList}
          value={state.country}
          onChange={onChange}
        />

        {/* <ReactSelectBox
          options={options}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          handleChange={handleChange}
        /> */}

        <p className={styles.span}>Choose your document type</p>
        <div className={styles.radioBtn}>
          <div
            className={styles.radioWrapper}
            onClick={() => setIsDocTypeSelected(true)}
          >
            <input type="radio" id="idCard" name="doc" value={state.idType} />
            <span>ID Card</span>
          </div>
          <div
            className={styles.radioWrapper}
            onClick={() => setIsDocTypeSelected(true)}
          >
            <input type="radio" id="idCard" name="doc" value={state.idType} />
            <span>Passport</span>
          </div>
          <div
            className={styles.radioWrapper}
            onClick={() => setIsDocTypeSelected(true)}
          >
            <input type="radio" id="idCard" name="doc" value={state.idType} />
            <span>Drivers license</span>
          </div>
        </div>
        <div className={styles.continue}>
          <CustomButton
            onClick={handleContinue} // !isDocSelected || isSelfieSelected || !isAgree
            disabled={
              state.country ? false : isDocTypeSelected === false ? false : true
            }
          // disabled={!state.country || isDocTypeSelected}
          >
            Continue
          </CustomButton>
        </div>
      </>
    );
  };
  const getModalUI = (val: string) => {
    switch (val) {
      case "DOC":
        return <ChooseDoc />;
      case "UPLOAD":
        return (
          <UploadDoc
            title="Take a photo of your document"
            desc="Take a photo of the front side of the document"
            file={file}
            setFile={setFile}
            setIsDocNotApprove={setIsDocNotApprove}
            isDocNotApprove={isDocNotApprove}
            toggle={toggle}
          />
        );
      case "SELFIE":
        return (
          <UploadDoc
            title="Take a selfie"
            desc="Look straight into the camera"
            file={file}
            setFile={setFile}
            setIsDocNotApprove={setIsDocNotApprove}
            isDocNotApprove={isDocNotApprove}
            toggle={toggle}
          />
        );
    }
  };
  const handleClose = () => {
    setIsOpen(false);
    setIsDocApprove(false);
    setIsDocNotApprove(false);
    setModalStep("DOC");
    setFile(null);
    if (modalStep === "SELFIE") {
      setIsSelfieSelected(true);
    } else {
      setIsDocSelected(true);
    }
  };
  const skipHandle = async () => {
    const payload = {
      id: user?.id,
      isOnboardingSkipped: true,
    };
    const { data, error } = (await skipOnBoarding(payload)) as SkipStep;
    if (data) {
      dispatch(changeSkipStatus(true));
      navigate("/", { replace: true });
    }
    if (error) {
      setAlertMsg?.({
        type: "error",
        msg: error.message
      })
    }
  };

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setIsAgree(e?.target.checked);
  };
  return (
    <div className={styles.identity}>
      <div className={styles.skip}>
        <h2>Confirm your identity</h2>
        {actions && (
          <h5 onClick={skipHandle}>
            {isLoading ? "Loading..." : "Skip for now"}
          </h5>
        )}
      </div>

      <SumsubSdk
        setIsSelfieSelected={setIsSelfieSelected}
        setSumsumAppId={setSumsumAppId}
        stepCompletedCB={stepCompletedCB}
      />

      {/* <div className={styles.boxWrapper}>
        <div className={styles.topBox}>
          <div className={styles.boxLeft}>
            <h6>STEP 1</h6>
            <h3>UPLOAD YOUR DOCUMENT</h3>
            <p>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium
            </p>
          </div>
          {isDocSelected ? (
            <div className={styles.boxRightSuccess}>
              <CustomButton
                className={styles.successBtn}
                variant="outlined"
                startIcon={<Tick />}
              >
                Success
              </CustomButton>
            </div>
          ) : (
            <div className={styles.boxRight}>
              <CustomButton
                className={styles.btn}
                onClick={() => {
                  setModalStep("DOC");
                  handleUpload();
                }}
              >
                Upload A Photo
              </CustomButton>
              <p>or</p>
              <CustomButton
                className={styles.btn}
                onClick={() => {
                  setModalStep("DOC");
                  handleUpload();
                }}
              >
                Take A Photo
              </CustomButton>
            </div>
          )}
        </div>
        <hr />
        <div className={styles.bottomBox}>
          <div className={styles.bottomLeft}>
            <p>The photo should be:</p>
            <ul>
              <li>bright and clear (good quality)</li>
              <li>uncut (all corners should be visible)</li>
            </ul>
          </div>
          <div className={styles.bottomRight}>
            <div className={styles.passport}>
              <Passport className={styles.passIcon1} />
              <Check className={styles.icon} />
            </div>
            <div className={styles.passport}>
              <Passport className={styles.passIcon2} />
              <Cross className={styles.icon} />
            </div>
            <div className={styles.passport}>
              <Passport className={styles.passIcon3} />
              <Cross className={styles.icon} />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.boxWrapper}> 
        <div className={styles.topBox}>
          <div className={styles.boxLeft}>
            <h6>STEP 2</h6>
            <h3>TAKE A SELFIE</h3>
            <p>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium
            </p>
          </div>
          <div className={styles.boxRight}>
            {isSelfieSelected ? (
              <div className={styles.boxRightSuccess}>
                <CustomButton
                  className={styles.successBtn}
                  variant="outlined"
                  startIcon={<Tick />}
                >
                  Success
                </CustomButton>
              </div>
            ) : (
              <CustomButton
                className={styles.btn}
                onClick={() => {
                  setModalStep("SELFIE");
                  handleUpload();
                }}
              >
                Take A Selfie
              </CustomButton>
            )}
          </div>
        </div>
      </div> */}

      {isSelfieSelected && (
        <>
          <div className={styles.agree}>
            <div>
              <Input
                onChange={handleCheckbox}
                type="checkbox"
                id="approve"
                name="approve"
                placeholder=""
                value={isAgree ? "true" : "false"}
                className={styles.personalData}
              />
            </div>
            <label htmlFor="approve">
              I agree to the processing of my personal data
            </label>
          </div>

          <div className={styles.btnContainer}>
            <CustomButton
              className={styles.btnParent}
              variant="outlined"
              onClick={() => setActiveStep((prev) => prev - 1)}
              disabled={user?.onboardingStep !== 0}
            >
              Back
            </CustomButton>
            <CustomButton
              className={styles.btnParent}
              onClick={handleClick}
              disabled={!isSelfieSelected || !isAgree}
            >
              Continue
            </CustomButton>
          </div>
          <div className={styles.skipMobile}>
            <h5 className={styles.skipText} onClick={skipHandle}>
              {isLoading ? "Loading..." : "Skip for now"}
            </h5>
          </div>
        </>
      )}

      <CustomModal isOpen={isOpen} toggle={toggle}>
        <div className={styles.modal}>{getModalUI(modalStep)}</div>
      </CustomModal>

      <CustomModal isOpen={isDocNotApprove} toggle={toggleApprove}>
        <div className={styles.approveModal}>
          <WarningIcon />
          <h2>
            {modalStep === "SELFIE"
              ? "Unfortunantly, we can't accept this selfie"
              : "Unfortunantly, we can't accept this document"}
          </h2>
          <p>
            Information from your document cannot be read. Make sure that data
            is visible and clear. Also ensure that you selected the right
            document type and country.
          </p>
          <div className={styles.btnContainer2}>
            <CustomButton variant="outlined">Restart</CustomButton>
            <CustomButton
              onClick={() => {
                toggleApprove();
                setIsDocApprove(true);
              }}
            >
              Try Again
            </CustomButton>
          </div>
        </div>
      </CustomModal>

      <CustomModal isOpen={isDocApprove} toggle={toggleApprove}>
        <div className={styles.approveModal}>
          <Done />
          <h2>
            {modalStep === "SELFIE"
              ? "Your selfie was approved"
              : "Your photo was approved"}
          </h2>
          <p>
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature
          </p>
          <div className={styles.continue}>
            <CustomButton className={styles.width220} onClick={handleClose}>
              Continue
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default IdentityVerification;
