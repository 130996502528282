import copy from "copy-to-clipboard";
import { ReactComponent as InputIcon } from "../../../assets/icons/inputIcon.svg";
import { LoyaltyIllustration, bannerImg } from "../../../assets/index";
import { ReactComponent as Chat } from "../../../assets/staking-page-icons/chat.svg";
import { ReactComponent as Google } from "../../../assets/staking-page-icons/google.svg";
import { ReactComponent as Message } from "../../../assets/staking-page-icons/message.svg";
import { ReactComponent as One } from "../../../assets/staking-page-icons/one.svg";
import { ReactComponent as Rating } from "../../../assets/staking-page-icons/rating.svg";
import { ReactComponent as RightArrow } from "../../../assets/staking-page-icons/right-right.svg";
import { ReactComponent as Team } from "../../../assets/staking-page-icons/team.svg";
import { ReactComponent as Three } from "../../../assets/staking-page-icons/three.svg";
import { ReactComponent as Twitter } from "../../../assets/staking-page-icons/twitter.svg";
import { ReactComponent as Two } from "../../../assets/staking-page-icons/two.svg";
import { useAlertMessage } from "../../../context/AlertContext";
import Banner from "../../Banner";
import Dashboardtitle from "../../DashbordTitle";
import DataTables from "../../DataTables";
import HowItwork from "../../HowItwork";
import Input from "../../Input";
import RatingCard from "../../RatingCard";
import LoyaltyStepper from "../../loyaltyStepper";
import style from "./index.module.scss";

type Columns = {
  name: string;
  selector: (row: any) => string;
  sortable?: boolean;
  style?: {};
};

const contentStyle = {
  fontWeight: 700,
  fontSize: 14,
  letterSpacing: "0.02em",
  color: "#1B4458",
};

const contentNumber = {
  fontWeight: 700,
  fontSize: 14,
  letterSpacing: "0.02em",
  color: "#1B4458",
};

const columns: Columns[] = [
  {
    name: "Loyalty lvl",
    selector: (row) => row.id,
    style: contentStyle,
  },
  {
    name: "Staking APY",
    selector: (row) => row.item1,
    style: contentStyle,
  },
  {
    name: "Investment pre token launch",
    selector: (row) => row.item2,
    style: contentStyle,
  },
  {
    name: "NFT-reward",
    selector: (row) => row.item3,
    style: contentStyle,
  },
  {
    name: "Loyalty-points",
    selector: (row) => row.item4,
    style: contentNumber,
  },
];
const dummyData = [
  {
    id: 1,
    item1: "+0.15%",
    item2: "No",
    item3: "Wooden Neal",
    item4: "1,000",
  },
  {
    id: 2,
    item1: "+0.15%",
    item2: "No",
    item3: "Wooden Neal",
    item4: "1,000",
  },
  {
    id: 3,
    item1: "+0.15%",
    item2: "No",
    item3: "Wooden Neal",
    item4: "1,000",
  },
  {
    id: 4,
    item1: "+0.15%",
    item2: "No",
    item3: "Wooden Neal",
    item4: "1,000",
  },
  {
    id: 5,
    item1: "+0.15%",
    item2: "No",
    item3: "Wooden Neal",
    item4: "1,000",
  },
  {
    id: 6,
    item1: "+0.15%",
    item2: "No",
    item3: "Wooden Neal",
    item4: "1,000",
  },
  {
    id: 7,
    item1: "+0.15%",
    item2: "No",
    item3: "Wooden Neal",
    item4: "1,000",
  },
];
export default function LoyaltyProgram() {
  const { setAlertMsg } = useAlertMessage();
  function createData(
    name: any,
    calories: any,
    fat: any,
    carbs: any,
    protein: any
  ) {
    return { name, calories, fat, carbs, protein };
  }
  const copyToClipboard = (copyData: string) => {
    console.log(copyData, "copyData");
    copy(copyData);
    setAlertMsg?.({
      type: "success",
      msg: "Link copied to clipboard!",
    });
  };
  const link = "crypto.75way.com/?invite=user6726161re13";
  return (
    <div className={style.loyalty}>
      {/* <Dashboardtitle title={"Loyalty program"} /> */}
      {/* <Banner
        title="Stake your $NFTS"
        content="Unlock the full potential of your digital assets with our staking platform and start earning passive income right now! Staking your $NFTS Tokens unlocks an attractive APY to further your return opportunities and maximize your portfolio’s performance!"
        contentsec="And that’s not all! By staking any Indicator tokens, you’ll get $NEAL tokens, unlocking yet another layer of benefits such as governance for example!"
        src={bannerImg}
        alt="bannerImg"
        icon={<Illustration />}
      ></Banner> */}
      <Banner
        src={bannerImg}
        alt="bannerImg"
        title={"How to take part in loyalty program"}
        content={
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting."
        }
        icon={<LoyaltyIllustration />}
      />
      <div className={style.referFridnd}>
        <Dashboardtitle
          title={"Refer friends"}
          description={
            "Get 20$ in $NEAL if one of your friends invests in our index tokens:"
          }
        />
        <div className={style.Referral}>
          <div className={style.ReferralInput}>
            <Input
              className={style.referralInput}
              textColor={style.referralInputColor}
              placeholder={link}
              value={link}
              label={"Referral link"}
              type={"text"}
              id={"referral"}
              name={"referral"}
              disabled
            />
            <InputIcon onClick={() => copyToClipboard(link)} />
          </div>
          <p className={style.ReferralP}>
            *Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old.
          </p>
        </div>
      </div>
      <div className={style.referFridnd}>
        <Dashboardtitle
          title={"If you are loyal, we are loyal"}
          description={"Collect loyalty points and get higher staking APYs."}
        />
        <p className={style.rewards}>*Claim your rewards in Wallet / Rewards</p>
        {/* <StepperDesign /> */}
        <LoyaltyStepper />
      </div>
      <Dashboardtitle title={"How it works"} description={""} />
      <div className={style.gridsystem}>
        <HowItwork
          title={"SELECT REQUIREMENT"}
          content={"Select requirement from the list below"}
          icon={<One />}
        />
        <RightArrow className={style.rightIcon} />
        <HowItwork
          title={"SELECT REQUIREMENT"}
          content={"Select requirement from the list below"}
          icon={<Two />}
        />
        <RightArrow className={style.rightIcon} />
        <HowItwork
          title={"SELECT REQUIREMENT"}
          content={"Select requirement from the list below"}
          icon={<Three />}
        />
      </div>
      <Dashboardtitle
        title={"If you are loyal, we are loyal"}
        description={"Learn more about loyalty levels"}
      />
      <div className={style.dataTable}>
        <DataTables columns={columns} data={dummyData} />
      </div>
      <Dashboardtitle
        className={style.receiveloyalty}
        title={"How to receive loyalty points"}
        description={
          "In order to participate in loyalty program follow the below requirements:"
        }
      />
      <div className={style.receiveloyaltyPoint}>
        <RatingCard
          text={"Trustpilot Rating"}
          visit={"Visit"}
          icon={<Rating />}
          points={"100 points"}
        />
        <RatingCard
          text={"Twitter post about Crypto"}
          visit={"Visit"}
          icon={<Twitter />}
          points={"20 points"}
        />
        <RatingCard
          text={"Google Rating"}
          visit={"Visit"}
          icon={<Google />}
          points={"100 points"}
        />
        <RatingCard
          text={"Discord member"}
          icon={<Chat />}
          points={"100 points"}
        />
        <RatingCard
          icon={<Team />}
          text={"Registered Friends Referral"}
          points={"150 points"}
          // visit={"visit"}
        />
        <RatingCard
          points={"100 points"}
          // visit={"visit"}
          text={"Discord member"}
          icon={<Message />}
        />
      </div>
    </div>
  );
}
