import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { SampleTokenSVG } from "../../assets";
import CustomButton from "../CustomButton";
import style from "./index.module.scss";
type Props = {
  className: string;
  buttonText?: string;
};

export default function NFTSToken(Props: Props) {
  const Navigate = useNavigate();
  const { className, buttonText } = Props;
  return (
    <div className={clsx(style.token, className)}>
      <div className={style.tokenIcon}>
        <div className={style.tokenBg}>
          {/* <img src={coinSample} /> */}
          <SampleTokenSVG />
        </div>
        <h6>$NFTS TOKEN</h6>
      </div>
      <div className={style.amount}>
        <p>Total staked amount</p>
        <div>
          <strong>0 $NFTS</strong> <small>$80.200</small>
        </div>
      </div>
      <div className={style.amount}>
        <p>Staking APR</p>
        <strong>6.7%</strong>
      </div>
      <div className={style.amount}>
        <p>Total rewards</p>
        <strong className={style.reward_value}>1.234,54 $NFTS</strong>
      </div>
      <div className={style.stakBtn}>
        <div className={style.Cusbtn}>
          <CustomButton
            className={style.stackbutton}
            onClick={() => Navigate("/staking-menu/stakingNFTpage")}
          >
            {buttonText}
          </CustomButton>
        </div>
        {/* <div className={style.img}>
          <img src={plus} />
        </div> */}
      </div>
    </div>
  );
}
