import CustomButton from "../../CustomButton";
import FlexBox from "../../common/FlexBox";
import { Amount } from "./Reward";
import styles from "./index.module.scss";

type Data = {
  id: number;
  type: string;
  points: string;
  amount: Amount;
};
interface Props {
  data: {
    id: number;
    type: string;
    points: string;
    amount: {
      min: string;
      max: string;
    };
  }[];
}

type Item = {
  item: {
    id: number;
    type: string;
    points: string;
    amount: {
      min: string;
      max: string;
    };
  };
};
const RewardMobile = ({ data }: Props) => {
  return (
    <div>
      {data.map((item, i) => (
        <StakRow item={item} key={i} />
      ))}
    </div>
  );
};
const StakRow = ({ item }: Item) => {
  return (
    <div className={styles.stakRow}>
      <FlexBox className={styles.flex}>
        <div className={styles.cell}>
          <p>Mission</p>
          <h5>{item.type}</h5>
        </div>
        <div className={styles.cell}>
          <p>Points</p>
          <h5>{item.points}</h5>
        </div>
        <div className={styles.cell}>
          <p>Amount</p>
          <h5>{item.amount.min}$NEAL</h5>
          <span>${item.amount.max}</span>
        </div>
      </FlexBox>
      <CustomButton variant="outlined" className={styles.claimBtn}>
        Claim
      </CustomButton>
    </div>
  );
};
export default RewardMobile;
