import clsx from "clsx";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Down,
  Exchange,
  Redeem,
  Setting,
  Tokengraph,
  USDS,
  DepositeIcon,
  WithdrawIcon,
  AssetsCoinIcon,
} from "../../../assets/index";
import useWindowResize from "../../../hooks/useWindowResize";
import Card from "../../Card";
import CustomButton from "../../CustomButton";
import Dashboardtitle from "../../DashbordTitle";
import ProgressBar from "../../ProgressBar";
import style from "./index.module.scss";

type Amount = {
  type: string;
  isMax: boolean;
  coin: string;
};
export default function CRYTokenPage() {
  const navigate = useNavigate();

  const [progress, setProgress] = React.useState(50);
  const { width } = useWindowResize();
  const breakpoint = 576;

  const tokendata = [
    {
      id: "1",
      icon: <AssetsCoinIcon />,
      icontext: "USDC",
      market: "$294.022.480",
      volume: "294.022.480",
      progress: <ProgressBar value={progress} />,
    },
    {
      id: "1",
      icon: <AssetsCoinIcon />,
      icontext: "USDC",
      market: "$294.022.480",
      volume: "294.022.480",
      progress: <ProgressBar value={progress} />,
    },
    {
      id: "1",
      icon: <AssetsCoinIcon />,
      icontext: "USDC",
      market: "$294.022.480",
      volume: "294.022.480",
      progress: <ProgressBar value={progress} />,
    },
    {
      id: "1",
      icon: <AssetsCoinIcon />,
      icontext: "USDC",
      market: "$294.022.480",
      volume: "294.022.480",
      progress: <ProgressBar value={progress} />,
    },
    {
      id: "1",
      icon: <AssetsCoinIcon />,
      icontext: "USDC",
      market: "$294.022.480",
      volume: "294.022.480",
      progress: <ProgressBar value={progress} />,
    },
    {
      id: "1",
      icon: <AssetsCoinIcon />,
      icontext: "USDC",
      market: "$294.022.480",
      volume: "294.022.480",
      progress: <ProgressBar value={progress} />,
    },
    {
      id: "1",
      icon: <AssetsCoinIcon />,
      icontext: "USDC",
      market: "$294.022.480",
      volume: "294.022.480",
      progress: <ProgressBar value={progress} />,
    },
    {
      id: "1",
      icon: <AssetsCoinIcon />,
      icontext: "USDC",
      market: "$294.022.480",
      volume: "294.022.480",
      progress: <ProgressBar value={progress} />,
    },
    {
      id: "1",
      icon: <AssetsCoinIcon />,
      icontext: "USDC",
      market: "$294.022.480",
      volume: "294.022.480",
      progress: <ProgressBar value={progress} />,
    },
    {
      id: "1",
      icon: <AssetsCoinIcon />,
      icontext: "USDC",
      market: "$294.022.480",
      volume: "294.022.480",
      progress: <ProgressBar value={progress} />,
    },
    {
      id: "1",
      icon: <AssetsCoinIcon />,
      icontext: "USDC",
      market: "$294.022.480",
      volume: "294.022.480",
      progress: <ProgressBar value={progress} />,
    },
  ];
  return (
    <div className={style.nftsTokenpage}>
      <div className={style.graphSection}>
        <Tokengraph className={style.graphImg} />
      </div>
      <div className={style.depositeSection}>
        <div className={style.redeemYour}>
          <div className={style.redeepIcon}>
            <WithdrawIcon />
          </div>
          <h4 className={style.redeemTitle}>SWAP YOUR STABLE TOKEN</h4>
        </div>
        {/* <div className={style.redeemYour}>
          <div className={style.redeepIcon}>
            <DepositeIcon />
          </div>
          <h4 className={style.redeemTitle}>DEPOSIT YOUR NFT</h4>
        </div> */}
      </div>

      <div className={style.wrapper}>
        <div className={style.cardLeft}>
          <div className={style.innerCard}>
            <Card>
              <div className={style.statContainer}>
                <h2 className={style.stats}>Stats</h2>
                <div className={style.statDEtail}>
                  <div className={style.statItem}>
                    <p className={style.marketCap}>Market Cap</p>
                    <h3 className={style.calValue}>$685.14K</h3>
                  </div>
                  <div className={style.statItem}>
                    <p>Volume(24h)</p>
                    <h3>$65.14K</h3>
                  </div>
                  <div className={style.statItem}>
                    <p>Current Supply</p>
                    <h3>$685.14K</h3>
                  </div>
                  <div className={style.statItem}>
                    <p>Streaming Fee</p>
                    <h3>$685.14K</h3>
                  </div>
                  <div className={style.statItem}>
                    <p>Mint Fee</p>
                    <h3>$685.14K</h3>
                  </div>
                  <div className={style.statItem}>
                    <p>Redeem Fee</p>
                    <h3>$685.14K</h3>
                  </div>
                  <div className={style.statItem}>
                    <p>NAV</p>
                    <h3>$685.14K</h3>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className={style.innerCard}>
            <div className={style.innerCardAllocation}>
              <Dashboardtitle
                className={style.allocationsTitle}
                title="Top tokens"
              />
              {width < breakpoint ? (
                ""
              ) : (
                <div className={style.allocationHeader}>
                  <div
                    className={clsx(style.allocationHeaderHead, style.poshead)}
                  >
                    <p className={style.posName}>Pos</p>
                  </div>
                  <div
                    className={clsx(style.allocationHeaderHead, style.namehead)}
                  >
                    <p>Name</p>
                  </div>
                  <div
                    className={clsx(
                      style.allocationHeaderHead,
                      style.capitalizationhead
                    )}
                  >
                    <p>Market capitalization</p>
                  </div>
                  <div
                    className={clsx(
                      style.allocationHeaderHead,
                      style.volumehead
                    )}
                  >
                    <p>Volume (24hr)</p>
                  </div>
                  <div
                    className={clsx(
                      style.allocationHeaderHead,
                      style.percentagehead
                    )}
                  >
                    <p>Percentage of token in nSTBL</p>
                  </div>
                </div>
              )}
            </div>
            <Card>
              <div>
                {tokendata.map((tokenItems, index) => {
                  return (
                    <div className={style.topsectionToken} key={index}>
                      <div className={style.serialNo}>
                        {width < breakpoint ? (
                          <p className={style.posName}>Pos</p>
                        ) : (
                          ""
                        )}
                        {tokenItems.id}
                      </div>
                      <div className={style.usdsIncon}>
                        {width < breakpoint ? (
                          <p className={style.posName}>Name</p>
                        ) : (
                          ""
                        )}
                        <div>{tokenItems.icon}</div>
                        <h5 className={style.usdctile}>
                          {tokenItems.icontext}
                        </h5>
                      </div>
                      <div className={style.marketValue}>
                        {width < breakpoint ? (
                          <p className={style.posName}>Market capitalization</p>
                        ) : (
                          ""
                        )}
                        {tokenItems.market}
                      </div>
                      <div className={style.marketValue}>
                        {width < breakpoint ? (
                          <p className={style.posName}>Volume (24hr)</p>
                        ) : (
                          ""
                        )}
                        {tokenItems.volume}
                      </div>
                      <div className={style.percentage}>
                        {width < breakpoint ? (
                          <p className={style.posName}>
                            Percentage of token in nSTBL
                          </p>
                        ) : (
                          ""
                        )}
                        {tokenItems.progress}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Card>
          </div>
        </div>

        <div className={style.cardRight}>
          <Card>
            <div>
              <div className={style.swapContainer}>
                <h2 className={style.swapTitle}>Swap</h2>
                <Setting />
              </div>
              <div className={style.coinDetail}>
                <Amount type="From" isMax={true} coin="ETH" />
                <Exchange className={style.exchangeIcon} />
                <Amount type="To" isMax={false} coin="NFTS" />
              </div>
              <CustomButton
                className={style.btn}
                onClick={() => navigate("/stblAmount")}
              >
                Insufficient Balance
              </CustomButton>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
const Amount = ({ type, isMax, coin }: Amount) => {
  return (
    <div className={style.amountContainer}>
      <div className={style.amountTop}>
        <h6 className={style.amountTitle}>{type}</h6>
        <p>Balance: 0.0023,7</p>
      </div>
      <div className={style.amountBottom}>
        <h3>0.00</h3>
        <div className={style.amountBottomRight}>
          {isMax && (
            <div className={style.max}>
              <span>Max</span>
            </div>
          )}
          <div className={style.coin}>
            <p>{coin}</p>
            <Down />
          </div>
        </div>
      </div>
    </div>
  );
};
