import clsx from "clsx";
import { ReactElement, ReactNode } from "react";
import style from "./index.module.scss";
type Props = {
  alt?: string;
  title?: string;
  content?: string;
  contentsec?: string;
  className?: string;
  children?: ReactNode;
  src: string;
  icon?: ReactElement;
};

export default function Banner(Props: Props) {
  const { src, alt, title, content, contentsec, className, children, icon } =
    Props;
  return (
    <div className={clsx(style.stakeYour, className)}>
      <div className={style.stakeYourText}>
        <h2>{title}</h2>
        <p>{content}</p>
        <p className={style.contentsec}>{contentsec}</p>
        <div className={style.child_LG}>{children}</div>
      </div>
      <div className={style.stakeYourImg}>
        {/* if src is available then do something */}
        {src ? <img src={src} alt={alt} /> : icon}
        {/* <WatchVideoImg /> */}

        <div className={style.child_SM}>{children}</div>
      </div>
    </div>
  );
}
