import clsx from "clsx";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Graphs, coinIcon1, coinIcon4 } from "../../../assets/index";
import CustomButton from "../../CustomButton";
import Dashboardtitle from "../../DashbordTitle";
import CustomModal from "../../common/CustomModal";
import FlexBox from "../../common/FlexBox";
import style from "./index.module.scss";

export default function Overview() {
  const [buyToken, setBuyToken] = useState(false);
  const indicatorToken = () => {
    setBuyToken((buyToken) => !buyToken);
  };
  return (
    <div className={style.overview}>
      <Dashboardtitle description="As a novel approach for straightforward, easy investing, each one of On-chain Index Tokens act as a basket containing carefully selected assets from a specific market. Buying one Index Token means buying a portion of all the assets it represents, which in turn means efficient portfolio diversification and higher potential returns." />
      <Dashboardtitle
        className={style.interested}
        description="If you’re interested and would like to learn more, make sure to check out all our available ressources regarding Index Tokens."
      />
      <Dashboardtitle
        className={style.overviewTitle}
        description=""
        title="Buy & sell our Indicator Tokens"
      />

      <FlexBox className={style.indicatorCard}>
        <div className={style.imgSection}>
          <img className={style.img} src={coinIcon1} alt="tok1" />
        </div>
        <div className={style.tokenDetails}>
          <Dashboardtitle
            className={style.tokenTitle}
            title="$nSTBL TOKEN"
            description="$nSTBL focuses on the 10 top trading assets on the cryptocurrency market."
          />
          <p className={style.current}>Current price</p>
          <div className={style.tokenValue}>
            $ 1,474.91 <span className={style.toeknprice}>4.48% APY</span>
          </div>
          <div className={style.updateValue}>
            <span className={style.spanvalue}>+$35.06</span>
            <span className={style.spanvalue}>(+2.14%)</span>
          </div>
          <div className={style.buyTokenSec}>
            <p className={style.currentt}>
              Market capitalization{" "}
              <strong className={style.capitalization}>$237.467,02</strong>
            </p>
            <CustomButton onClick={indicatorToken} className={style.buybutton}>
              Buy Token
            </CustomButton>
          </div>
          <CustomModal isOpen={buyToken} toggle={indicatorToken}>
            <Dashboardtitle title="How to buy an Index Token?" />
            <p className={style.tokenDescription}>
              At the top of the Buy/Sell interface, make sure you selected
              “Buy”.
            </p>
            <p className={style.tokenDescription}>
              1) In the “From” box enter the{" "}
              <strong>amount of currency you want to spend on the left</strong>,
              and{" "}
              <strong>
                {" "}
                select the currency you want to spend on the right
              </strong>{" "}
              in the drop-down menu. You can also click on “
              <strong className={style.max}> Max</strong> ” to instantly input
              the maximum amount of currency you have in your possession. For
              example, if you have a total of 100 $ETH and click “
              <strong className={style.max}> Max</strong> ”, it will input 100
              as amount to be spent.
            </p>
            <p className={style.tokenDescription}>
              2) In the “To” second box{" "}
              <strong>
                the amount of currency you’ll receive will appear on the left
              </strong>{" "}
              , and
              <strong>
                you can select which currency you want to purchase on the right
              </strong>{" "}
              in the drop-down menu.
            </p>
            <p className={style.tokenDescription}>
              3) Once these steps are complete, a third box will appear with
              informations regarding your transaction. Makes sure to review them
              before confirming!
            </p>
            <p className={style.tokenDescription}>
              4) Once you are happy with the numbers, click the “Confirm”
              button, review the transaction in your wallet and confirm again
              within your wallet for the transaction to go through.
            </p>
            <p className={style.tokenDescription}>
              5) Enjoy your newly acquired Crypto Index Token and all its
              benefits!
            </p>
            <p className={style.tokenDescription}>
              Note: If you do not confirm the transaction within your wallet,
              the transaction will not go through even if you clicked “Confirm”
              on our interface!
            </p>
            <div className={style.cancelBtnContainer}>
              <CustomButton
                className={style.cancelBtn}
                onClick={indicatorToken}
              >
                Got it!
              </CustomButton>
            </div>
          </CustomModal>
        </div>
        <div className={style.rightBorder}></div>
        <Graphs className={style.graph} />
      </FlexBox>
      <FlexBox
        // className={style.indicatorCard}
        className={clsx(style.comingSoonCard, style.indicatorCard)}
      >
        <div className={style.imgSection}>
          <img className={style.img} src={coinIcon4} alt="tok4" />
        </div>
        <div className={style.comingSoonText}>
          <Dashboardtitle
            className={style.gameTitle}
            title="$NFTS TOKEN"
            description="$NFTS focuses on the 10 top trading assets on the NFT market."
          />
          <p className={style.comingtext}>
            <span>Coming Soon</span>
          </p>
        </div>
      </FlexBox>
      <div className={style.quicklinks}>
        <Dashboardtitle title="Quick links" className={style.quickTitle} />
        <Link className={style.quicklink} to="/">
          Saving plans details
        </Link>
        <Link className={style.quicklink} to="/">
          How to buy an Index Token?
        </Link>
      </div>
    </div>
  );
}
