import clsx from "clsx";
import { ReactComponent as Back } from "../../assets/icons/back-icon.svg";
import styles from "./index.module.scss";
type Props = {
  className?: string;
  onClick?: () => void;
};

export default function BackButton(Props: Props) {
  const { onClick, className } = Props;
  return (
    <button className={clsx(styles.backBtn, className)} onClick={onClick}>
      <Back />
      Back
    </button>
  );
}
