import clsx from "clsx";
import styles from "./index.module.scss";
type Props = {
  label: string;
  id?: string;
  className?: string;
  name?: string | undefined;
  onChange?: (event: any) => void;
  value?: string;
};
export default function RadioBtn(props: Props) {
  const { label, id, className, name, onChange, value } = props;
  return (
    <div className={clsx(styles.RadioBtn, className)}>
      <input
        type="radio"
        id={id}
        name={name}
        onChange={onChange}
        value={value}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}
