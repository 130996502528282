import AboutDeposit from "../AboutDeposit";
import FundLimit from "../FundLimit";
import styles from "./index.module.scss";

type Props = {
  type: string;
};
const DepositInfo = (props: Props) => {
  const { type } = props;
  return (
    <div className={styles.info}>
      <AboutDeposit type={type} />
      <FundLimit title="Funding Limits" type={type} />
    </div>
  );
};

export default DepositInfo;
