import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Euro } from "../../assets/icons/euro.svg";
import { ReactComponent as Group } from "../../assets/icons/Group.svg";
import { ReactComponent as MenuArrow } from "../../assets/icons/menu-arrow.svg";
import CustomButton from "../CustomButton";
import CustomLink from "../CustomLink";
import Dashboardtitle from "../DashbordTitle";
import style from "./index.module.scss";

export default function ConfirmOrder() {
  return (
    <div className={style.summary}>
      <Dashboardtitle title={"Confirm order"} description={""} />
      <div className={style.eurBox}>
        <div className={style.arrow}>
          <MenuArrow />
        </div>
        <div className={style.eur}>
          <h5>
            {" "}
            <Euro />
            EUR
          </h5>
          <p>1000.00</p>
        </div>
        <div className={style.eth}>
          <h5>
            <Group />
            ETH
          </h5>
          <p>0.036621</p>
        </div>
      </div>
      <div className={style.orderSummary}>
        <h4>Order summary</h4>
        <div className={style.orderList}>
          <p>Payment method</p>
          <strong>Visa **** 9900</strong>
          <p>Price</p>
          <strong>1 ETH = 100.01 EUR </strong>
          <p>You receive</p>
          <strong>0.036621 ETH</strong>
          <p>Fees</p>
          <strong>20 EUR </strong>
          <p>Total</p>
          <strong>1000.00,00 EUR</strong>
        </div>
      </div>

      <CustomLink
        className={style.orderLink}
        link={
          <div>
            I agree with the{" "}
            <Link className={style.term_link} to="">
              Terms
            </Link>{" "}
            and
            <Link className={style.term_link} to="">
              Privacy Policy
            </Link>
          </div>
        }
      />
      <div className={style.cryptoBtn}>
        <div className={style.btnChild}>
          <CustomButton variant={"outlined"}>Cancel</CustomButton>
        </div>
        <div className={style.btnChild}>
          <CustomButton variant={"primary"}>Confirm</CustomButton>
        </div>
      </div>
    </div>
  );
}
