import { useState } from "react";
import { AccordionData } from "../Accordion/type";
import { ReactComponent as Top } from "../../assets/staking-page-icons/top-direction.svg";
import { ReactComponent as Douwn } from "../../assets/staking-page-icons/douwn-direction.svg";
import Style from "../Accordion/index.module.scss";

function AccordionItem({ data }: { data: AccordionData }) {
  const [hide, setHide] = useState(false);
  const onhandleClick = () => {
    setHide((hide) => !hide);
  };
  return (
    <li className={Style.accordionIttem}>
      <h2 className="accordion-item-title">
        <button
          className={Style.accordionBtn}
          onClick={() => {
            onhandleClick();
          }}
        >
          {data.title}
          {hide ? <Top /> : <Douwn />}
        </button>
      </h2>
      {hide == true ? (
        <div className="accordion-item-container">
          <div className="accordion-item-content">{data.content}</div>
        </div>
      ) : (
        ""
      )}
    </li>
  );
}

export default AccordionItem;
