import { useNavigate } from "react-router-dom";
import { GoogleIcon } from "../assets";

import { useGoogleLogin } from "@react-oauth/google";
import { useAlertMessage } from "../context/AlertContext";
import { useLoginByGoogleMutation } from "../service/api";
import { useAppDispatch } from "../store/hooks";
import { loginUser } from "../store/reducers/authSlice";

type Props = {
  navigateTo?: string;
  classes?: {
    inputGroup?: string;
    button?: string;
  };
  affiliateCode?: string;
  register?: boolean;
};

export default function GoogleLogin(props: Props) {
  const { navigateTo, classes, affiliateCode, register } = props;
  const [loginWithGoogle] = useLoginByGoogleMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setAlertMsg } = useAlertMessage()
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      if (tokenResponse.access_token) {
        await login(tokenResponse.access_token);
      }
    },
  });

  const login = async (access_token: string) => {
    //@ts-ignore
    try {
      const { data } = await loginWithGoogle({
        access_token,
        affiliateCode: affiliateCode ?? "",
      }).unwrap();

      const { user, token, refreshToken } = data;
      if (user && user?.is2FAEnable) {
        const route = user.is2FAVerified ? "/2fa-auth" : "/2fa-qrcode";
        return navigate(route, {
          state: {
            email: user?.email,
            user,
          },
        });
      }

      if (user && token && refreshToken) {
        dispatch(
          loginUser({
            user: user,
            token: token,
            refreshToken: refreshToken,
          })
        );
        if (navigateTo) {
          navigate(navigateTo);
        }
      }
    } catch (error: any) {
      setAlertMsg?.({
        type: 'error',
        msg: error?.message || error?.data?.message || "Something went wrong!"
      })
    }
  };

  return (
    <div className={classes?.inputGroup}>
      <button
        type="button"
        className={classes?.button}
        onClick={() => googleLogin()}
      >
        {" "}
        <GoogleIcon /> Continue with Google
      </button>
    </div>
  );
}
