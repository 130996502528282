import clsx from "clsx";
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  ReactNode,
  forwardRef,
} from "react";
import { FieldError } from "react-hook-form";
import styles from "./index.module.scss";
interface Props {
  type: string;
  label?: string;
  placeholder: string;
  id: string;
  name: string;
  error?: FieldError | undefined;
  textCenter?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  noWidth?: boolean;
  value?: string | number;
  maxLength?: string;
  className?: string;
  textColor?: string;
  inputClassName?: string;
  max?: string;
  maxDate?: string;
  disabled?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement> | undefined;
  required?: boolean;
  checked?: boolean;
  icon?: ReactNode;
  info?: ReactNode;
  // inputRef?: React.RefObject<HTMLInputElement>|null,
}
// forwardRef<SideBarRef>((_, ref)
const Input = forwardRef((props: Props, ref) => {
  const {
    label,
    type,
    error,
    onChange,
    noWidth,
    className,
    inputClassName,
    textCenter,
    maxLength,
    maxDate,
    disabled,
    onKeyDown,
    onBlur,
    checked,
    required,
    icon,
    textColor,
    info,
    ...rest
  } = props;
  const handleEnter = (
    event: KeyboardEventHandler<HTMLInputElement>
  ): void => { };
  return (
    <div
      className={clsx(
        styles.inputContainer,
        className,
        noWidth ? styles.width0 : styles.width100
      )}
    >
      <label
        className={clsx(
          styles.labelText,
          !label && styles.displayNone,
          required && styles.star
        )}
        htmlFor={props.id}
      >
        {label}
        {info && <span className={styles.info}>{info}</span>}
      </label>
      <div
        className={clsx(
          styles.inputRadio,
          type !== "checkbox" && styles.margin8
        )}
      >
        {type === "textarea" ? (
          <textarea
            rows={6}
            className={clsx(
              styles.textarea,
              textCenter ? styles.text_center : styles.text_start,
              error ? styles.borderError : styles.borderNormal
            )}
            onChange={onChange}
            disabled={disabled}
            {...rest}
          ></textarea>
        ) : (
          <div className={clsx(type !== "checkbox" && styles.inputWrapper, inputClassName, error ? styles.borderError : styles.borderNormal)}>
            {icon}
            <input
              // ref={inputRef}
              type={type}
              onChange={onChange}
              onKeyDown={onKeyDown}
              checked={checked}
              max={maxDate}
              className={clsx(
                textCenter ? styles.text_center : styles.text_start,
                // error ? styles.borderError : styles.borderNormal,
                type === "date"
                  ? styles.datePlaceholder
                  : styles.inputPlaceholder,
                textColor
              )}
              disabled={disabled}
              onBlur={onBlur}
              {...rest}
            />
          </div>
        )}
      </div>
      <div className={clsx(styles.error, !error && styles.displayNone)}>
        {error?.message}
      </div>
    </div>
  );
});
export default Input;
