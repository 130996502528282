import { useNavigate } from "react-router";
import {
  bannerImg,
  Briefcase,
  Effect,
  One,
  RightIcon,
  SavingIcon,
  Three,
  Two,
  Diamound,
} from "../../../assets";
import schwein from "../../../assets/saving-plan-img/banner-img.png";
import Banner from "../../Banner";
import CardWithIcon from "../../CardWithIcon";
import FlexBox from "../../common/FlexBox";
import CustomButton from "../../CustomButton";
import Dashboardtitle from "../../DashbordTitle";
import HowItwork from "../../HowItwork";
import style from "./index.module.scss";

export default function ProductHelpCenter() {
  const navigate = useNavigate();
  return (
    <div className={style.productHelp}>
      <Banner
        title="What is an Indicator Token?"
        content="As a novel approach for straightforward, easy investing, each one of our Indicator Tokens acts as a basket containing carefully selected assets from a specific market. Buying one Indicator Token means buying a portion of all the assets it represents, which in turn means efficient portfolio diversification and higher potential returns."
        contentsec="And with Crypto’s magic, you can even enjoy the selected assets’ own benefits! "
        src={bannerImg}
        alt="bannerImg"
      />
      <CardWithIcon
        description="The process of researching individual cryptoassets can be time-consuming and often requires significant technical and financial knowledge. This can prove to be a challenge to some investors. Our Indicator Tokens allow investors to gain broad exposure to the crypto market or a specific subset of the market, simplifying the investment process for everyone."
        cardTitle="A simpler investing process"
        icon={<Diamound />}
      />
      <CardWithIcon
        description="If you are looking to build a diversified crypto portfolio that provides a large coverage of the market, our Indicator Tokens are the solution you’re looking for as you can do just that, in one transaction. Quick, simple, and efficient, we make sure that the only thing you really need to look at are your results."
        cardTitle="One-click diversification"
        icon={<Briefcase />}
      />
      <CardWithIcon
        description="Building a diversified portfolio can be costly. Between the time you must invest into doing your research and the fees inherent to the various chains, exchange fees, withdrawal fees, transfer fee, and so forth... You might find yourself in a disadvantageous position quickly. How great would it be to be able to minimize the fees for each individual asset your decide to purchase into one simple transaction on a unique platform instead? Rejoice, that’s exactly what we, at Crypto, offer you with our Indicator Tokens."
        cardTitle="Trading cost reduction"
        icon={<SavingIcon />}
      />
      <Dashboardtitle title={"Buying in 3 easy steps!"} description={""} />
      <div className={style.gridsystem}>
        <HowItwork
          title={"CONNECT YOUR WALLET..."}
          content={
            "...To the Crypto Platform and follow the in-wallet instructions."
          }
          icon={<One />}
        />
        <RightIcon className={style.rightIcon} />
        <HowItwork
          title={"CHOOSE YOUR INDICATOR..."}
          content={"...As well as the currency you want to spend and how much."}
          icon={<Two />}
        />
        <RightIcon className={style.rightIcon} />
        <HowItwork
          title={"CONFIRM YOUR BUY..."}
          content={
            "...On our Platform and in your wallet, and you’re good to go!"
          }
          icon={<Three />}
        />
      </div>
      <div className={style.stakeYour}>
        <div className={style.stakeYourText}>
          <Dashboardtitle
            title={"Want to take it one step further?"}
            description={
              "Unlock the potential of web3 investments with our innovative Auto-Invest system. Our platform offers a range of Indicator Tokens, carefully designed to give you exposure to different assets in the web3 space. Diversifying your investments across different assets can help you reduce risk and increase your chances of long-term success!"
            }
          />
          <p className={style.bottomContent}>
            By setting up an Auto-Invest, you can make regular, automated
            contributions to your investments and take advantage of the power of
            compounding. Plus, you can earn a passive income by staking our
            ecosystem token $NEAL, with an APY that increases the more $NEAL you
            have in your wallet!
          </p>
        </div>
        <div className={style.stakeYourImg}>
          <img src={schwein} alt="banner" />
        </div>
      </div>
      <FlexBox className={style.contentFlexBox}>
        <div className={style.investmentContent}>
          <p className={style.investPeragraph}>
            Don't miss out on the opportunity to build your wealth in the most
            exciting investment opportunity of our time, sign up now and start
            your journey to financial freedom!
          </p>
        </div>
        <CustomButton className={style.investmentButton}>
          See Investment Plans
        </CustomButton>
        {/* <CustomButton
          onClick={() => navigate("/helpCenter")}
          className={style.investmentButton}
        >
          Goto support
        </CustomButton> */}
      </FlexBox>
    </div>
  );
}
