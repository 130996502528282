import clsx from "clsx";
import React from "react";
import styles from "./index.module.scss";
type Props = {
  label: string;
  id?: string;
  description?: React.ReactElement;
  className?: string;
};
export default function Checkbox(props: Props) {
  const { label, id, description, className } = props;
  return (
    <div className={clsx(styles.checkbox, className)}>
      <input className={styles.customcheckbox} type="checkbox" id={id} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}
