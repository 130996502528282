import { useEffect, useState } from "react";
import { useSocket } from "../context/Socket";
import { useLazyGetDashboardChartQuery } from "../service/api";
import { events } from "../utils/constant";

export default function useDashboardGraph() {
  const { io } = useSocket();
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardChart, setDashboardChart] = useState<DashboardChatData[]>([]);
  const [filter, setFilter] = useState("");

  const [fetchTokens] = useLazyGetDashboardChartQuery();

  const getGraph = async (filter?: string) => {
    try {
      setIsLoading(true);
      const { data } = await fetchTokens(filter ? { filter } : {}).unwrap();
      setDashboardChart(data);
    } catch (error: any) {
      console.log(error?.data);
    } finally {
      setIsLoading(false);
    }
  };

  const applyFilter = async (filter: string) => {
    const newFilter = filter === "all" ? "" : filter;
    setFilter(newFilter);
    await getGraph(newFilter);
  };

  const syncTokensHandler = async () => {
    await getGraph(filter);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      io?.on(events.syncTokens, syncTokensHandler);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [io]);

  useEffect(() => {
    getGraph();
  }, []);

  return {
    dashboardChart,
    isLoading,
    applyFilter,
    filter,
  };
}
