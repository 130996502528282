import { ReactElement } from "react";
import { cls } from "react-image-crop";
import { ClockIcon } from "../../assets";
import styles from "./index.module.scss";

type Steps = {
  title: string;
  step: string | number;
  time: string;
  icon: ReactElement;
  className?: string;
};

const StepInfo = (props: Steps) => {
  const { title, step, time, icon, className } = props;
  return (
    <div className={cls(styles.container, className)}>
      <div className={styles.top}>
        <p>STEP {step}</p>
        <div className={styles.topRight}>
          <ClockIcon />
          <p>{time}min</p>
        </div>
      </div>
      <div className={styles.bottom}>
        <div>{icon}</div>
        <p>{title}</p>
      </div>
    </div>
  );
};

export default StepInfo;
