import clsx from "clsx";
import { tokenImg1, tokenImg2, tokenImg3 } from "../../../assets";
import Card from "../../Card";
import CustomButton from "../../CustomButton";
import styles from "./index.module.scss";

type BuySell = {
  item: {
    name: string;
    desc: string;
    currentPrice: string;
    cap: string;
    isAvailable: boolean;
  };
};
const data = [
  {
    name: "$NFTS TOKEN",
    desc: "Our $NFTS token focuses on the 10 biggest cryptocurrencies on the market. With every token you get the exact market equivalent.",
    currentPrice: "252.60",
    cap: "237,467,02",
    isAvailable: true,
  },
  {
    name: "$CRY10 TOKEN",
    desc: "Our $NFTS token focuses on the 10 biggest cryptocurrencies on the market. With every token you get the exact market equivalent.",
    currentPrice: "252.60",
    cap: "237,467,02",
    isAvailable: true,
  },
  {
    name: "$GAME TOKEN",
    desc: "Our $NFTS token focuses on the 10 biggest cryptocurrencies on the market. With every token you get the exact market equivalent.",
    currentPrice: "252.60",
    cap: "237,467,02",
    isAvailable: false,
  },
];
const IndexProduct = () => {
  return (
    <div className={styles.index}>
      <h2 className={styles.title}>Index products</h2>
      <div className={styles.indexContent}>
        <div className={styles.indexContentLeft}>
          <p>
            Index trackers are among the most popular investment vehicles among
            retail investors. Instead of trying to beat the market by selecting
            a handful of securities or assets, passively investing in a market’s
            index typically yields better returns.
          </p>
          <p>
            Read on to learn more about crypto index tokens and discover a list
            of leading index tokens you could add to your crypto portfolio.
          </p>
        </div>
        <div className={styles.indexContentRight}>
          <h3>Quick links</h3>
          <p>Index info</p>
          <p>How to buy Token?</p>
        </div>
      </div>
      <div className={styles.buySell}>
        <h2>Buy & sell our index Tokens</h2>
        <p>
          Read on to learn more about crypto index tokens and discover a list of
          leading index tokens you could add to your crypto portfolio.
        </p>
      </div>
      <div className={styles.cards}>
        {data.map((item) => (
          <BuySell item={item} key={item.name} />
        ))}
      </div>
    </div>
  );
};
const BuySell = ({ item }: BuySell) => {
  return (
    <div
      className={clsx(
        styles.buyContainer,
        !item.isAvailable && styles.disabled
      )}
    >
      <Card>
        <div className={styles.cardInner}>
          <img src={tokenImg1} />
          <div className={styles.buyContent}>
            <h3>{item.name}</h3>
            <p>{item.desc}</p>

            <div className={styles.rate}>
              <div className={styles.rateLeft}>
                <h6>Current price</h6>
                <h4>${item.currentPrice}</h4>
              </div>
              <div className={styles.divide}></div>
              <div className={styles.rateRight}>
                <h6>Market capitalization</h6>
                <h4>${item.cap}</h4>
              </div>
            </div>

            {item.isAvailable ? (
              <div className={styles.btnContainer}>
                <CustomButton variant="outlined">View More</CustomButton>
                <CustomButton>Buy Token</CustomButton>
              </div>
            ) : (
              <CustomButton variant="outlined">Comint Soon</CustomButton>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};
export default IndexProduct;
