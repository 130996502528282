import clsx from "clsx";
import CustomButton from "../CustomButton";
import FlexBox from "../common/FlexBox";
import styles from "./index.module.scss";

type Props = {
  sortValue: string;
  handleSortValue: (val: string) => void;
};
const SortByYear = (props: Props) => {
  const { sortValue, handleSortValue } = props;
  return (
    <div>
      <FlexBox className={styles.flexGap}>
        <CustomButton
          variant={sortValue === "24h" ? "primary" : "link"}
          className={clsx(
            styles.sort_btn,
            sortValue === "24h" && styles.bgColor
          )}
          onClick={() => handleSortValue("24h")}
        >
          24h
        </CustomButton>
        <CustomButton
          variant={sortValue === "1w" ? "primary" : "link"}
          className={clsx(
            styles.sort_btn,
            sortValue === "1w" && styles.bgColor
          )}
          onClick={() => handleSortValue("1w")}
        >
          1w
        </CustomButton>
        <CustomButton
          variant={sortValue === "1m" ? "primary" : "link"}
          className={clsx(
            styles.sort_btn,
            sortValue === "1m" && styles.bgColor
          )}
          onClick={() => handleSortValue("1m")}
        >
          1m
        </CustomButton>
        <CustomButton
          variant={sortValue === "1y" ? "primary" : "link"}
          className={clsx(
            styles.sort_btn,
            sortValue === "1y" && styles.bgColor
          )}
          onClick={() => handleSortValue("1y")}
        >
          1y
        </CustomButton>
        <CustomButton
          variant={sortValue === "all" ? "primary" : "link"}
          className={clsx(
            styles.sort_btn,
            sortValue === "all" && styles.bgColor
          )}
          onClick={() => handleSortValue("all")}
        >
          All
        </CustomButton>
      </FlexBox>
    </div>
  );
};

export default SortByYear;
