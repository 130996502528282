import clsx from "clsx";
import style from "./index.module.scss";
type Props = {
  title?: string;
  content?: string;
};
export default function Title(
  Props: Props & Omit<React.HTMLProps<HTMLButtonElement>, "type">
) {
  const { title, content, className } = Props;
  return (
    <div className={clsx(className)}>
      <h2 className={style.dashboardTitle}>{Props.title}</h2>
      <p className={style.content}>{content}</p>
    </div>
  );
}
