import { useLocation } from "react-router-dom";
import QRCode from "../../components/QRCode";
import BasicLayout from "../../layout/BasicLayout";

export default function TwoFAQrCode() {
  const {
    state: { email, user },
  } = useLocation();

  return (
    <BasicLayout>
      <QRCode email={email} user={user} />
    </BasicLayout>
  );
}
