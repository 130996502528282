import React from "react";
import style from "./index.module.scss";
type Amount = {
  type?: string;
  isMax?: boolean;
  coin?: string;
  startIcon?: React.ReactElement;
  douwntIcon?: React.ReactElement;
  amount?: number;
  balance?: any;
  isDropMenu?: boolean;
};
export default function Swap(props: Amount) {
  const {
    type,
    isMax,
    coin,
    startIcon,
    amount,
    balance,
    douwntIcon,
    isDropMenu,
  } = props;
  return (
    <div className={style.amountContainer}>
      <div className={style.amountTop}>
        <h6>{type}</h6>
        <p>{balance}</p>
      </div>
      <div className={style.amountBottom}>
        <h3>
          {startIcon} {amount}
        </h3>
        <div className={style.amountBottomRight}>
          {isMax && (
            <div className={style.max}>
              <span>Max</span>
            </div>
          )}
          {isDropMenu && (
            <div className={style.coin}>
              <p>{coin}</p>
              <span>{douwntIcon}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
