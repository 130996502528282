import clsx from "clsx";
import React from "react";
import style from "./index.module.scss";
type Props = {
  icon?: React.ReactElement;
  cardTitle?: string;
  description?: string;
  className?: string;
};
export default function CardWithIcon(Props: Props) {
  const { icon, cardTitle, description, className } = Props;
  return (
    <div className={clsx(style.cardsection, className)}>
      <div className={style.leftIcon}>{icon}</div>
      <div className={style.contentArea}>
        <h4 className={style.cardtitle}>{cardTitle}</h4>
        <p className={style.cardDescription}>{description}</p>
      </div>
    </div>
  );
}
