import clsx from "clsx";
import React from "react";
import CustomButton from "../CustomButton";
import styles from "./index.module.scss";

type Props = {
  title?: string;
  contnet?: string;
  buttonContent?: string;
  icon?: React.ReactElement;
  className?: string;
  onClick?: () => void;
};
export default function LearnMore(Props: Props) {
  const { title, contnet, icon, buttonContent, onClick, className } = Props;
  return (
    <div className={clsx(styles.LearnMore, className)}>
      <div className={styles.icon}>{icon}</div>
      <h2>{title}</h2>
      <p>{contnet}</p>
      <CustomButton onClick={onClick}>{buttonContent}</CustomButton>
    </div>
  );
}
