import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import clsx from "clsx";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import BackButton from "../../BackBtn";
import CustomButton from "../../CustomButton";
import AutoSetup from "../../CustomSavingPlanPages/AutoSetUp";
import CustomConfirm from "../../CustomSavingPlanPages/CustomConfirm";
import CustomPayment from "../../CustomSavingPlanPages/CustomPayment";
import style from "./index.module.scss";

const steps = ["Auto-Invest setup", "Payment", "Confirmation"];

export default function CustomSavinPlan() {
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const getStepperData = (step: number) => {
    switch (step) {
      case 0:
        return <AutoSetup />;
      case 1:
        return <CustomPayment />;
      case 2:
        return <CustomConfirm />;
    }
  };

  const navigate = useNavigate();
  return (
    <>
      <BackButton
        onClick={() => {
          navigate(-1);
        }}
      />
      <div className={style.customePlan}>
        <div className={style.investPlanStepper}>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={index}>
                    <StepLabel
                      style={{ whiteSpace: "nowrap" }}
                      StepIconProps={{
                        classes: {
                          active: style.avtiveIcon,
                          text: style.iconText,
                          completed: style.completed,
                        },
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep !== steps.length ? (
              <div className={style.investPlanDetails}>
                {getStepperData(activeStep)}
                {activeStep === 0 && (
                  <CustomButton
                    className={style.ConfirmContinuebutton}
                    onClick={() => {
                      handleNext();
                    }}
                  >
                    Confirm & Continue
                  </CustomButton>
                )}
                {activeStep === 1 && (
                  <CustomButton
                    onClick={() => {
                      handleNext();
                    }}
                    className={style.ConfirmContinuebutton}
                  >
                    Submit & Pay
                  </CustomButton>
                )}
                {/* <CustomButton
                  className={style.ConfirmContinuebutton}
                  onClick={handleNext}
                >
                  {activeStep === 0 && "Confirm & Continue"}
                  {activeStep === 1 && "Submit & Pay"}
                </CustomButton> */}
              </div>
            ) : (
              ""
            )}
          </Box>
        </div>
        {activeStep == 2 ? (
          <div
            className={clsx(style.investPlamSummery, style.investQuicllinks)}
          >
            <h4 className={style.QuickTitle}>Quick links</h4>
            <Link className={style.QuickLinks} to="/">
              How to change saving plan{" "}
            </Link>
            <Link className={style.QuickLinks} to="/">
              How to subscribe to another saving plan?
            </Link>
          </div>
        ) : (
          <div className={style.investPlamSummery}>
            <div className={style.investPlamSummeryinner}>
              <h4 className={style.tableTitle}>Setup Summary</h4>
              <table className={style.summeryTable}>
                <thead>
                  <tr>
                    <th>
                      <p className={style.Investing}>Auto-Invest</p>
                    </th>
                    <th>
                      <h6 className={style.blue}>$CRY10 TOKEN</h6>
                      <p className={style.InvestingTitle}>Single asset</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
