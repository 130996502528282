import { ClassNames } from "@emotion/react";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import style from "./index.module.scss";
type Props = {
  text?: string;
  icon?: React.ReactElement;
  endIcon?: React.ReactElement;
  values?: any;
  className?: string;
};
export default function StakingCard(Props: Props) {
  const { text, icon, values, className, endIcon } = Props;
  return (
    <div className={clsx(style.StakCard, className)}>
      <div className={style.icon}>{icon}</div>
      <div className={style.texts}>
        <p>{text}</p>
        <strong>{values}</strong>
      </div>
      <Link to="">{endIcon}</Link>
    </div>
  );
}
