import clsx from "clsx";
import { Link } from "react-router-dom";
import { ReactComponent as Exchangenft } from "../../assets/icons/exchangenft.svg";
import { ReactComponent as PipChart } from "../../assets/icons/pie-chart.svg";
import { ReactComponent as Piggy } from "../../assets/icons/piggy-bank.svg";
import { ReactComponent as Reward } from "../../assets/icons/reward.svg";
import { ReactComponent as Stakinggroup } from "../../assets/icons/stakinggroup.svg";
import { ReactComponent as Startup } from "../../assets/icons/startup.svg";
import Accordion from "../../components/Accordion/Accordion";
import Card from "../../components/Card";
import Dashboardtitle from "../../components/DashbordTitle";
import FlexBox from "../../components/common/FlexBox";
import OverviewWallet from "../../components/overviewWallet";
import { useAppSelector } from "../../store/hooks";
import style from "./index.module.scss";

export default function HelpCenter() {
  const { isKYCed } = useAppSelector((state) => state.auth);

  const accordionItems = [
    {
      title: "What is Crypto?",
      content: (
        <div>
          <p>
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
      ),
    },
    {
      title: "What is Crypto?",
      content: (
        <div>
          <p>
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
      ),
    },
    {
      title: "What is Crypto?",
      content: (
        <div>
          <p>
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
      ),
    },
    {
      title: "What is Crypto?",
      content: (
        <div>
          <p>
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
      ),
    },
    {
      title: "What is Crypto?",
      content: (
        <div>
          <p>
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
      ),
    },
    {
      title: "What is Crypto?",
      content: (
        <div>
          <p>
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
      ),
    },
  ];
  return (
    <div className={clsx(style.helpCenter)}>
      <Dashboardtitle title={"Help center"} />
      <FlexBox className={style.removeGap}>
        <Card>
          <OverviewWallet
            className={style.helpCenterBox}
            Icon={<Startup />}
            title={"Getting started"}
            description={
              <div>
                <Link to="/helpCenter/gettingStart">How to connect wallet</Link>
                <Link to="">How to buy crypto</Link>
                <Link to="">How to buy token</Link>
              </div>
            }
          />
        </Card>
        <Card>
          <OverviewWallet
            className={style.helpCenterBox}
            Icon={<Reward />}
            title={"Loyalty program"}
            description={
              <div>
                <Link to="">Airdrop</Link>
                <Link to="">Tier level</Link>
              </div>
            }
          />
        </Card>
        <Card>
          <OverviewWallet
            className={style.helpCenterBox}
            Icon={<Stakinggroup />}
            title={"Staking"}
            description={
              <div>
                <Link to="">How to stake</Link>
                <Link to="">How to buy token</Link>
              </div>
            }
          />
        </Card>
        <Card>
          <OverviewWallet
            className={style.helpCenterBox}
            Icon={<Exchangenft />}
            title={"Borrowing & Redemption"}
            description={
              <div>
                <Link to="">What is borrowing</Link>
                <Link to="">What is redemption</Link>
                <Link to="">How to buy token</Link>
              </div>
            }
          />
        </Card>
        <Card>
          <OverviewWallet
            className={style.helpCenterBox}
            Icon={<Piggy />}
            title={"Saving plan"}
            description={
              <div>
                <Link to="">How to choose saving plan</Link>
                <Link to="">About saving plan</Link>
              </div>
            }
          />
        </Card>
        <Card>
          <OverviewWallet
            className={style.helpCenterBox}
            Icon={<PipChart />}
            title={"Other topics"}
            description={
              <div>
                <Link to="">Subscribe for new token</Link>
                <Link to="">Take part in the Governance decision</Link>
              </div>
            }
          />
        </Card>
      </FlexBox>
      <Dashboardtitle title={"FAQ"} className={style.faqTitle} />
      <Accordion items={accordionItems} />
    </div>
  );
}
