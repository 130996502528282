import clsx from "clsx";
import { Navigate, useNavigate } from "react-router-dom";
import {
  ArchiveIcon,
  BillCheckIcon,
  CardReciveIcon,
  TestToken,
  graphStat,
} from "../../assets";
import Card from "../../components/Card";
import CustomButton from "../../components/CustomButton";
import HomeCard from "../../components/HomeCard";
import Title from "../../components/Title";
import FlexBox from "../../components/common/FlexBox";
import { useAppSelector } from "../../store/hooks";
import Blog from "../Blog";
import styles from "./index.module.scss";

export const homeCardData = [
  {
    id: 1,
    title: "DEPOSIT / WITHDRAWAL",
    description:
      "You already own some but you’d like to purchase more? We can help you!",
    icon: <BillCheckIcon />,
    btnText: "Buy / Sell Crypto tokens",
    type: "ACTIVE",
    to: "/token-buy-sell",
    toolTipText: "Buy /sell Crypto tokens",
  },
  {
    id: 2,
    title: "EARN",
    description:
      "Make the underlying assets work for you. Get more yield in a couple of clicks!",
    icon: <CardReciveIcon />,
    btnText: "Earn now!",
    type: "ACTIVE",
    to: "/staking-menu",
  },
  {
    id: 3,
    title: "TRADE",
    description:
      "Our Indicator Tokens are the best way to enjoy a lot of exciting assets, effortlessly!",
    icon: <ArchiveIcon />,
    btnText: "Coming soon",
    type: "COMING_SOON",
    to: "",
    toolTipText: "Coming soon",
  },
];

const Home = () => {
  const { isOnboardingSkipped, isOnboardingCompleted } = useAppSelector(
    (state) => state.auth
  );
  const navigate = useNavigate();

  if (!isOnboardingCompleted && !isOnboardingSkipped) {
    return <Navigate to="/boarding_details" />;
  }

  const onClikAcccesStatistics = () => {
    navigate("/");
  };

  const onClickLearnMore = () => {
    navigate("/product");
  };

  return (
    <div>
      <Title title={"Command Center"} className={styles.title} />
      <FlexBox className={clsx(styles.flex)}>
        {homeCardData.map((card) => (
          <HomeCard
            className={styles.cryptocurrencies}
            icon={card.icon}
            toolTipText={card?.toolTipText}
            to={card.to}
            title={card.title}
            subtile={""}
            type={card.type}
            key={card.id}
            description={card.description}
            buttonText={card.btnText}
          />
        ))}
      </FlexBox>
      <FlexBox className={clsx(styles.flex, styles.flex1)}>
        <Card className={styles.card}>
          <div className={styles.cardLeft}>
            <div className={styles.iconContainer}>
              <TestToken />
            </div>
            <h3>Crypto INDEX TOKEN</h3>
            <CustomButton className={styles.btn} onClick={onClickLearnMore}>
              Learn More
            </CustomButton>
          </div>
        </Card>
        <Card className={styles.card}>
          <div className={styles.cardRight}>
            <img src={graphStat} alt="graph stat" />
            <CustomButton
              className={styles.btn}
              onClick={onClikAcccesStatistics}
            >
              Accces statistics
            </CustomButton>
          </div>
        </Card>
      </FlexBox>
      <Blog />
    </div>
  );
};

export default Home;
