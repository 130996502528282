import { TabContext, TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";
const CustomTabList = ({ data, value, handleChange }) => {
  return (
    <TabContext value={value}>
      <Box
        sx={{
          width: "100%",
          typography: "body1",
        }}
      >
        <TabList
          onChange={handleChange}
          aria-label="lab API tabs example"
          TabIndicatorProps={{ sx: { backgroundColor: "#FFB000" } }}
          sx={{
            "& button": {
              padding: "0",
              color: "#2e637d",
              fontSize: "16px",
              marginRight: "15px",
              textTransform: "inherit",
              fontFamily: "Nunito Sans",
            },
            "& button.Mui-selected ": {
              color: "#1B4458",
              fontWeight: "600",
              fontFamily: "Nunito Sans",
            },
          }}
        >
          {data.map(({ label, value }) => (
            <Tab label={label} value={value} />
          ))}
        </TabList>
      </Box>
    </TabContext>
  );
};

export default CustomTabList;
