import { Slider } from "@mui/material";

interface Props {
  value: number[];
  min: number;
  max: number;
  step: number;
  handleChange: (event: Event, newValue: number | number[]) => void;
  valuetext: (value: number) => string;
}
const RangeInput = ({
  value,
  min,
  max,
  step,
  handleChange,
  valuetext,
}: Props) => {
  return (
    <Slider
      getAriaLabel={() => "Temperature range"}
      value={value}
      min={min}
      max={max}
      step={step}
      onChange={(event, newValue) => handleChange(event, newValue)}
      valueLabelDisplay="auto"
      getAriaValueText={valuetext}
      aria-labelledby="non-linear-slider"
      // valueLabelFormat={valueLabelFormat}
    />
  );
};

export default RangeInput;
