import { daiIcon, tok1, usdcIcon, usdtIcon } from "../assets";

export const RESOURCE_LINK =
  "https://drive.google.com/drive/folders/1X1q1yrdRkKqGRnZ63OMyBpdupOnOPuax?usp=sharing";

export const SAMPLE_INTRO_VIDEO =
  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4";

export const steps: string[] = [
  "General info",
  "Identity verification",
  "Questionnaire",
];
export const stepsWithEmailVerification: string[] = [
  "General information",
  "Email Verification",
  "Identity verification",
  "Questionnaire",
];

export const options = [
  { value: "india", label: "India" },
  { value: "pakistan", label: "Pakistan" },
  { value: "srilanka", label: "Srilanka" },
];

export const TIME_ZONE = [
  { value: "+1.00 BST London, Europe", label: "+1.00 BST London, Europe" },
  { value: "pakistani", label: "Pakistani" },
  { value: "srilankan", label: "Srilankan" },
];

export const LANGUAGE = [
  { value: "Hindi", label: "Hindi" },
  { value: "en", label: "English" },
];

export const companySizeList = [
  { value: "1-10", label: "1-10" },
  { value: "11-50", label: "11-50" },
  { value: "51-200", label: "51-200" },
  { value: "201-500", label: "201-500" },
  { value: "500+", label: "500+" },
];
export const chartData = [
  { name: "Mar 1", uv: 4000 },
  { name: "Mar 2", uv: 3000 },
  { name: "Mar 3", uv: 2000 },
  { name: "Mar 4", uv: 6000 },
  { name: "Mar 5", uv: 1890 },
  { name: "Mar 6", uv: 2390 },
  { name: "Mar 7", uv: 3490 },
  { name: "Mar 8", uv: 3490 },
  { name: "Mar 9", uv: 3490 },
  { name: "Mar 10", uv: 1231 },
  { name: "Mar 11", uv: 6454 },
  { name: "Mar 12", uv: 1434 },
  { name: "Mar 13", uv: 7544 },
  { name: "Mar 14", uv: 6375 },
  { name: "Mar 15", uv: 9453 },
  { name: "Mar 16", uv: 94534 },
];

export const tokenData = [
  {
    name: "$NFTS TOKEN",
    price: "$253.60",
    holdings: "$100.00",
    high: "45",
    low: "",
    logo: "tok1.png",
  },
  {
    name: "$CRY10 TOKEN",
    price: "$243.60",
    holdings: "$10.00",
    high: "66",
    low: "",
    logo: "tok2.png",
  },
  {
    name: "$CRY10 TOKEN",
    price: "$453.60",
    holdings: "$200.00",
    high: "55",
    low: "",
    logo: "tok3.png",
  },
];

export const staking = [
  {
    amount: {
      min: "124.472",
      max: "7.568,02",
    },
    reward: {
      min: "270.0",
      max: "204.67",
    },
  },
  {
    amount: {
      min: "224.472",
      max: "7.568,02",
    },
    reward: {
      min: "330.0",
      max: "204.67",
    },
  },
  {
    amount: {
      min: "554.472",
      max: "7.568,02",
    },
    reward: {
      min: "270.0",
      max: "204.67",
    },
  },
  {
    amount: {
      min: "654.472",
      max: "7.568,02",
    },
    reward: {
      min: "270.0",
      max: "204.67",
    },
  },
];

export const rewardData = [
  {
    id: 1,
    type: "Friends Referral",
    points: "150",
    amount: { min: "20.00", max: "20.00" },
  },
  {
    id: 2,
    type: "Trustpilot Rating",
    points: "150",
    amount: { min: "20.00", max: "20.00" },
  },
];

export const loanData = [
  {
    id: 1,
    asset: "#3068",
    repay: "270.0",
    repayBefore: "April 4, 2023  10:30",
  },
  {
    id: 2,
    asset: "#3068",
    repay: "270.0",
    repayBefore: "April 4, 2023  10:30",
  },
];

export const Theader = [
  {
    heading: "Status",
  },
  {
    heading: "Document name",
  },
  {
    heading: "Document type",
  },
  {
    heading: "Verification type",
  },
  {
    heading: "Uploaded date",
  },
];

export const affiliateOverview = [
  {
    id: 1,
    title: "invitees",
    value: 102,
  },
  {
    id: 2,
    title: "Total traders",
    value: 24,
  },
  {
    id: 3,
    title: "Earned Comissions",
    value: "$403.83",
  },
  {
    id: 4,
    title: "Trading colume",
    value: "$12.120",
  },
];

export const socialmedia = [
  { value: "Facebook", label: "Facebook" },
  { value: "Instagram", label: "Instagram" },
  { value: "Twitter", label: "Twitter" },
  { value: "Snapchat", label: "Snapchat" },
  { value: "Youtube", label: "Youtube" },
  { value: "We chat", label: "We chat" },
  { value: "Telegram", label: "Telegram" },
];

export const yesNo = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const affiliateSteps: string[] = ["General info", "E-mail verification"];

export const PUBLIC_ACCOUNT_ID_FAKE = "183bd HDBEZ13 36eDGEDV";

export const getImageBySymbol: Record<string, string> = {
  WETH: tok1,
  DAI: daiIcon,
  USDC: usdcIcon,
  USDT: usdtIcon,
};

export const events = {
  getPortfolio: "get-portfolio",
  syncPortfolio: "sync-portfolio",
  accountDelete: "accountDelete",
  getNotification: "get-notification",
  syncTransaction: "sync-transaction",
  syncPortfolioValue: "sync-portfolio-value",
  syncTokens: "sync-tokens",
  syncOrders: "sync-orders",
};

export const PRODUCT_TABS = [
  { label: "Overview", value: "1" },
  { label: "$nSTBL", value: "2" },
  { label: "$NFTS", value: "3" },
  { label: "Help center", value: "4" },
];

export const legalStructureList = [
  { value: "Corporation", label: "Corporation" },
  { value: "Limited liability company", label: "Limited liability company" },
  { value: "Nonprofit", label: "Nonprofit" },
  { value: "S corporation", label: "S corporation" },
  { value: "General partnership", label: "General partnership" },
  { value: "Cooperative", label: "Cooperative" },
  { value: "Choosing a legal structure", label: "Choosing a legal structure" },
  { value: "Limited company", label: "Limited company" },
];
