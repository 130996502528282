import {
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useState
} from "react";
import { AlertType } from "../pages/Login";

type ContextProps = {
    alertMsg?: AlertType;
    setAlertMsg?: React.Dispatch<React.SetStateAction<AlertType>> | undefined
    onCloseAlert?: () => void
}
const Context = createContext<ContextProps>({});

export default function AlertContext(props: PropsWithChildren) {
    // const [timer, setTimer] = useState<SetStateAction<number>>(0)
    const [alertMsg, setAlertMsg] = useState<AlertType>({
        type: "success",
        msg: ""
    });
    useEffect(() => {
        let time;
        if (alertMsg.msg !== "") {
            time = setTimeout(onCloseAlert, 6000)
        }
        return () => {
            clearTimeout(time)
        }
    }, [alertMsg.msg])
    const onCloseAlert = () => {
        setAlertMsg({
            type: undefined,
            msg: ""
        })
    }
    return (
        <Context.Provider value={{ alertMsg, setAlertMsg, onCloseAlert }}>{props.children}</Context.Provider>
    );
}
export const useAlertMessage = () => useContext(Context);
