import { useNavigate } from "react-router-dom";
import {
  IResolveParams,
  LoginSocialFacebook,
  objectType,
} from "reactjs-social-login";
import { Facebook } from "../assets";
import { useAlertMessage } from "../context/AlertContext";
import { environment } from "../environment";
import { useLoginByFacebookMutation } from "../service/api";
import { useAppDispatch } from "../store/hooks";
import { loginUser } from "../store/reducers/authSlice";

type Props = {
  navigateTo?: string;
  classes?: {
    inputGroup?: string;
    button?: string;
  };
  affiliateCode?: string;
  register?: boolean;
};

type Body = {
  token: string;
  affiliateCode?: string;
};

export default function FBLogin(props: Props) {
  const { navigateTo, classes, affiliateCode, register } = props;
  const [fbLogin] = useLoginByFacebookMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setAlertMsg } = useAlertMessage()
  const handleOnResolve = async (result?: objectType) => {
    const body: Body = {
      token: result?.accessToken || "",
      affiliateCode: affiliateCode || "",
    };
    try {
      const { data } = await fbLogin(body).unwrap();

      const { user, token, refreshToken } = data;

      if (user && user?.is2FAEnable) {
        const route = user.is2FAVerified ? "/2fa-auth" : "/2fa-qrcode";
        return navigate(route, {
          state: {
            email: user?.email,
            user,
          },
        });
      }

      if (user && token && refreshToken) {
        dispatch(
          loginUser({
            user: user,
            token: token,
            refreshToken: refreshToken,
          })
        );
        if (navigateTo) {
          navigate(navigateTo);
        }
      }
    } catch (error: any) {
      setAlertMsg?.({
        type: 'error',
        msg: error?.message || error?.data?.message || "Something went wrong!"
      })
    }
  };

  return (
    <LoginSocialFacebook
      appId={environment.FB_APP_ID}
      onResolve={({ data }: IResolveParams) => handleOnResolve(data)}
      onReject={(e: any) => console.log(e, "onReject")}
    >
      <div className={classes?.inputGroup}>
        <button type="button" className={classes?.button}>
          {" "}
          <Facebook /> Continue with Facebook
        </button>
      </div>
    </LoginSocialFacebook>
  );
}
